import { call, all, takeEvery, put, fork } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import actions from './actions';
import Soses from '../../services/soses';
import { API_STATUS } from '../../utils/enums';
import { URLS } from '../../utils/enumsUrl';

import pagesActions from '../pages/actions';

export function* loadSoses() {
  yield takeEvery(actions.LOAD_SOSES, function* ({ payload }) {
    const { page } = payload;
    try {
      const result = yield call(Soses.getSoses, page);
      yield put(actions.loadSosesSuccess(result));
    } catch (error) {
      yield put(actions.loadSosesError(error));
    }
  });
}

export function* loadSos() {
  yield takeEvery(actions.LOAD_SOS, function* ({ payload }) {
    try {
      const result = yield call(Soses.getSos, payload);
      yield put(actions.loadSosSuccess(result));
    } catch (error) {
      yield put(actions.loadSosError(error));
    }
  });
}

export function* sendPushSos() {
  yield takeEvery(actions.SEND_PUSH_SOS, function* ({ payload }) {
    const { data } = payload;
    try {
      const result = yield call(Soses.sendPushSos, data);

      if (result === API_STATUS.SUCCESS) {
        yield put(push(URLS.SOS));
        yield put(actions.sendPushSosSuccess());
      }
    } catch (error) {
      yield put(actions.sendPushSosError(error));
    }
  });
}

export function* deleteSos() {
  yield takeEvery(actions.DELETE_SOS, function* ({ payload }) {
    const { data } = payload;
    try {
      const result = yield call(Soses.deleteSos, data);

      if (result === API_STATUS.SUCCESS) {
        yield put(actions.loadSoses());
        yield put(pagesActions.resetPage());
      }
    } catch (error) {
      yield put(actions.deleteSosError(error));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(loadSoses), fork(loadSos), fork(sendPushSos), fork(deleteSos)]);
}
