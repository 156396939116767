import { call, all, takeEvery, put, fork } from 'redux-saga/effects';
import actions from './actions';
import CategoriesBasic from '../../services/categoriesBasic';

export function* loadCategoriesBasic() {
  yield takeEvery(actions.LOAD_CATEGORIES_BASIC, function* ({ payload }) {
    const { categoryId } = payload;
    try {
      const result = yield call(CategoriesBasic.getCategoriesBasic, categoryId);
      yield put(actions.loadCategoriesBasicSuccess(result));
    } catch (error) {
      yield put(actions.loadCategoriesBasicError(error));
    }
  });
}

export function* loadCategoryBasic() {
  yield takeEvery(actions.LOAD_CATEGORY_BASIC, function* ({ payload }) {
    const { id } = payload;
    try {
      const result = yield call(CategoriesBasic.getCategoryBasic, id);
      yield put(actions.loadCategoryBasicSuccess(result));
    } catch (error) {
      yield put(actions.loadCategoryBasicError(error));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(loadCategoriesBasic), fork(loadCategoryBasic)]);
}
