import actions from './actions';

const initState = {
  isLoading: false,
  rooms: [],
  roomName: '',
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_ROOMS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_ROOMS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        rooms: payload.data,
      };
    case actions.LOAD_ROOMS_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.LOAD_ROOM:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_ROOM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        roomName: payload.data,
      };
    case actions.LOAD_ROOM_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.REMOVE_ROOMS:
      return {
        ...state,
        rooms: [],
      };

    case actions.REMOVE_ROOM:
      return {
        ...state,
        roomName: '',
      };

    default:
      return state;
  }
}
