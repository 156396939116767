import styled from 'styled-components';
import { palette } from 'styled-theme';

const ModalInfo = ComponentName => styled(ComponentName)`
  .ant-modal {
    padding: 0;
  }

  .ant-modal-content {
    border-radius: 8px;

    .ant-modal-close-x {
      color: ${palette('text', 5)};
    }
  }

  .ant-modal-header {
    border-radius: 8px 8px 0 0;
    background-color: ${palette('primary', 0)};

    .ant-modal-title {
      color: ${palette('text', 5)};
    }
  }
`;

export { ModalInfo };
