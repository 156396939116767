import styled from 'styled-components';
import { palette } from 'styled-theme';

const ButtonHolders = styled.div``;

const ButtonStyle = ComponentName => styled(ComponentName)`
  &.ant-btn-primary,
  &.ant-btn-primary:focus {
    background-color: ${palette('primary', 0)};
    border-color: ${palette('primary', 0)};
    color: ${palette('text', 5)};
  }

  &.ant-btn-primary:hover {
    background-color: ${palette('text', 5)};
    border-color: ${palette('primary', 0)};
    color: ${palette('primary', 0)};
  }
`;

const ButtonStyleToRight = ComponentName => styled(ComponentName)`
  float: right;

  &.ant-btn-primary,
  &.ant-btn-primary:focus {
    background-color: ${palette('primary', 0)};
    border-color: ${palette('primary', 0)};
    color: ${palette('text', 5)};
  }

  &.ant-btn-primary:hover {
    background-color: ${palette('text', 5)};
    border-color: ${palette('primary', 0)};
    color: ${palette('primary', 0)};
  }
`;

export { ButtonHolders, ButtonStyle, ButtonStyleToRight };
