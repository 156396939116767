import GeoJSON from 'ol/format/GeoJSON';
import Projection from 'ol/proj/Projection';

import { MAP_ENUMS } from '../../../utils/enums';

const createProjection = () => {
  return new Projection({
    code: MAP_ENUMS.PROJECTION,
  });
};

const createGeoJson = () => {
  const proj = createProjection();
  return new GeoJSON({
    featureProjection: proj,
    // dataProjection: proj,
  });
};

export default class GeoJsonUtils {
  static createProjection = createProjection;
  static createGeoJson = createGeoJson;
}
