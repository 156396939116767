import React from 'react';
import { Link } from 'react-router-dom';

import { MAP_ENUMS } from '../../../utils/enums';
import { URLS } from '../../../utils/enumsUrl';

import { LogoCollapsed, Logo } from './logo.style';

const CustomLogo = ({ collapsed }) => {
  return (
    <div className='isoLogoWrapper'>
      {collapsed ? (
        <LogoCollapsed>
          <h3>
            <Link to={`${URLS.MAP}/${MAP_ENUMS.MAP_STARTING_FLOOR}`}>
              <img src={require('../../../assets/umed_sygnet.svg')} alt='Uniwersytet Medyczny' />
            </Link>
          </h3>
        </LogoCollapsed>
      ) : (
        <Logo>
          <h3>
            <Link to={`${URLS.MAP}/${MAP_ENUMS.MAP_STARTING_FLOOR}`}>
              <img src={require('../../../assets/umed_sygnet.svg')} alt='Uniwersytet Medyczny' />
            </Link>
          </h3>
        </Logo>
      )}
    </div>
  );
};

export { CustomLogo };
