const actions = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',

  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERROR: 'LOGOUT_ERROR',

  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',

  CHECK_WHOAMI: 'CHECK_WHOAMI',
  CHECK_WHOAMI_SUCCESS: 'CHECK_WHOAMI_SUCCESS',
  CHECK_WHOAMI_ERROR: 'CHECK_WHOAMI_ERROR',

  login: ({ email, password }) => ({
    type: actions.LOGIN_REQUEST,
    payload: { email, password },
  }),

  loginSuccess: token => ({
    type: actions.LOGIN_SUCCESS,
    payload: { token },
  }),

  loginError: error => ({
    type: actions.LOGIN_ERROR,
    payload: { error },
  }),

  logout: () => ({
    type: actions.LOGOUT_REQUEST,
  }),

  logoutSuccess: () => ({
    type: actions.LOGOUT_SUCCESS,
  }),

  logoutError: error => ({
    type: actions.LOGOUT_ERROR,
    payload: { error },
  }),

  checkWhoAmI: () => ({
    type: actions.CHECK_WHOAMI,
  }),
  checkWhoAmISuccess: data => ({
    type: actions.CHECK_WHOAMI_SUCCESS,
    payload: { data },
  }),
  checkWhoAmIError: error => ({
    type: actions.CHECK_WHOAMI_ERROR,
    payload: { error },
  }),
};

export default actions;
