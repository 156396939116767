import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getTranslations } from '../redux/languages/selectors';

function withTitleTln(Cmp) {
  class Controller extends Component {
    render() {
      const { translations, title } = this.props;
      const cmpProps = { ...this.props };
      delete cmpProps.dispatch;

      return <Cmp {...cmpProps} title={translations[title] ? translations[title] : title} />;
    }
  }

  return connect(mapStateToProps, null)(Controller);
}

const mapStateToProps = state => {
  return {
    translations: getTranslations(state),
  };
};

export default withTitleTln;
