import actions from './actions';
import { defaultLanguage } from '../../config/settings';

const initState = {
  activeLanguage: defaultLanguage,
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.SET_LANGUAGE:
      return {
        ...state,
        activeLanguage: payload.language,
      };

    default:
      return state;
  }
}
