import SuperFetch from '../utils/superFetch';
import TlnString from '../components/Tln/TlnString';

const Events = {
  getEvents: (page = 1) => SuperFetch.get(`event?page=${page}`).then(response => response.data),

  getEvent: eventId => SuperFetch.get(`event/${eventId}`).then(response => response.data),

  saveEvent: event =>
    SuperFetch.post('event', event, false, TlnString('CRUD_ADD_RECORD_SUCCESS')).then(response => response.status),

  updateEvent: event =>
    SuperFetch.put(`event/${event.id}`, event, false, TlnString('CRUD_EDIT_RECORD_SUCCESS')).then(response => response.status),

  deleteEvent: event =>
    SuperFetch.delete(`event/${event.id}`, {}, false, TlnString('CRUD_REMOVE_RECORD_SUCCESS')).then(response => response.status),
};

export default Events;
