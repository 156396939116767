const ROUTER_URLS = {
  CMS: '',
  MAP: 'map',
  MAP_WITH_FIRST_FLOOR: 'map/p0',
  CATEGORIES: 'categories',
  CATEGORIES_MODIFY: 'categories/modify',
  ARTICLES: 'articles',
  ARTICLES_MODIFY: 'articles/modify',
  EVENTS: 'events',
  EVENTS_MODIFY: 'events/modify',
  SOS: 'sos',
  SOS_SEND_PUSH: 'sos/send_push',
  USERS: 'users',
  USERS_MODIFY: 'users/modify',
  USERS_MODIFY_PASSWORD: 'users/modify_password',
  CATEGORIES_CRUDS: 'categories_articles_events',
  CATEGORIES_CRUDS_MODIFY: 'categories_articles_events/modify',
  BUTTONS: 'buttons',
  BUTTONS_MODIFY: 'buttons/modify',
  ADVERTISEMENTS: 'advertisements',
  ADVERTISEMENTS_MODIFY: 'advertisements/modify',
  NOTIFICATIONS: 'notifications',
  NOTIFICATIONS_MODIFY: 'notifications/modify',
  NOTIFICATIONS_PUSH: 'notifications_push',
  NOTIFICATIONS_PUSH_MODIFY: 'notifications_push/modify',
  NOTIFICATIONS_ROUTE: 'notifications_route',
  NOTIFICATIONS_ROUTE_MODIFY: 'notifications_route/modify',
  CAMPUSES: 'campuses',
  CAMPUSES_MODIFY: 'campuses/modify',
  SOS_CONTACTS: 'sos_contacts',
  SOS_CONTACTS_MODIFY: 'sos_contacts/modify',
  PARKING_LOTS: 'parking_lots',
  PARKING_LOTS_MODIFY: 'parking_lots/modify',
  PARKING_LOTS_LEGEND: 'parking_lots/legend',
  BEACONS: 'beacons',
  BEACONS_MODIFY: 'beacons/modify',
  OPINIONS: 'opinions',
};

const URLS = {
  CMS: '/cms',
  MAP: '/cms/map',
  CATEGORIES: '/cms/categories',
  CATEGORIES_MODIFY: '/cms/categories/modify',
  ARTICLES: '/cms/articles',
  ARTICLES_MODIFY: '/cms/articles/modify',
  EVENTS: '/cms/events',
  EVENTS_MODIFY: '/cms/events/modify',
  SOS: '/cms/sos',
  SOS_SEND_PUSH: '/cms/sos/send_push',
  USERS: '/cms/users',
  USERS_MODIFY: '/cms/users/modify',
  USERS_MODIFY_PASSWORD: '/cms/users/modify_password',
  CATEGORIES_CRUDS: '/cms/categories_articles_events',
  CATEGORIES_CRUDS_MODIFY: '/cms/categories_articles_events/modify',
  BUTTONS: '/cms/buttons',
  BUTTONS_MODIFY: '/cms/buttons/modify',
  ADVERTISEMENTS: '/cms/advertisements',
  ADVERTISEMENTS_MODIFY: '/cms/advertisements/modify',
  NOTIFICATIONS: '/cms/notifications',
  NOTIFICATIONS_MODIFY: '/cms/notifications/modify',
  NOTIFICATIONS_PUSH: '/cms/notifications_push',
  NOTIFICATIONS_PUSH_MODIFY: '/cms/notifications_push/modify',
  NOTIFICATIONS_ROUTE: '/cms/notifications_route',
  NOTIFICATIONS_ROUTE_MODIFY: '/cms/notifications_route/modify',
  CAMPUSES: '/cms/campuses',
  CAMPUSES_MODIFY: '/cms/campuses/modify',
  SOS_CONTACTS: '/cms/sos_contacts',
  SOS_CONTACTS_MODIFY: '/cms/sos_contacts/modify',
  PARKING_LOTS: '/cms/parking_lots',
  PARKING_LOTS_MODIFY: '/cms/parking_lots/modify',
  PARKING_LOTS_LEGEND: '/cms/parking_lots/legend',
  BEACONS: '/cms/beacons',
  BEACONS_MODIFY: '/cms/beacons/modify',
  OPINIONS: '/cms/opinions',
};

const URLS_TO_ROUTER = [
  { key: URLS.MAP, value: ROUTER_URLS.MAP },
  { key: URLS.CATEGORIES, value: ROUTER_URLS.CATEGORIES },
  { key: URLS.CATEGORIES_MODIFY, value: ROUTER_URLS.CATEGORIES },
  { key: URLS.ARTICLES, value: ROUTER_URLS.ARTICLES },
  { key: URLS.ARTICLES_MODIFY, value: ROUTER_URLS.ARTICLES },
  { key: URLS.EVENTS, value: ROUTER_URLS.EVENTS },
  { key: URLS.EVENTS_MODIFY, value: ROUTER_URLS.EVENTS },
  { key: URLS.SOS, value: ROUTER_URLS.SOS },
  { key: URLS.SOS_SEND_PUSH, value: ROUTER_URLS.SOS },
  { key: URLS.USERS, value: ROUTER_URLS.USERS },
  { key: URLS.USERS_MODIFY, value: ROUTER_URLS.USERS },
  { key: URLS.USERS_MODIFY_PASSWORD, value: ROUTER_URLS.USERS },
  { key: URLS.CATEGORIES_CRUDS, value: ROUTER_URLS.CATEGORIES_CRUDS },
  { key: URLS.CATEGORIES_CRUDS_MODIFY, value: ROUTER_URLS.CATEGORIES_CRUDS },
  { key: URLS.BUTTONS, value: ROUTER_URLS.BUTTONS },
  { key: URLS.BUTTONS_MODIFY, value: ROUTER_URLS.BUTTONS },
  { key: URLS.ADVERTISEMENTS, value: ROUTER_URLS.ADVERTISEMENTS },
  { key: URLS.ADVERTISEMENTS_MODIFY, value: ROUTER_URLS.ADVERTISEMENTS },
  { key: URLS.NOTIFICATIONS, value: ROUTER_URLS.NOTIFICATIONS },
  { key: URLS.NOTIFICATIONS_MODIFY, value: ROUTER_URLS.NOTIFICATIONS },
  { key: URLS.NOTIFICATIONS_PUSH, value: ROUTER_URLS.NOTIFICATIONS_PUSH },
  { key: URLS.NOTIFICATIONS_PUSH_MODIFY, value: ROUTER_URLS.NOTIFICATIONS_PUSH },
  { key: URLS.NOTIFICATIONS_ROUTE, value: ROUTER_URLS.NOTIFICATIONS_ROUTE },
  { key: URLS.NOTIFICATIONS_ROUTE_MODIFY, value: ROUTER_URLS.NOTIFICATIONS_ROUTE },
  { key: URLS.CAMPUSES, value: ROUTER_URLS.CAMPUSES },
  { key: URLS.CAMPUSES_MODIFY, value: ROUTER_URLS.CAMPUSES },
  { key: URLS.SOS_CONTACTS, value: ROUTER_URLS.SOS_CONTACTS },
  { key: URLS.SOS_CONTACTS_MODIFY, value: ROUTER_URLS.SOS_CONTACTS },
  { key: URLS.PARKING_LOTS, value: ROUTER_URLS.PARKING_LOTS },
  { key: URLS.PARKING_LOTS_MODIFY, value: ROUTER_URLS.PARKING_LOTS },
  { key: URLS.PARKING_LOTS_LEGEND, value: ROUTER_URLS.PARKING_LOTS },
  { key: URLS.BEACONS, value: ROUTER_URLS.BEACONS },
  { key: URLS.BEACONS_MODIFY, value: ROUTER_URLS.BEACONS },
  { key: URLS.OPINIONS, value: ROUTER_URLS.OPINIONS },
];

export { ROUTER_URLS, URLS, URLS_TO_ROUTER };
