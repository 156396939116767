import { call, all, takeEvery, put, fork } from 'redux-saga/effects';
import actions from './actions';
import Types from '../../services/types';

export function* loadTypes() {
  yield takeEvery(actions.LOAD_TYPES, function* () {
    try {
      const result = yield call(Types.getTypes);
      yield put(actions.loadTypesSuccess(result));
    } catch (error) {
      yield put(actions.loadTypesError(error));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(loadTypes)]);
}
