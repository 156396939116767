import SuperFetch from '../utils/superFetch';
import TlnString from '../components/Tln/TlnString';

const Articles = {
  getArticles: (page = 1) => SuperFetch.get(`article?page=${page}`).then(response => response.data),

  getArticle: articleId => SuperFetch.get(`article/${articleId}`).then(response => response.data),

  saveArticle: article =>
    SuperFetch.post('article', article, false, TlnString('CRUD_ADD_RECORD_SUCCESS')).then(response => response.status),

  updateArticle: article =>
    SuperFetch.put(`article/${article.id}`, article, false, TlnString('CRUD_EDIT_RECORD_SUCCESS')).then(
      response => response.status,
    ),

  deleteArticle: article =>
    SuperFetch.delete(`article/${article.id}`, {}, false, TlnString('CRUD_REMOVE_RECORD_SUCCESS')).then(
      response => response.status,
    ),
};

export default Articles;
