import actions from './actions';

const initState = {
  isLoading: false,
  isSaving: false,
  categoriesCruds: [],
  categoryCruds: {},
  categoriesCrudsBasic: [],
  modalActive: false,
  firstOpen: true,
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_CATEGORIES_CRUDS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_CATEGORIES_CRUDS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categoriesCruds: payload.data,
      };
    case actions.LOAD_CATEGORIES_CRUDS_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.LOAD_CATEGORIES_CRUDS_BASIC:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_CATEGORIES_CRUDS_SUCCESS_BASIC:
      return {
        ...state,
        isLoading: false,
        firstOpen: false,
        categoriesCrudsBasic: payload.data,
      };
    case actions.LOAD_CATEGORIES_CRUDS_ERROR_BASIC:
      return {
        ...state,
        isLoading: false,
        firstOpen: false,
      };

    case actions.LOAD_CATEGORY_CRUDS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_CATEGORY_CRUDS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categoryCruds: payload.data,
      };
    case actions.LOAD_CATEGORY_CRUDS_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.SAVE_CATEGORIES_CRUDS:
      return {
        ...state,
        isSaving: true,
      };
    case actions.SAVE_CATEGORIES_CRUDS_SUCCESS:
      return {
        ...state,
        isSaving: false,
      };
    case actions.SAVE_CATEGORIES_CRUDS_ERROR:
      return {
        ...state,
        isSaving: false,
      };

    case actions.REMOVE_CATEGORY_CRUDS:
      return {
        ...state,
        categoryCruds: {},
      };
    case actions.REMOVE_CATEGORIES_CRUDS:
      return {
        ...state,
        categoriesCruds: [],
      };
    case actions.REMOVE_CATEGORIES_CRUDS_BASIC:
      return {
        ...state,
        categoriesCrudsBasic: [],
      };

    case actions.TOGGLE_CATEGORY_CRUDS_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
      };

    case actions.CLOSE_COMPONENT_CATEGORIES_CRUDS_BASIC:
      return {
        ...state,
        modalActive: false,
        firstOpen: true,
      };

    default:
      return state;
  }
}
