import SuperFetch from '../utils/superFetch';

const Features = {
  getFeatures: (floor, p1, p2, signal) =>
    SuperFetch.get(`geo/extent?profile=${floor}&p1=${p1}&p2=${p2}`, {}, false, null, signal).then(response => response),

  sendNewFeatures: newFeatures => SuperFetch.post('geo', newFeatures, true).then(response => response.status),

  sendEditedFeatures: editedFeatures => SuperFetch.put('geo', editedFeatures, true).then(response => response.status),

  sendDeletedFeatures: deletedFeatures => SuperFetch.delete('geo', deletedFeatures, true).then(response => response.status),

  generateGraph: () => SuperFetch.get('routing/generateGraph').then(response => response),
};

export default Features;
