const actions = {
  LOAD_BUTTONS: 'LOAD_BUTTONS',
  LOAD_BUTTONS_SUCCESS: 'LOAD_BUTTONS_SUCCESS',
  LOAD_BUTTONS_ERROR: 'LOAD_BUTTONS_ERROR',

  LOAD_BUTTON: 'LOAD_BUTTON',
  LOAD_BUTTON_SUCCESS: 'LOAD_BUTTON_SUCCESS',
  LOAD_BUTTON_ERROR: 'LOAD_BUTTON_ERROR',

  SAVE_BUTTON: 'SAVE_BUTTON',
  SAVE_BUTTON_SUCCESS: 'SAVE_BUTTON_SUCCESS',
  SAVE_BUTTON_ERROR: 'SAVE_BUTTON_ERROR',

  LOAD_BUTTONS_TYPE: 'LOAD_BUTTONS_TYPE',
  LOAD_BUTTONS_TYPE_SUCCESS: 'LOAD_BUTTONS_TYPE_SUCCESS',
  LOAD_BUTTONS_TYPE_ERROR: 'LOAD_BUTTONS_TYPE_ERROR',

  REMOVE_BUTTON: 'REMOVE_BUTTON',
  REMOVE_BUTTONS: 'REMOVE_BUTTONS',

  loadButtons: page => ({
    type: actions.LOAD_BUTTONS,
    payload: { page },
  }),
  loadButtonsSuccess: data => ({
    type: actions.LOAD_BUTTONS_SUCCESS,
    payload: { data },
  }),
  loadButtonsError: error => ({
    type: actions.LOAD_BUTTONS_ERROR,
    payload: { error },
  }),

  loadButton: buttonId => ({
    type: actions.LOAD_BUTTON,
    payload: buttonId,
  }),
  loadButtonSuccess: data => ({
    type: actions.LOAD_BUTTON_SUCCESS,
    payload: { data },
  }),
  loadButtonError: error => ({
    type: actions.LOAD_BUTTON_ERROR,
    payload: { error },
  }),

  saveButton: (data, actionName) => ({
    type: actions.SAVE_BUTTON,
    payload: { data, actionName },
  }),
  saveButtonSuccess: () => ({
    type: actions.SAVE_BUTTON_SUCCESS,
  }),
  saveButtonError: error => ({
    type: actions.SAVE_BUTTON_ERROR,
    payload: { error },
  }),

  removeButton: () => ({
    type: actions.REMOVE_BUTTON,
  }),
  removeButtons: () => ({
    type: actions.REMOVE_BUTTONS,
  }),
};

export default actions;
