const actions = {
  LOAD_ROOMS: 'LOAD_ROOMS',
  LOAD_ROOMS_SUCCESS: 'LOAD_ROOMS_SUCCESS',
  LOAD_ROOMS_ERROR: 'LOAD_ROOMS_ERROR',

  LOAD_ROOM: 'LOAD_ROOM',
  LOAD_ROOM_SUCCESS: 'LOAD_ROOM_SUCCESS',
  LOAD_ROOM_ERROR: 'LOAD_ROOM_ERROR',

  REMOVE_ROOMS: 'REMOVE_ROOMS',
  REMOVE_ROOM: 'REMOVE_ROOM',

  loadRooms: (data, signal, withId) => ({
    type: actions.LOAD_ROOMS,
    payload: { data, signal, withId },
  }),
  loadRoomsSuccess: data => ({
    type: actions.LOAD_ROOMS_SUCCESS,
    payload: { data },
  }),
  loadRoomsError: error => ({
    type: actions.LOAD_ROOMS_ERROR,
    payload: { error },
  }),

  loadRoom: id => ({
    type: actions.LOAD_ROOM,
    payload: { id },
  }),
  loadRoomSuccess: data => ({
    type: actions.LOAD_ROOM_SUCCESS,
    payload: { data },
  }),
  loadRoomError: error => ({
    type: actions.LOAD_ROOM_ERROR,
    payload: { error },
  }),

  removeRooms: () => ({
    type: actions.REMOVE_ROOMS,
  }),
  removeRoom: () => ({
    type: actions.REMOVE_ROOM,
  }),
};

export default actions;
