import 'dotenv/config';

const apiUrl = process.env.REACT_APP_apiUrl;
const apiUrlBasic = process.env.REACT_APP_apiUrlBasic;

const defaultLanguage = 'pl';

const languages = [
  {
    key: 'pl',
    local: 'Polski',
    calendarFirstDay: 1,
    tln_id: 'LANGUAGE_PL',
  },
  {
    key: 'en',
    local: 'English',
    calendarFirstDay: 1,
    tln_id: 'LANGUAGE_EN',
  },
];

const siteConfig = {
  siteName: 'Uniwersytet Medyczny',
  siteIcon: 'ion-flash',
  footerText: '© 2021 Uniwersytet Medyczny',
};

const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault',
};

const defaultColor = '#285548';

const mapConfig = {
  // centerPosition: [19.456394, 51.760392], // -> INV
  // centerPosition: [19.5058267,51.7747453], // -> CKD
  // centerPosition: [19.436091667631633, 51.766878612909125], // -> WF
  centerPosition: [19.467000698486302, 51.770228320119685],
  centerPositionMapLatLon: [19.467000698486302, 51.770228320119685],
  xyz: process.env.REACT_APP_xyz,
  xyzEnd: process.env.REACT_APP_xyzEnd,
};

const tileConfig = {
  minZoom: 12,
  maxZoom: 22,
};

export { apiUrl, apiUrlBasic, defaultLanguage, siteConfig, themeConfig, defaultColor, mapConfig, tileConfig, languages };
