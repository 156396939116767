import SuperFetch from '../utils/superFetch';
import TlnString from '../components/Tln/TlnString';

const Categories = {
  getCategories: (page = 1) => SuperFetch.get(`category_geo?page=${page}`).then(response => response.data),

  getCategory: categoryId => SuperFetch.get(`category_geo/${categoryId}`).then(response => response.data),

  saveCategory: category =>
    SuperFetch.post('category_geo', category, false, TlnString('CRUD_ADD_RECORD_SUCCESS')).then(response => response.status),

  updateCategory: category =>
    SuperFetch.put(`category_geo/${category.id}`, category, false, TlnString('CRUD_EDIT_RECORD_SUCCESS')).then(
      response => response.status,
    ),

  deleteCategory: category =>
    SuperFetch.delete(`category_geo/${category.id}`, {}, false, TlnString('CRUD_REMOVE_RECORD_SUCCESS')).then(
      response => response.status,
    ),
};

export default Categories;
