const actions = {
  LOAD_PARKING_LOTS: 'LOAD_PARKING_LOTS',
  LOAD_PARKING_LOTS_SUCCESS: 'LOAD_PARKING_LOTS_SUCCESS',
  LOAD_PARKING_LOTS_ERROR: 'LOAD_PARKING_LOTS_ERROR',

  LOAD_PARKING_LOT: 'LOAD_PARKING_LOT',
  LOAD_PARKING_LOT_SUCCESS: 'LOAD_PARKING_LOT_SUCCESS',
  LOAD_PARKING_LOT_ERROR: 'LOAD_PARKING_LOT_ERROR',

  EDIT_PARKING_LOT: 'EDIT_PARKING_LOT',
  EDIT_PARKING_LOT_SUCCESS: 'EDIT_PARKING_LOT_SUCCESS',
  EDIT_PARKING_LOT_ERROR: 'EDIT_PARKING_LOT_ERROR',

  REMOVE_PARKING_LOT: 'REMOVE_PARKING_LOT',
  REMOVE_PARKING_LOTS: 'REMOVE_PARKING_LOTS',

  loadParkingLots: page => ({
    type: actions.LOAD_PARKING_LOTS,
    payload: { page },
  }),
  loadParkingLotsSuccess: data => ({
    type: actions.LOAD_PARKING_LOTS_SUCCESS,
    payload: { data },
  }),
  loadParkingLotsError: error => ({
    type: actions.LOAD_PARKING_LOTS_ERROR,
    payload: { error },
  }),

  loadParkingLot: parkingLotId => ({
    type: actions.LOAD_PARKING_LOT,
    payload: { parkingLotId },
  }),
  loadParkingLotSuccess: data => ({
    type: actions.LOAD_PARKING_LOT_SUCCESS,
    payload: { data },
  }),
  loadParkingLotError: error => ({
    type: actions.LOAD_PARKING_LOT_ERROR,
    payload: { error },
  }),

  editParkingLot: data => ({
    type: actions.EDIT_PARKING_LOT,
    payload: { data },
  }),
  editParkingLotSuccess: () => ({
    type: actions.EDIT_PARKING_LOT_SUCCESS,
  }),
  editParkingLotError: error => ({
    type: actions.EDIT_PARKING_LOT_ERROR,
    payload: { error },
  }),

  removeParkingLot: () => ({
    type: actions.REMOVE_PARKING_LOT,
  }),
  removeParkingLots: () => ({
    type: actions.REMOVE_PARKING_LOTS,
  }),
};

export default actions;
