import { call, all, takeEvery, put, fork } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import actions from './actions';
import NotificationsPush from '../../services/notificationsPush';
import { API_STATUS } from '../../utils/enums';
import { URLS } from '../../utils/enumsUrl';

export function* loadNotificationsPush() {
  yield takeEvery(actions.LOAD_NOTIFICATIONS_PUSH, function* ({ payload }) {
    const { page } = payload;
    try {
      const result = yield call(NotificationsPush.getNotificationsPush, page);
      yield put(actions.loadNotificationsPushSuccess(result));
    } catch (error) {
      yield put(actions.loadNotificationsPushError(error));
    }
  });
}

export function* loadNotificationPush() {
  yield takeEvery(actions.LOAD_NOTIFICATION_PUSH, function* ({ payload }) {
    const { notificationPushId } = payload;
    try {
      const result = yield call(NotificationsPush.getNotificationPush, notificationPushId);
      yield put(actions.loadNotificationPushSuccess(result));
    } catch (error) {
      yield put(actions.loadNotificationPushError(error));
    }
  });
}

export function* sendNotificationPush() {
  yield takeEvery(actions.SEND_NOTIFICATION_PUSH, function* ({ payload }) {
    const { data } = payload;
    try {
      const result = yield call(NotificationsPush.sendNotificationPush, data);

      if (result === API_STATUS.SUCCESS) {
        yield put(push(URLS.NOTIFICATIONS_PUSH));
        yield put(actions.sendNotificationPushSuccess());
      }
    } catch (error) {
      yield put(actions.sendNotificationPushError(error));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(loadNotificationPush), fork(loadNotificationsPush), fork(sendNotificationPush)]);
}
