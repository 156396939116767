import { call, all, takeEvery, put, fork } from 'redux-saga/effects';
import actions from './actions';
import UmedRoom from '../../services/umedRoom';

export function* loadRooms() {
  yield takeEvery(actions.LOAD_ROOMS, function* ({ payload }) {
    const { data, signal, withId } = payload;
    try {
      const result = yield call(UmedRoom.getOptionsRooms, data, signal, withId);
      yield put(actions.loadRoomsSuccess(result));
    } catch (error) {
      yield put(actions.loadRoomsError(error));
    }
  });
}

export function* loadRoom() {
  yield takeEvery(actions.LOAD_ROOM, function* ({ payload }) {
    const { id } = payload;
    try {
      const result = yield call(UmedRoom.getSingleRoom, id);
      yield put(actions.loadRoomSuccess(result));
    } catch (error) {
      yield put(actions.loadRoomError(error));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(loadRooms), fork(loadRoom)]);
}
