import App from './app/reducer';
import Auth from './auth/reducer';
import Categories from './categories/reducer';
import Articles from './articles/reducer';
import Events from './events/reducer';
import Sos from './sos/reducer';
import Features from './features/reducer';
import Users from './users/reducer';
import Types from './types/reducer';
import InteractionsMenu from './interactionsMenu/reducer';
import CategoriesCruds from './categoriesCruds/reducer';
import GeoBasic from './geoBasic/reducer';
import Upload from './upload/reducer';
import Buttons from './buttons/reducer';
import Languages from './languages/reducer';
import Pages from './pages/reducer';
import Advertisements from './advertisements/reducer';
import Notifications from './notifications/reducer';
import NotificationsPush from './notificationsPush/reducer';
import Campuses from './campuses/reducer';
import SosContacts from './sosContacts/reducer';
import ParkingLots from './parkingLots/reducer';
import CategoriesBasic from './categoriesBasic/reducer';
import NotificationsRoute from './notificationsRoute/reducers';
import UmedRooms from './umedRooms/reducer';
import ConnectorValues from './connectorValues/reducer';
import Beacons from './beacons/reducer';
import Opinions from './opinions/reducer';

export default {
  App,
  Auth,
  Categories,
  Articles,
  Events,
  Sos,
  Features,
  Users,
  Types,
  InteractionsMenu,
  CategoriesCruds,
  GeoBasic,
  Upload,
  Buttons,
  Languages,
  Pages,
  Advertisements,
  Notifications,
  NotificationsPush,
  Campuses,
  SosContacts,
  ParkingLots,
  CategoriesBasic,
  NotificationsRoute,
  UmedRooms,
  ConnectorValues,
  Beacons,
  Opinions,
};
