import SuperFetch from '../utils/superFetch';

const Auth = {
  login: ({ email, password }) => SuperFetch.post('auth/login', { email, password }).then(response => response.token),

  logout: () => SuperFetch.post('auth/logout').then(response => response),

  checkWhoAmI: () => SuperFetch.get('auth/whoami').then(response => response.data),
};

export default Auth;
