import styled from 'styled-components';
import { palette } from 'styled-theme';
import { transition, borderRadius } from '../../config/settings/style-util';

const TopbarWrapper = styled.div`
  .isomorphicTopbar {
    background-color: ${palette('text', 0)};
    position: fixed;
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0 31px 0 265px;
    z-index: 1000;
    ${transition()};

    .anticon {
      &:hover {
        color: ${palette('primary', 1)};
      }
    }

    @media only screen and (max-width: 767px) {
      padding: 0px 15px 0px 260px !important;
    }

    &.collapsed {
      padding: 0 31px 0 109px;
      @media only screen and (max-width: 767px) {
        padding: 0px 15px !important;
      }
    }

    .isoLeft {
      display: flex;
      align-items: center;
      justify-content: center;
      vertical-align: middle;
      margin: 0;

      @media only screen and (max-width: 767px) {
        margin: 0 20px 0 0;
      }

      i {
        font-size: 20px;
        color: ${palette('text', 0)};
        line-height: 0;
      }
    }

    .isoRight {
      display: flex;
      align-items: center;
      justify-content: center;
      vertical-align: middle;
      margin: 0;

      li {
        margin-left: 0;
        margin-right: 20px;
        cursor: pointer;
        line-height: normal;
        position: relative;
        display: inline-block;

        @media only screen and (max-width: 360px) {
          margin-left: 0;
          margin-right: 25px;
        }

        &:last-child {
          margin: 0;
        }

        i {
          font-size: 24px;
          color: ${palette('text', 0)};
          line-height: 1;
        }

        .isoIconWrapper {
          position: relative;
          line-height: normal;

          span {
            font-size: 12px;
            color: #fff;
            background-color: ${palette('secondary', 1)};
            width: 20px;
            height: 20px;
            display: -webkit-inline-flex;
            display: -ms-inline-flex;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            line-height: 20px;
            position: absolute;
            top: -8px;
            left: 10px;
            right: inherit;
            ${borderRadius('50%')};
          }
        }

        &.isoLogout {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          .ant-spin-spinning {
            line-height: 0;
            margin-right: 1rem;
          }

          i {
            line-height: 0;
          }
        }

        &.isoUser {
          .isoImgWrapper {
            cursor: default;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            background-color: ${palette('grayscale', 9)};
            ${borderRadius('50%')};

            img {
              height: 100%;
              object-fit: cover;
            }

            .userActivity {
              width: 10px;
              height: 10px;
              display: block;
              background-color: ${palette('success', 0)};
              position: absolute;
              bottom: 0;
              right: 3px;
              border: 1px solid #ffffff;
              ${borderRadius('50%')};
            }
          }
        }
      }
    }
  }
`;

export default TopbarWrapper;
