import SuperFetch from '../utils/superFetch';
import TlnString from '../components/Tln/TlnString';

const Soses = {
  getSoses: (page = 1) => SuperFetch.get(`sos?page=${page}`).then(response => response.data),

  getSos: sosId => SuperFetch.get(`sos/${sosId}`).then(response => response.data),

  sendPushSos: pushSos =>
    SuperFetch.post('pushsos', pushSos, false, TlnString('SOS_PUSH_SEND_SUCCESS')).then(response => response.status),

  deleteSos: sos =>
    SuperFetch.delete(`sos/${sos.id}`, {}, false, TlnString('CRUD_REMOVE_RECORD_SUCCESS')).then(response => response.status),
};

export default Soses;
