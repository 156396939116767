import { call, all, takeEvery, put, fork } from 'redux-saga/effects';

import actions from './actions';
import pagesActions from '../pages/actions';
import Opinions from '../../services/opinions';
import { API_STATUS } from '../../utils/enums';

export function* loadOpinionsWatcher() {
  yield takeEvery(actions.LOAD_OPINIONS, function* ({ payload }) {
    const { page } = payload;
    try {
      const result = yield call(Opinions.getOpinions, page);
      yield put(actions.loadOpinionsSuccess(result));
    } catch (error) {
      yield put(actions.loadOpinionsError(error));
    }
  });
}

export function* loadOpinionWatcher() {
  yield takeEvery(actions.LOAD_OPINION, function* ({ payload }) {
    const { opinionId } = payload;
    try {
      const result = yield call(Opinions.getOpinion, opinionId);
      yield put(actions.loadOpinionSuccess(result));
    } catch (error) {
      yield put(actions.loadOpinionError(error));
    }
  });
}

export function* deleteOpinionWatcher() {
  yield takeEvery(actions.DELETE_OPINION, function* ({ payload }) {
    const { data } = payload;
    try {
      const result = yield call(Opinions.deleteOpinion, data);

      if (result === API_STATUS.SUCCESS) {
        yield put(actions.loadOpinions());
        yield put(pagesActions.resetPage());
      }
    } catch (error) {
      yield put(actions.deleteOpinionError(error));
    }
  });
}

export function* sendPushOpinionReplyWatcher() {
  yield takeEvery(actions.SEND_PUSH_OPINION_REPLY, function* ({ payload }) {
    const { opinionId, data } = payload;
    try {
      const result = yield call(Opinions.sendPushOpinionReply, opinionId, data);

      if (result === API_STATUS.SUCCESS) {
        yield put(actions.sendPushOpinionReplySuccess());
        yield put(actions.loadOpinions());
        yield put(actions.closeOpinionDetailsModal());
      }
    } catch (error) {
      yield put(actions.sendPushOpinionReplyError(error));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(loadOpinionsWatcher), fork(loadOpinionWatcher), fork(deleteOpinionWatcher), fork(sendPushOpinionReplyWatcher)]);
}
