import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Debounce } from 'react-throttle';
import WindowResizeListener from 'react-window-size-listener';

import { ThemeProvider } from 'styled-components';

import { Layout, Content } from '../../components/uielements';

import authAction from '../../redux/auth/actions';
import appActions from '../../redux/app/actions';
import { getHeight } from '../../redux/app/selectors';

import AppRouter from './AppRouter';
import Sidebar from '../../pages/Sidebar/Sidebar';
import Topbar from '../../pages/Topbar/Topbar';

import themes from '../../config/settings/themes';
import { themeConfig } from '../../config/settings';

import './global.css';

const { logout } = authAction;
const { toggleAll } = appActions;

export class App extends Component {
  render() {
    const { height, toggleAll, location, match } = this.props;
    const { pathname } = location;
    const { url } = match;
    const appHeight = window.innerHeight;

    return (
      <ThemeProvider theme={themes[themeConfig.theme]}>
        <Layout style={{ height: appHeight }}>
          <Debounce time='1000' handler='onResize'>
            <WindowResizeListener onResize={windowSize => toggleAll(windowSize.windowWidth, windowSize.windowHeight)} />
          </Debounce>
          <Topbar />
          <Layout style={{ flexDirection: 'row', overflow: 'hidden' }}>
            <Sidebar pathname={pathname} />
            <Layout
              className='isoContentMainLayout'
              style={{
                height: height,
              }}
            >
              <Content
                className='isomorphicContent'
                style={{
                  padding: '70px 0 0',
                  flexShrink: '0',
                  background: '#f1f3f6',
                  position: 'relative',
                }}
              >
                <AppRouter url={url} />
              </Content>
            </Layout>
          </Layout>
        </Layout>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    height: getHeight(state),
  };
};

const mapDispatchToProps = { logout, toggleAll };

export default connect(mapStateToProps, mapDispatchToProps)(App);
