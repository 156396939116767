export default {
  YES: 'Tak',
  NO: 'Nie',
  ID: 'Id',
  ARE_YOU_SURE: 'Czy na pewno?',

  NAME: 'Nazwa',
  COLOR: 'Kolor',
  ICON: 'Ikona',
  TEXT: 'Tekst',
  HOLD: 'Przytrzymaj',
  IS_ENABLED: 'Aktywność',

  MENU_MAP: 'Mapy',
  MENU_CATEGORIES: 'Kategorie mapowe',
  MENU_ARTICLES: 'Aktualności',
  MENU_EVENTS: 'Wydarzenia',
  MENU_SOS: 'SOS',
  MENU_SOS_CONTACTS: 'Lista kontaktów SOS',
  MENU_USERS: 'Użytkownicy',
  MENU_CATEGORIES_CRUDS: 'Kategorie',
  MENU_BUTTONS: 'Przyciski',
  MENU_ADVERTISEMENTS: 'Reklamy',
  MENU_NOTIFICATIONS: 'Powiadomienia',
  MENU_NOTIFICATIONS_PUSH: 'Powiadomienia PUSH',
  MENU_CAMPUSES: 'Etykiety kampusów',
  MENU_PARKING_LOT: 'Parkingi',
  MENU_NOTIFICATIONS_ROUTE: 'Powiadomienia ścieżkowe',
  MENU_BEACONS: 'Beacony',
  MENU_OPINIONS: 'Opinie',

  MENU_SNAP_SWITCH: 'dociąganie',
  MAP_BUTTON_SAVE: 'zapisz zmiany',
  MENU_EDIT_MODE: 'tryb edycji',
  MAP_GENERATE_GRAPH: 'generuj graf',

  MENU_FEATURE_FILTER: 'filtruj obiekty',
  MENU_FEATURE_FILTER_CHECK_ALL: 'zaznacz wszystkie',
  MENU_FEATURE_FILTER_UNCHECK_ALL: 'odznacz wszystkie',

  MAP_MENU_ROTATION_LABEL: 'obrót w stopniach',
  MAP_MENU_ZOOM_LABEL: 'przybliżenie mapy',
  MAP_MENU_SQUARE_NAME: 'kwadrat',
  MAP_MENU_BOX_NAME: 'prostokąt',
  MAP_MENU_POLYGON_NAME: 'wielokąt',
  MAP_MENU_UNION_NAME: 'scalanie',
  MAP_MENU_DIFFERENCE_NAME: 'różnica',
  MAP_MENU_UNGROUP_NAME: 'rozgrupowanie',
  MAP_MODAL_ERROR_TITLE: 'Operacja się nie powiodła',
  MAP_MODAL_MERGING_ERROR: 'Nie można scalić. Jeden z zaznaczonych obiektów to punkt lub linia.',
  MAP_MODAL_HELP_TITLE: 'Pomoc użytkownika',
  MAP_MODAL_HELP_HEADER: 'Skróty klawiszowe:',
  MAP_MODAL_HELP_COPY: 'skopiuj zaznaczone obiekty',
  MAP_MODAL_HELP_CUT: 'wytnij zaznaczone obiekty',
  MAP_MODAL_HELP_PASTE: 'wklej skopiowane obiekty',
  MAP_MODAL_HELP_DUPLICATE: 'duplikuj zaznaczone obiekty',
  MAP_MODAL_HELP_EDIT_CTRL: 'podczas edycji kształtu, aby skalować tylko w jednym kierunku',
  MAP_MODAL_HELP_EDIT_SHIFT: 'podczas edycji kształtu, aby zachować proporcje',
  ROTATION_LABEL: 'obrót w stopniach',

  CHOOSE_MAP_OBJECT_TYPE: 'Wybierz typ obiektu',

  TABLE_SHOWING: 'Pokazane',
  TABLE_OF: 'z',
  TABLE_RESULTS: 'Wyników',
  TABLE_NO_DATA: 'Brak Danych',
  TABLE_SEARCH: 'Szukaj',
  TABLE_RESET: 'Reset',

  CRUD_ADD_NEW: 'Dodaj nowy rekord',
  CRUD_SAVE: 'Zapisz',
  CRUD_UPDATE: 'Aktualizuj',
  CRUD_CANCEL: 'Anuluj',
  CRUD_ARE_YOU_SURE: 'Czy na pewno chcesz usunąć wybrany wiersz?',
  CRUD_ACTIONS: 'Akcje',
  CRUD_ADD_RECORD_SUCCESS: 'Dodawanie zakończone pomyślnie',
  CRUD_EDIT_RECORD_SUCCESS: 'Edycja zakończona pomyślnie',
  CRUD_REMOVE_RECORD_SUCCESS: 'Usuwanie zakończone pomyślnie',
  CRUD_CLOSE: 'Zamknij',
  CRUD_SEND: 'Wyślij',

  FORMS_EMAIL: 'E-mail',
  FORMS_EMAIL_OR_LOGIN: 'E-mail lub login',
  FORMS_PASSWORD: 'Hasło',
  FORMS_CONFIRM_PASSWORD: 'Potwierdź hasło',
  FORMS_FIELD_REQUIRED: 'To pole jest wymagane',
  FORMS_EMAIL_NOT_CORRECT: 'Podany e-mail jest niepoprawny',
  FORMS_PASSWORD_NOT_CORRECT: 'Hasło musi zawierać co najmniej jedną dużą, małą literę i jedną cyfrę',
  FORMS_NOT_SAME_PASSWORD: 'Hasła muszą być takie same',

  AUTH_SIGNIN: 'Zaloguj',
  AUTH_LOGOUT: 'Wyloguj',
  AUTH_CONFIRM: 'Potwierdź',
  AUTH_FORGOT_PASSWORD: 'Nie pamiętam hasła',
  AUTH_FORGOT_PASSWORD_SUCCESS: 'Link do resetu hasła został wysłany na podany adres e-mail',
  AUTH_FORGOT_PASSWORD_SUBTITLE: 'Nie pamiętasz hasła?',
  AUTH_FORGOT_PASSWORD_DESCRIPTION: 'Wprowadź adres e-mail na który zostało zarejestrowane konto.',
  AUTH_FORGOT_PASSWORD_DESCRIPTION_LDAP: 'Jeśli posiadasz konto LDAP skontakuj się z administratorem.',
  AUTH_BACK_TO_SIGNIN: 'Powrót do strony logowania',
  AUTH_RESET_PASSWORD_SUBTITLE: 'Resetuj hasło',
  AUTH_RESET_PASSWORD_DESCRIPTION: 'Wpisz nowe hasło i je potwierdź',
  AUTH_RESET_PASSWORD_SUCCESS: 'Hasło zostało zmienione na nowe. Możesz zalogować się',

  TITLE_404: '404',
  SUBTITLE_404: 'Pojawiła się przeszkoda',
  DESC_404: 'Strona, której szukasz prawdopodobnie nie istnieje lub została przeniesiona.',
  BACK_404: 'Powrót do strony głównej',
  TITLE_403: '403',
  SUBTITLE_403: 'Pojawiła się przeszkoda',
  DESC_403: 'Nie możesz otrzymać dostępu do tego zasobu..',
  BACK_403: 'Powrót do strony głównej',
  SERVER_ERROR: 'Nieznany błąd serwera',

  SELECT_VALUE_ALL: 'Wszystko',
  SELECT_VALUE_CAMPUS: 'Kampus',
  SELECT_VALUE_BUILDING: 'Budynek',
  SELECT_VALUE_ROOM: 'Pokój',
  SELECT_VALUE_BEACON: 'Beacon',
  SELECT_VALUE_POI: 'POI',
  SELECT_VALUE_ROUTE: 'Ścieżka',
  SELECT_VALUE_LEVEL: 'Poziom',
  SELECT_VALUE_ROI: 'ROI',
  SELECT_VALUE_ROUTE_ROUTING: 'Ścieżka routingu',
  SELECT_VALUE_WALL: 'Ściana',
  SELECT_VALUE_STAIRS: 'Schody',
  SELECT_VALUE_BACKGROUND: 'Tło',
  SELECT_VALUE_DOOR: 'Drzwi',
  SELECT_VALUE_PARKING: 'Parking',
  SELECT_VALUE_WINDOWS: 'Okno',
  SELECT_VALUE_FURNITURE: 'Mebel',
  SELECT_VALUE_CONNECTOR: 'Konektor',

  SELECT_VALUE_POINT: 'Punkt',
  SELECT_VALUE_POLYGON: 'Wielokąt',
  SELECT_VALUE_CIRCLE: 'Koło',
  SELECT_VALUE_SQUARE: 'Kwadrat',
  SELECT_VALUE_BOX: 'Prostokąt',
  SELECT_VALUE_LINESTRING: 'Linia',

  MODAL_DELETE_FEATURE_TITLE_CORRECT: 'Czy na pewno?',
  MODAL_DELETE_FEATURE_TITLE_INCORRECT: 'Zaznacz obiekt, który należy usunać!',
  MODAL_DELETE_FEATURE_OK_CORRECT: 'TAK',
  MODAL_DELETE_FEATURE_OK_INCORRECT: 'OK',
  MODAL_DELETE_FEATURE_CANCEL_CORRECT: 'NIE',
  MODAL_DELETE_FEATURE_TEXT_CORRECT: 'Jesteś pewny? Operacji nie można odwrócić, a wybrany obiekt zostanie usunięty na stałe!',
  MODAL_DELETE_FEATURE_TEXT_INCORRECT:
    'Nie zaznaczono żadnego obiektu - brak informacji, który obiekt ma zostać usunięty. Należy zaznaczyć kształt przed wykonaniem żądanej operacji.',

  NOT_EMPTY_COLLECTIONS_BUTTON_OK: 'Akceptuj i kontynuuj',
  NOT_EMPTY_COLLECTIONS_BUTTON_CANCEL: 'Anuluj',
  NOT_EMPTY_COLLECTIONS_TITLE: 'Istnieją niezapisane zmiany na obiektach mapowych',
  NOT_EMPTY_COLLECTIONS_TEXT: 'Dane, które nie zostały zapisane zostaną bezpowrotnie usunięte i przejdziesz do żądanego widoku.',

  DRAWER_OBJECT_MODIFY_BUTTON_SUBMIT: 'Zapisz',
  DRAWER_OBJECT_MODIFY_NOTIFICATION_SUCCESS: 'Zmiany wprowadzone poprawnie',
  DRAWER_OBJECT_MODIFY_NOTIFICATION_ERROR: 'Sprawdź czy wszystkie wymagane pola zostały wypełnione',

  NOTIFICATION_PHOTO_ERROR_FORMAT: 'Tylko JPG/PNG',
  NOTIFICATION_PHOTO_GIF_ERROR_FORMAT: 'Tylko JPG/PNG/GIF',
  NOTIFICATION_ICON_ERROR_FORMAT: 'Tylko PNG',
  NOTIFICATION_VIDEO_ERROR_FORMAT: 'Tylko MP4/WEBM',
  NOTIFICATION_AUDIO_ERROR_FORMAT: 'Tylko WAV/MP3',
  NOTIFICATION_PHOTO_ERROR_SIZE: 'Obraz musi być mniejszy niż 2MB',
  NOTIFICATION_ICON_ERROR_SIZE: 'Ikona musi być mniejsza niż 20KB',
  NOTIFICATION_VIDEO_ERROR_SIZE: 'Plik wideo musi być mniejszy niż 120MB',
  NOTIFICATION_AUDIO_ERROR_SIZE: 'Plik audio musi być mniejszy niż 10MB',

  NOTIFICATION_INFO_NO_DATA: 'Żadne zmiany nie zostały wprowadzone',
  NOTIFICATION_INFO_SAVE_DATA_SUCCESS: 'Zmiany zostały wysłane na serwer w celu przetworzenia',
  NOTIFICATION_INFO_SAVE_DATA_ERROR: 'Wystąpił nieoczekiwany błąd',
  NOTIFICATION_INFO_GENERATE_GRAPH: 'Generowanie grafu w toku.. Może to zająć chwilę..',

  LOCATION_FINDER_CATEGORIES_FROM_MAP: 'Wyszukaj spośród istniejących lokalizacji',
  LOCATION_FINDER_CATEGORIES_LAT_LON: 'Zaznacz punkt na mapie',

  LATITUDE: 'Szerokość geograficzna',
  LONGITUDE: 'Długość geograficzna',

  NO_DATA: 'Brak',
  PROMPT_MESSAGE: 'Wprowadzone zmiany mogą nie zostać zapisane.',

  REQUIRED_FIELD_NOT_CORRECT: 'Sprawdź czy wszystkie wymagane pola zostały wypełnione',

  //STATUS OPTIONS
  STATUS_OPTION_ACTIVE: 'Aktywny',
  STATUS_OPTION_INACTIVE: 'Nieaktywny',

  //LANGUAGES
  LANGUAGE_PL: 'Polski',
  LANGUAGE_EN: 'Angielski',

  //NOTIFICATIONS TYPES
  NOTIFICATION_TYPE_GENERAL: 'Ogólne',
  NOTIFICATION_TYPE_MARKETING: 'Marketingowe',
  NOTIFICATION_TYPE_PUSH: 'Push',
  NOTIFICATION_TYPE_ROUTE: 'Ścieżkowe',

  //BUTTONS TYPES
  BUTTON_TYPE_BEFORE_ARTICLES: 'Przed aktualnościami',
  BUTTON_TYPE_BEFORE_EVENTS: 'Przed wydarzeniami',

  //USER ROLES
  USER_ROLE_ADMIN: 'Admin',
  USER_ROLE_MODERATOR: 'Moderator',
  USER_ROLE_AD_USER: 'Użytkownik z AD',
  USER_ROLE_AD_EMPLOYEE: 'Pracownik z AD',
  USER_ROLE_USER: 'Użytkownik',

  //UPLOAD_VALUES
  UPLOAD_VALUE_PHOTO: 'Zdjęcie',
  UPLOAD_VALUE_ICON: 'Ikona',
  UPLOAD_VALUE_VIDEO: 'Plik wideo',
  UPLOAD_VALUE_AUDIO: 'Plik audio',

  //TOOLTIPS MENU INTERACTION
  TOOLTIP_SELECT: 'Tryb zaznaczania',
  TOOLTIP_TYPE_SHAPES: 'Typy kształtów',
  TOOLTIP_LINE: 'Linia',
  TOOLTIP_POINT: 'Punkt',
  TOOLTIP_MODIFY: 'Edytuj',
  TOOLTIP_COPYCUT: 'Kopiuj/wytnij',
  TOOLTIP_PASTE: 'Wklej',
  TOOLTIP_TYPE_MERGE: 'Typy operacji',
  TOOLTIP_DELETE: 'Usuń',
  TOOLTIP_HELP: 'Pomoc',

  //CRUDS TLN
  CATEGORIES_NAME_PL: 'Nazwa (PL)',
  CATEGORIES_NAME_EN: 'Nazwa (EN)',
  CATEGORIES_IS_VISIBLE: 'Widoczność',
  CATEGORIES_IS_VISIBLE_ICON: 'Widoczność ikon',
  CATEGORIES_IS_VISIBLE_FILTER: 'Widoczność na pasku kategorii',
  CATEGORIES_FILTER_RANGE: 'Zasięg wyszukiwania [m]',
  CATEGORIES_TITLE: 'Kategorie mapowe',
  CATEGORIES_ADD_CATEGORY: 'Dodaj kategorię mapową',
  CATEGORIES_EDIT_CATEGORY: 'Edytuj kategorię mapową',

  ARTICLES_TITLE: 'Aktualności',
  ARTICLES_ADD_ARTICLE: 'Dodaj aktualność',
  ARTICLES_EDIT_ARTICLE: 'Edytuj aktualność',
  ARTICLES_PREVIEW: 'Podgląd',
  ARTICLES_PREVIEW_TITLE: 'Widok',
  ARTICLES_CATEGORY: 'Kategoria',
  ARTICLES_PHOTO: 'Zdjęcie',
  ARTICLES_STATUS: 'Status',
  ARTICLES_LANGUAGE: 'Język',
  ARTICLES_CAMPUS: 'Kampus',
  ARTICLES_NOTIFICATION: 'Notyfikacja PUSH o aktualności',

  SINGLE_ARTICLE_NAME: 'Nazwa',
  SINGLE_ARTICLE_CATEGORY: 'Kategoria',
  SINGLE_ARTICLE_DESCRIPTION: 'Opis',
  SINGLE_ARTICLE_PHOTO: 'Zdjęcie',
  SINGLE_ARTICLE_STATUS: 'Status',
  SINGLE_ARTICLE_LANGUAGE: 'Język',

  ARTICLE_EVENT_NOTIFICATION_SENT: 'Notyfikacja została już wysłana',
  ARTICLES_EVENTS_NOTIFICATION_TITLE: 'Notyfikacja',

  EVENTS_TITLE: 'Wydarzenia',
  EVENTS_ADD_EVENT: 'Dodaj wydarzenie',
  EVENTS_EDIT_EVENT: 'Edytuj wydarzenie',
  EVENTS_PREVIEW: 'Podgląd',
  EVENTS_PREVIEW_TITLE: 'Widok',
  EVENTS_CATEGORY: 'Kategoria',
  EVENTS_PHOTO: 'Zdjęcie',
  EVENTS_LOCATION: 'Lokalizacja',
  EVENTS_LOCATION_PLACEHOLDER: 'Zacznij wpisywać..',
  EVENTS_LOCATION_TYPE_PLACEHOLDER: 'Wybierz kategorię lokalizacji',
  EVENTS_STATUS: 'Status',
  EVENTS_LANGUAGE: 'Język',
  EVENTS_CAMPUS: 'Kampus',
  EVENTS_NOTIFICATION: 'Notyfikacja PUSH o wydarzeniu',

  EVENT_LOCATION: 'Lokalizacja',
  EVENT_DATE: 'Data',

  SINGLE_EVENT_NAME: 'Nazwa',
  SINGLE_EVENT_CATEGORY: 'Kategoria',
  SINGLE_EVENT_DESCRIPTION: 'Opis',
  SINGLE_EVENT_LOCATION: 'Lokalizacja',
  SINGLE_EVENT_DATE: 'Data',
  SINGLE_EVENT_PHOTO: 'Zdjęcie',
  SINGLE_EVENT_STATUS: 'Status',
  SINGLE_EVENT_LANGUAGE: 'Język',

  CATEGORIES_CRUDS_TITLE: 'Kategorie aktualności i wydarzeń',
  CATEGORIES_CRUDS_ADD_CATEGORY: 'Dodaj kategorię aktualności i wydarzeń',
  CATEGORIES_CRUDS_EDIT_CATEGORY: 'Edytuj kategorię aktualności i wydarzeń',

  CATEGORIES_CRUDS_NAME_PL: 'Nazwa (PL)',
  CATEGORIES_CRUDS_NAME_EN: 'Nazwa (EN)',

  SOS_TITLE: 'SOS',
  SOS_USER: 'Użytkownik',
  SOS_USER_UNAUTHENTICATED: 'Użytkownik niezalogowany',
  SOS_WHERE: 'Lokalizacja',
  SOS_DATE: 'Data',
  SOS_PREVIEW_TITLE: 'Widok',
  SOS_PREVIEW: 'Podgląd',
  SOS_LANGUAGE: 'Język',

  SOS_PUSH_SEND: 'Odpowiedz',
  SOS_PUSH_REPLY: 'Zobacz odpowiedź',
  SOS_PUSH_SEND_TITLE: 'Wyślij odpowiedź push',
  SOS_PUSH_SEND_SUCCESS: 'Wysyłanie odpowiedzi push zakończone sukcesem',
  SOS_PUSH_TITLE: 'Tytuł',
  SOS_PUSH_DESCRIPTION: 'Wiadomość',
  SOS_PUSH_MODAL_TITLE: 'Odpowiedź',

  SINGLE_SOS_NAME: 'Nazwa',
  SINGLE_SOS_WHERE: 'Lokalizacja',
  SINGLE_SOS_STATUS: 'Status',
  SINGLE_SOS_DATE: 'Data',
  SINGLE_SOS_DESCRIPTION: 'Notatka',

  USER: 'Użytkownik',
  USERS_TITLE: 'Użytkownicy',
  USER_NAME: 'Imię i nazwisko',
  USER_EMAIL: 'E-mail',
  USER_ROLE: 'Rola',
  USER_ADD: 'Dodaj użytkownika',
  USER_EDIT: 'Edytuj użytkownika',
  USER_EDIT_CRUD: 'Edytuj',
  USER_PASSWORD: 'Hasło',
  USER_PASSWORD_CONFIRM: 'Powtórz hasło',
  USER_PASSWORD_CHANGE: 'Zmień hasło',

  BUTTONS_TITLE: 'Przyciski',
  BUTTONS_ADD_BUTTON: 'Dodaj przycisk',
  BUTTONS_EDIT_BUTTON: 'Edytuj przycisk',
  BUTTONS_PREVIEW: 'Podgląd',
  BUTTONS_PREVIEW_TITLE: 'Widok',
  BUTTONS_LANGUAGE: 'Język',

  BUTTON_PLACE: 'Miejsce w aplikacji mobilnej',
  BUTTON_URL: 'URL',
  BUTTON_COLOR: 'Kolor',
  BUTTON_IMAGE: 'Obrazek',
  BUTTON_STATUS: 'Status',
  BUTTON_IMAGE_DELETE: 'Usuń',

  SINGLE_BUTTON_NAME: 'Nazwa',
  SINGLE_BUTTON_DESCRIPTION: 'Opis',
  SINGLE_BUTTON_START_DATE: 'Data rozpoczęcia',
  SINGLE_BUTTON_END_DATE: 'Data zakończenia',
  SINGLE_BUTTON_PHOTO: 'Obrazek',
  SINGLE_BUTTON_STATUS: 'Status',
  SINGLE_BUTTON_COLOR: 'Kolor',
  SINGLE_BUTTON_URL: 'URL',

  ADVERTISEMENTS_TITLE: 'Reklamy',
  ADVERTISEMENTS_STATUS: 'Status',
  ADVERTISEMENTS_LOCATION: 'Lokalizacja',
  ADVERTISEMENTS_VIDEO: 'Plik wideo',
  ADVERTISEMENTS_LANGUAGE: 'Jezyk',

  ADVERTISEMENTS_ADD_ADVERTISEMENT: 'Dodaj reklamę',
  ADVERTISEMENTS_EDIT_ADVERTISEMENT: 'Edytuj reklamę',

  NOTIFICATIONS_TITLE: 'Powiadomienia',
  NOTIFICATIONS_NAME_PL: 'Nazwa (PL)',
  NOTIFICATIONS_NAME_EN: 'Nazwa (EN)',
  NOTIFICATIONS_LOCATION: 'Lokalizacja',
  NOTIFICATIONS_STATUS: 'Status',
  NOTIFICATIONS_TYPE: 'Typ',
  NOTIFICATIONS_IMAGE: 'Obrazek',
  NOTIFICATIONS_DESCRIPTION_PL: 'Opis (PL)',
  NOTIFICATIONS_DESCRIPTION_EN: 'Opis (EN)',

  NOTIFICATIONS_PUSH_TITLE: 'Powiadomienia PUSH',
  NOTIFICATIONS_PUSH_NAME_PL: 'Nazwa (PL)',
  NOTIFICATIONS_PUSH_NAME_EN: 'Nazwa (EN)',
  NOTIFICATIONS_PUSH_DESCRIPTION_PL: 'Opis (PL)',
  NOTIFICATIONS_PUSH_DESCRIPTION_EN: 'Opis (EN)',
  NOTIFICATIONS_PUSH_SENDER: 'Osoba wysyłająca',
  NOTIFICATIONS_SEND_DATE: 'Data wysłania',
  NOTIFICATIONS_PUSH_DESCRIPTION_PREVIEW_TITLE: 'Podgląd opisu',
  NOTIFICATIONS_PUSH_DESCRIPTION_PREVIEW: 'Widok',

  NOTIFICATIONS_ROUTE_TITLE: 'Powiadomienia ścieżkowe',
  NOTIFICATIONS_ROUTE_NAME_PL: 'Nazwa (PL)',
  NOTIFICATIONS_ROUTE_NAME_EN: 'Nazwa (EN)',
  NOTIFICATIONS_ROUTE_LOCATION: 'Lokalizacja',
  NOTIFICATIONS_ROUTE_STATUS: 'Status',
  NOTIFICATIONS_ROUTE_DESCRIPTION_PL: 'Opis (PL)',
  NOTIFICATIONS_ROUTE_DESCRIPTION_EN: 'Opis (EN)',
  NOTIFICATIONS_ROUTE_FILE: 'Plik',

  NOTIFICATIONS_ROUTE_ADD_NOTIFICATION: 'Dodaj powiadomienie ścieżkowe',
  NOTIFICATIONS_ROUTE_EDIT_NOTIFICATION: 'Edytuj powiadomienie ścieżkowe',

  NOTIFICATIONS_ADD_NOTIFICATION: 'Dodaj powiadomienie',
  NOTIFICATIONS_EDIT_NOTIFICATION: 'Edytuj powiadomienie',

  NOTIFICATIONS_PUSH_SEND: 'Wyślij powiadomienie push',
  NOTIFICATIONS_PUSH_SEND_SUCCESS: 'Wysyłanie powiadomienia push zakończone sukcesem',

  NOTIFICATIONS_PUSH_SEND_NOTIFICATION_PUSH: 'Wyślij powiadomienie push',

  CAMPUSES_TITLE: 'Etykiety kampusów',
  CAMPUSES_ADD_CAMPUS: 'Dodaj etykietę kampusu',
  CAMPUSES_EDIT_CAMPUS: 'Edytuj etykietę kampusu',

  CAMPUSES_NAME_PL: 'Nazwa (PL)',
  CAMPUSES_NAME_EN: 'Nazwa (EN)',

  SOS_CONTACTS_TITLE: 'Lista kontaktów SOS',
  SOS_CONTACTS_NAME: 'Nazwa',
  SOS_CONTACTS_PHONE: 'Telefon',
  SOS_CONTACTS_EMAIL: 'E-mail',
  SOS_CONTACTS_STATUS: 'Status',
  SOS_CONTACTS_PHONE_EMAIL_VALIDATOR: 'Jedno z pól kontaktowych musi być wypełnione',

  SOS_CONTACTS_ADD_CONTACT: 'Dodaj kontakt SOS',
  SOS_CONTACTS_EDIT_CONTACT: 'Edytuj kontakt SOS',

  PARKING_LOTS_TITLE: 'Parkingi',
  PARKING_LOTS_NAME_PL: 'Nazwa (PL)',
  PARKING_LOTS_NAME_EN: 'Nazwa (EN)',
  PARKING_LOTS_CAMPUS_PL: 'Nazwa kampusu (PL)',
  PARKING_LOTS_CAMPUS_EN: 'Nazwa kampusu (EN)',
  PARKING_LOTS_SYSTEM_ID: 'ID systemu',
  PARKING_LOTS_PARKING_LOT_ID: 'ID parkingu',
  PARKING_LOTS_STATUS: 'Status',
  PARKING_LOTS_ALL: 'Wszystkie miejsca',
  PARKING_LOTS_TAKEN: 'Zajęte miejsca',
  PARKING_LOTS_TOTAL: 'Realne wszystkie miejsca',
  PARKING_LOTS_FREE: 'Wolne miejsca',
  PARKING_LOTS_PERCENT: 'Procent wolnych miejsc',

  PARKING_LOTS_EDIT_PARKING_LOT: 'Edytuj parking',
  PARKING_LOTS_LEGEND: 'Informacje o zajętości',
  PARKING_LOTS_LEGEND_TITLE: 'Informacje o zajętości parkingów',
  PARKING_LOTS_LEGEND_SCOPE: 'Zakres procentowy',
  PARKING_LOTS_LEGEND_DESCRIPTION: 'Opis',
  PARKING_LOTS_LEGEND_DESCRIPTION_TITLE_PL: 'Tytuł (PL)',
  PARKING_LOTS_LEGEND_DESCRIPTION_TITLE_EN: 'Tytuł (EN)',
  PARKING_LOTS_LEGEND_DESCRIPTION_DESC_PL: 'Opis (PL)',
  PARKING_LOTS_LEGEND_DESCRIPTION_DESC_EN: 'Opis (EN)',

  BEACONS_TITLE: 'Beacony',
  BEACONS_EDIT_BEACON: 'Edytuj beacon',
  BEACONS_EDIT_NAME: 'Nazwa identyfikująca',
  BEACONS_EDIT_LEVEL: 'Poziom',
  BEACONS_EDIT_STATUS: 'Status',
  BEACONS_EDIT_MAC: 'Adres MAC',
  BEACONS_EDIT_BATTERY: 'Bateria [%]',
  BEACONS_EDIT_POWER: 'Korekcja mocy (txPower) [dBm]',
  BEACONS_EDIT_DEVICE: 'Device',
  BEACONS_EDIT_INTERVAL: ' Interwał [ms]',
  BEACONS_EDIT_IS_OUTSIDE: 'Zewnętrzny',
  BEACONS_EDIT_PROFILE: 'Warstwa',
  BEACONS_EDIT_RSSI: 'Współczynnik siły sygnału (RSSI@1m) [dBm]',
  BEACONS_EDIT_PHOTO: 'Zdjęcie z montażu',

  BEACONS_NAME: 'Nazwa identyfikująca',
  BEACONS_MAC: 'Adres MAC',
  BEACONS_BATTERY: 'Poziom naładowania baterii',

  OPINIONS_TITLE: 'Opinie',
  OPTIONS_TEXT: 'Tekst',
  OPTIONS_USER: 'Użytkownik',
  OPTIONS_DATE: 'Data',
  OPTIONS_ANSWERED: 'Status odpowiedzi',
  OPTIONS_USER_UNAUTHENTICATED: 'Użytkownik niezalogowany',
  OPTIONS_DETAILS_REPLY: 'Szczegóły',
  OPTIONS_DETAILS_REPLY_BODY: 'Opinia',

  OPINION_REPLY_PUSH_MODAL_TITLE: 'Szczegóły opinii',
  OPINION_REPLY_SEND: 'Wyślij odpowiedź',
  OPINION_REPLY_PUSH_SUCCESS: 'Wysyłanie odpowiedzi push zakończone sukcesem',
  OPINION_REPLY_TITLE: 'Tytuł odpowiedzi',
  OPINION_REPLY_BODY: 'Treść odpowiedzi',

  //Map forms
  MAP_FORM_CAMPUS_NAME: 'Nazwa (PL)',
  MAP_FORM_CAMPUS_NAME_EN: 'Nazwa (EN)',
  MAP_FORM_CAMPUS_KEY: 'Skrót',
  MAP_FORM_CAMPUS_SOURCE_ID: 'ID źródłowe',
  MAP_FORM_CAMPUS_CATEGORY: 'Kategoria',

  MAP_FORM_BUILDING_NAME: 'Nazwa budynku (PL)',
  MAP_FORM_BUILDING_NAME_EN: 'Nazwa budynku (EN)',
  MAP_FORM_BUILDING_KEY: 'Skrót',
  MAP_FORM_BUILDING_CAMPUS: 'Kampus',
  MAP_FORM_BUILDING_ADDRESS: 'Adres',
  MAP_FORM_BUILDING_SOURCE_ID: 'ID źródłowe',
  MAP_FORM_BUILDING_CATEGORY: 'Kategoria',

  MAP_FORM_LEVEL_NAME: 'Nazwa kondygnacji (PL)',
  MAP_FORM_LEVEL_NAME_EN: 'Nazwa kondygnacji (EN)',
  MAP_FORM_LEVEL_KEY: 'Skrót',
  MAP_FORM_LEVEL_BUILDING: 'Budynek',
  MAP_FORM_LEVEL_SORT: 'Sortowanie',
  MAP_FORM_LEVEL_LABEL_PL: 'Nazwa poziomu w aplikacji (PL)',
  MAP_FORM_LEVEL_LABEL_EN: 'Nazwa poziomu w aplikacji (EN)',
  MAP_FORM_LEVEL_PROFILE: 'Warstwa',
  MAP_FORM_LEVEL_SOURCE_ID: 'ID źródłowe',

  MAP_FORM_BEACONPLACE_NAME: 'Nazwa identyfikująca',
  MAP_FORM_BEACONPLACE_LEVEL: 'Poziom',
  MAP_FORM_BEACONPLACE_STATUS: 'Status',
  MAP_FORM_BEACONPLACE_MAC: 'Adres MAC',
  MAP_FORM_BEACONPLACE_BATTERY: 'Bateria [%]',
  MAP_FORM_BEACONPLACE_POWER: 'Korekcja mocy (txPower) [dBm]',
  MAP_FORM_BEACONPLACE_DEVICE: 'Device',
  MAP_FORM_BEACONPLACE_INTERVAL: ' Interwał [ms]',
  MAP_FORM_BEACONPLACE_IS_OUTSIDE: 'Zewnętrzny',
  MAP_FORM_BEACONPLACE_PROFILE: 'Warstwa',
  MAP_FORM_BEACONPLACE_RSSI: 'Współczynnik siły sygnału (RSSI@1m) [dBm]',

  MAP_FORM_ROOM_NAME: 'Nazwa (PL)',
  MAP_FORM_ROOM_NAME_EN: 'Nazwa (EN)',
  MAP_FORM_ROOM_KEY: 'Skrót',
  MAP_FORM_ROOM_LEVEL: 'Poziom',
  MAP_FORM_ROOM_CATEGORY: 'Kategoria',
  MAP_FORM_ROOM_PROFILE: 'Warstwa',

  MAP_FORM_WALL_LEVEL: 'Poziom',
  MAP_FORM_WALL_ROUTINGAVAILABLE: 'Routowalny',
  MAP_FORM_WALL_PROFILE: 'Warstwa',

  MAP_FORM_POI_NAME: 'Nazwa (PL)',
  MAP_FORM_POI_NAME_EN: 'Nazwa (EN)',
  MAP_FORM_POI_KEY: 'Skrót',
  MAP_FORM_POI_LEVEL: 'Poziom',
  MAP_FORM_POI_PARENT_ROI: 'Nadrzędny ROI',
  MAP_FORM_POI_MIN_ZOOM: 'Minimalny zoom',
  MAP_FORM_POI_MAX_ZOOM: 'Maksymalny zoom',
  MAP_FORM_POI_PROFILE: 'Warstwa',
  MAP_FORM_POI_IS_VISIBLE: 'Widoczność na mapie w aplikacji mobilnej',

  MAP_FORM_ROI_NAME: 'Nr pomieszczenia (PL)',
  MAP_FORM_ROI_NAME_EN: 'Nr pomieszczenia (EN)',
  MAP_FORM_ROI_KEY: 'Skrót',
  MAP_FORM_ROI_DESCRIPTION: 'Opis, jednostka (PL)',
  MAP_FORM_ROI_DESCRIPTION_EN: 'Opis, jednostka (EN)',
  MAP_FORM_ROI_LEVEL: 'Poziom',
  MAP_FORM_ROI_MIN_ZOOM: 'Minimalny zoom',
  MAP_FORM_ROI_MAX_ZOOM: 'Maksymalny zoom',
  MAP_FORM_ROI_POI: 'Przypisany POI',
  MAP_FORM_ROI_SIZE: 'Powierzchnia',
  MAP_FORM_ROI_ALIAS: 'Alias',
  MAP_FORM_ROI_CATEGORY: 'Kategoria (rodzaj pomieszczenia)',
  MAP_FORM_ROI_PROFILE: 'Warstwa',
  MAP_FORM_ROI_DISABLE_FOR_DISABLED: 'Dostępny dla osób z niepełnosprawnościami',
  MAP_FORM_ROI_UMED_ROOM: 'Sala',
  MAP_FORM_ROI_IS_VISIBLE: 'Widoczność na mapie w aplikacji mobilnej',

  MAP_FORM_ROUTE_NAME: 'Nazwa',
  MAP_FORM_ROUTE_KEY: 'Skrót',
  MAP_FORM_ROUTE_LEVEL: 'Poziom',
  MAP_FORM_ROUTE_DISABILITY_FRIENDLY: 'Dostępny dla osób z niepełnosprawnościami',
  MAP_FORM_ROUTE_TRAVELTIME: 'Czas przejścia',
  MAP_FORM_ROUTE_STATUS: 'Status',
  MAP_FORM_ROUTE_PROFILE: 'Warstwa',
  MAP_FORM_ROUTE_DIRECTION: 'Kierunek',

  MAP_FORM_CONNECTORS_NAME: 'Nazwa',
  MAP_FORM_CONNECTORS_PROFILE: 'Warstwa',
  MAP_FORM_CONNECTORS_KEY: 'Skrót',
  MAP_FORM_CONNECTORS_CONNECTOR: 'Cel',
  MAP_FORM_CONNECTORS_LEVEL: 'Poziom',
  MAP_FORM_CONNECTORS_DISABILITY_FRIENDLY: 'Dostępny dla osób z niepełnosprawnościami',
  MAP_FORM_CONNECTORS_DIRECTION: 'Kierunek',
  MAP_FORM_CONNECTORS_TRAVELTIME: 'Czas przejścia',
  MAP_FORM_CONNECTORS_ATTR: 'Typ przejścia',
  MAP_FORM_CONNECTORS_STATUS: 'Status',

  MAP_FORM_CONNECTORS_DIRECTION_START_TO_END: 'Początek - koniec',
  MAP_FORM_CONNECTORS_DIRECTION_END_TO_START: 'Koniec - początek',
  MAP_FORM_CONNECTORS_DIRECTION_BOTH: 'Dwukierunkowy',

  MAP_FORM_CONNECTORS_ATTR_STAIRS: 'Schody',
  MAP_FORM_CONNECTORS_ATTR_MOVING_STAIRS: 'Ruchome schody',
  MAP_FORM_CONNECTORS_ATTR_LIFT: 'Winda',
  MAP_FORM_CONNECTORS_ATTR_ENTRY: 'Wejście',
  MAP_FORM_CONNECTORS_ATTR_EXIT: 'Wyjście',

  //error codes
  1000: 'Nieprawidłowy login lub hasło',
  1001: 'Nazwa użytkownika jest wymagana',
  1002: 'Adres e-mail jest wymagany',
  1003: 'Nieprawidłowy format adresu e-mail',
  1004: 'Konto z podanym adresem e-mail już istnieje',
  1005: 'Hasło jest wymagane',
  1006: 'Hasło jest za krótkie',
  1010: 'Nieprawidłowy token',
  1018: 'Nazwa użytkownika jest za krótka',
  1020: 'Nazwa użytkownika jest wymagana',
  1021: 'Adres e-mail jest wymagany',
  1022: 'Nieprawidłowy format adresu e-mail',
  1023: 'Konto z podanym adresem e-mail już istnieje',
  1024: 'Hasło jest wymagane',
  1028: 'Nazwisko użytkownika jest wymagana',
  1029: 'Podany użytkownik już istnieje',
  1034: 'Nie znaleziono użytkownika',
  1035: 'Błąd wysyłania wiadomości resetującej hasło',
  1201: 'Nazwa kategorii (PL) jest wymagana',
  1202: 'Nazwa kategorii (PL) jest za krótka',
  1203: 'Nazwa kategorii (PL) jest za długa',
  1204: 'Kolor kategorii jest wymagany',
  1205: 'Nieprawidłowy format koloru kategorii',
  1207: 'Ikona kategorii nie istnieje',
  1208: 'Nieprawidłowy format aktywności kategorii',
  1209: 'Język kategorii jest wymagany',
  1210: 'Nieprawidłowy język kategorii',
  1211: 'Usuwanie kategorii nie powiodło się',
  1212: 'Edycja kategorii nie powiodła się',
  1213: 'Tworzenie kategorii nie powiodło się',
  1214: 'Usuwanie kategorii nie powiodło się, katgoria nie istnieje',
  1215: 'Usuwanie kategorii nie powiodło się, istnieją przypisane do niej wydarzenia',
  1216: 'Usuwanie kategorii nie powiodło się, istnieją przypisane do niej aktualności',
  1217: 'Nie można ustawić kategorii na nieaktywną z powodu przypisanych do niej aktualności lub wydarzeń',
  1218: 'Nazwa kategorii (EN) jest wymagana',
  1219: 'Nazwa kategorii (EN) jest za krótka',
  1220: 'Nazwa kategorii (EN) jest za długa',
  1230: 'Widoczność kategorii jest wymagana',
  1231: 'Nieprawidłowy format widoczności kategorii',
  1232: 'Widoczność ikony kategorii jest wymagana',
  1233: 'Nieprawidłowy format widoczności ikony kategorii',
  1234: 'Zasieg wyszukiwania kategorii wymagany',
  1235: 'Nieprawidłowy format zasięgu wyszukiwana kategorii',
  1300: 'Błąd podczas tworzenia typu obiektu mapowego',
  1301: 'Błąd podczas usunięcia typu obiektu mapowego',
  1302: 'Błąd podczas edycji typu obiektu mapowego',
  1303: 'Nazwa typu obiektu mapowego jest wymagana',
  1304: 'Nazwa typu obiektu mapowego jest za krótka',
  1305: 'Nazwa typu obiektu mapowego jest za długa',
  1306: 'Klucz typu obiektu mapowego jest wymagany',
  1307: 'Klucz typu obiektu mapowego jest za krótki',
  1308: 'Klucz typu obiektu mapowego jest za długi',
  1400: 'Błąd podczas tworzenia atrubytu typu obiektu mapowego',
  1401: 'Błąd podczas edycji atrubytu typu obiektu mapowego',
  1402: 'Błąd podczas usuwania atrubytu typu obiektu mapowego',
  1403: 'Nazwa atrubytu typu obiektu mapowego jest wymagana',
  1404: 'Nazwa atrubytu typu obiektu mapowego jest za krótka',
  1405: 'Nazwa atrubytu typu obiektu mapowego jest za długa',
  1406: 'Etykieta atrubytu typu obiektu mapowego jest za długa',
  1407: 'Typ atrubytu obiektu mapowego jest wymagany',
  1408: 'Nieprawidłowy typ atrybutu obiektu mapowego',
  1409: 'Typ atrybutu obiektu mapowego jest wymagany',
  1500: 'Błąd podczas tworzenia obiektu mapowego',
  1501: 'Błąd podczas edycji obiektu mapowego',
  1502: 'Błąd podczas usuwania obiektu mapowego',
  1503: 'Punkty geolokalizacyjne obiektu są wymagane',
  1504: 'Nieprawidłowy format obiektu mapowego',
  1505: 'Typ obiektu mapowego jest wymagany',
  1506: 'Nieprawidłowy typ obiektu mapowego',
  1507: 'Nieobsługiwany typ obiektu mapowego',
  1701: 'Nazwa aktualności jest wymagana',
  1702: 'Nazwa aktualności jest za krótka',
  1703: 'Nazwa aktualności jest za długa',
  1704: 'Kategoria aktualności jest wymagana',
  1705: 'Nieprawidłowa kategoria aktualności',
  1706: 'Nieprawidłowy format pliku graficznego',
  1707: 'Nieprawidłowy format pliku graficznego',
  1708: 'Opis aktualności jest wymagany',
  1709: 'Opis aktualności jest za krótki',
  1710: 'Opis aktualności jest za długi',
  1711: 'Status aktualności jest wymagany',
  1712: 'Nieprawidłowy status aktualności',
  1713: 'Język aktualności jest wymagany',
  1714: 'Nieprawidłowy język aktualności',
  1715: 'Nieprawidłowy kampus aktualności',
  1716: 'Nieprawidłowa kategoria aktualności - nieaktywna',
  1720: 'Błąd podczas tworzenia aktualności',
  1721: 'Błąd podczas usuwania aktualności',
  1722: 'Błąd podczas edycji aktualności',
  1801: 'Nazwa wydarzenia jest wymagana',
  1802: 'Nazwa wydarzenia jest za krótka',
  1803: 'Nazwa wydarzenia jest za długa',
  1804: 'Nieprawidłowy format pliku graficznego',
  1805: 'Nieprawidłowy format pliku graficznego',
  1806: 'Lokalizacja wydarzenia jest wymagana',
  1808: 'Nieprawidłowa lokalizacja wydarzenia',
  1810: 'Data wydarzenia jest wymagana',
  1811: 'Nieprawidłowy format daty wydarzenia',
  1812: 'Opis wydarzenia jest wymagany',
  1813: 'Opis wydarzenia jest za krótki',
  1814: 'Opis wydarzenia jest za długi',
  1820: 'Kategoria wydarzenia jest wymagana',
  1821: 'Nieprawidłowa kategoria wydarzenia',
  1822: 'Status wydarzenia jest wymagany',
  1823: 'Nieprawidłowy status wydarzenia',
  1824: 'Język wydarzenia jest wymagany',
  1825: 'Nieprawidłowy język wydarzenia',
  1826: 'Nieprawidłowy kampus wydarzenia',
  1827: 'Nieprawidłowa kategoria wydarzenia - nieaktywna',
  1830: 'Błąd podczas tworzenia wydarzenia',
  1831: 'Błąd podczas usuwania wydarzenia',
  1832: 'Błąd podczas edycji wydarzenia',
  1833: 'Błąd usuwania kategorii - przypisana do wydarzenia',
  1834: 'Błąd usuwania kategorii - przypisana do aktualności',
  1835: 'Wybrana kategoria nie istnieje',
  1901: 'Błąd podczas tworzenia użytkownika',
  1902: 'Błąd podczas edycji użytkownika',
  1903: 'Błąd podczas usuwania użytkownika',
  1904: 'Nie można usunąć użytkownika AD',
  1905: 'Nazwa użytkownika jest za długa',
  1906: 'Nazwa użytkownika jest za krótka',
  1907: 'Nazwisko użytkownika jest za długie',
  1908: 'Hasło jest za krótkie',
  1909: 'Hasło jest za długie',
  1910: 'Rola użytkownika jest wymagna',
  1911: 'Nieprawidłowy format ról (muszą być przekazane w tablicy)',
  1912: 'Nieprawidłowy format ról (muszą być numeryczne)',
  1913: 'Nie można zmienić hasła użytkownikowi z AD',
  2000: 'Błąd podczas tworzenia roli',
  2001: 'Błąd podczas usuwania roli',
  2002: 'Błąd podczas edycji roli',
  2003: 'Nazwa roli jest wymagana',
  2004: 'Nazwa roli jest za krótka',
  2005: 'Nazwa roli jest za długa',
  2010: 'Błąd podczas tworzenia uprawnienia',
  2011: 'Błąd podczas usuwania uprawnienia',
  2012: 'Błąd podczas edycji uprawnienia',
  2013: 'Nazwa uprawnienia jest wymagana',
  2014: 'Nazwa uprawnienia jest za krótka',
  2015: 'Nazwa uprawnienia jest za długa',
  2101: 'Nazwa SOS jest wymagana',
  2102: 'Nazwa SOS jest za krótka',
  2103: 'Nazwa SOS jest za długa',
  2106: 'Status SOS jest wymagana',
  2110: 'Opis SOS jest wymagany',
  2111: 'Opis SOS jest za krótki',
  2112: 'Opis SOS jest za długi',
  2113: 'Odpowiedź do SOS została już wysłana',
  2120: 'Błąd podczas tworzenia SOS',
  2121: 'Błąd podczas usuwania SOS',
  2122: 'Błąd podczas edycji SOS',
  2200: 'Błąd podczas zapisywania pliku',
  2201: 'Błąd podczas usuwania pliku',
  2202: 'Błąd podczas edycji pliku',
  2203: 'Plik jest wymagany',
  2204: 'Nieprawidłowy format pliku',
  2205: 'Nieprawidłowy rozmar pliku',
  2300: 'Nazwa przycisku jest wymagana',
  2301: 'Nazwa przycisku jest za krótka',
  2302: 'Nazwa przycisku jest za długa',
  2303: 'Opis przycisku jest wymagany',
  2304: 'Opis przycisku jest za krótki',
  2305: 'Opis przycisku jest za długi',
  2306: 'Typ przycisku jest wymagany',
  2307: 'Adres URL przycisku jest wymagany',
  2308: 'Nieprawidłowy format adresu URL',
  2314: 'Status przycisku jest wymagany',
  2315: 'Nieprawidłowy ID pliku graficznego',
  2316: 'Miejsce przycisku jest wymagane',
  2318: 'Status przycisku jest wymagany',
  2319: 'Nieprawidły status przycisku',
  2350: 'Błąd podczas tworzenia przycisku',
  2351: 'Błąd podczas edycji przycisku',
  2352: 'Błąd podczas usuwania przycisku',
  2353: 'Maksymalna ilość aktywnych przycisków w wybranym języku i miejscu została osiągnięta',
  2355: 'Nieprawidłowe miejsce przycisku',
  2356: 'Kolor przycisku jest wymagany',
  2357: 'Język przycisku jest wymagany',
  2358: 'Nieprawidłowy język przycisku',
  2400: 'Nazwa kampusu jest wymagana',
  2401: 'Nazwa kampusu jest za krótka',
  2402: 'Nazwa kampusu jest za długa',
  2403: 'Język kampusu jest wymagany',
  2404: 'Nieprawidłowy język kampusu',
  2405: 'Aktywność kampusu jest wymagana',
  2406: 'Nieprawidłowy format aktywności kampusu',
  2407: 'Usuwanie etykiety kampusu nie powiodło się z powodu przypisanego do niego wydarzenia',
  2408: 'Usuwanie etykiety kampusu nie powiodło się z powodu przypisanej do niego aktualności',
  3000: 'Router nie odpowiada',
  6000: 'Błąd podczas tworzenia reklamy',
  6001: 'Nazwa reklamy jest wymagana',
  6002: 'Plik wideo reklamy jest wymagany',
  6003: 'Nieprawidłowe ID pliku wideo reklamy',
  6004: 'Język reklamy jest wymagany',
  6005: 'Nieprawidłowy język reklamy',
  6006: 'Lokalizacja reklamy jest wymagana',
  6007: 'Nieprawidłowa lokalizacja reklamy',
  6008: 'Nazwa reklamy jest za krótka',
  6009: 'Nazwa reklamy jest za długa',
  6100: 'Błąd podczas tworzenia powiadomienia',
  6101: 'Nazwa powiadomienia (PL) jest wymagana',
  6102: 'Nazwa powiadomienia (PL) jest za krótka',
  6103: 'Nazwa powiadomienia (PL) jest za długa',
  6104: 'Nazwa powiadomienia (EN) jest wymagana',
  6105: 'Nazwa powiadomienia (EN) jest za krótka',
  6106: 'Nazwa powiadomienia (EN) jest za długa',
  6107: 'Opis powiadomienia (PL) jest wymagany',
  6108: 'Opis powiadomienia (PL) jest za krótki',
  6109: 'Opis powiadomienia (PL) jest za długi',
  6110: 'Opis powiadomienia (EN) jest wymagany',
  6111: 'Opis powiadomienia (EN) jest za krótki',
  6112: 'Opis powiadomienia (EN) jest za długi',
  6113: 'Lokalizacja powiadomienia jest wymagana',
  6114: 'Nieprawidłowy format lokalizacji powiadomienia',
  6115: 'Nieprawidłowe ID lokalizacji powiadomienia',
  6116: 'Plik graficzny powiadomienia jest wymagany',
  6117: 'Nieprawidłowe ID pliku graficznego powiadomienia',
  6118: 'Status powiadomienia jest wymagany',
  6119: 'Nieprawidłowy status powiadomienia',
  6120: 'Typ powiadomienia jest wymagany',
  6121: 'Nieprawidłowy typ powiadomienia',
  6122: 'Notyfikacja PUSH nie może zostać usunięta',
  6123: 'Notyfikacja PUSH nie może zostać edytowana',
  6200: 'Błąd podczas tworzenia SOS',
  6201: 'ID SOS jest wymagany',
  6202: 'Nieprawidłowy ID SOS',
  6203: 'Tytuł SOS jest wymagany',
  6204: 'Tytuł SOS jest za krótki',
  6205: 'Tytuł SOS jest za długi',
  6206: 'Treść SOS jest wymagana',
  6207: 'Treść SOS jest za krótka',
  6208: 'Treść SOS jest za długa',
  6300: 'Błąd podczas tworzenia kontaktu SOS',
  6301: 'Nazwa kontaktu SOS jest wymagana',
  6302: 'Telefon kontaktu SOS jest wymagany',
  6303: 'Adres e-mail kontaktu SOS jest wymagany',
  6304: 'Telefon lub adres e-mail kontaktu SOS jest wymagany',
  6305: 'Status kontaktu SOS jest wymagany',
  6306: 'Nieprawidłowy status kontaktu SOS',
  6400: 'Błąd podczas tworzenia parkingu',
  6401: 'Nazwa parkingu (PL) jest wymagana',
  6402: 'Nazwa parkingu (PL) jest za krótka',
  6403: 'Nazwa parkingu (PL) jest za długa',
  6404: 'Nazwa parkingu (EN) jest wymagana',
  6405: 'Nazwa parkingu (EN) jest za krótka',
  6406: 'Nazwa parkingu (EN) jest za długa',
  6407: 'Status parkingu jest wymagany',
  6408: 'Nieprawidłowy status parkingu',
  6701: 'Tytuł odpowiedzi na opinie jest wymagany',
  6702: 'Tytuł odpowiedzi na opinie jest za krótki',
  6703: 'Tytuł odpowiedzi na opinie jest za długi',
  6711: 'Treść odpowiedzi na opinie jest wymagana',
  6712: 'Treść odpowiedzi na opinie jest za krótka',
  6713: 'Treść odpowiedzi na opinie jest za długa',
  9999: 'Nieznany błąd serwera',
};
