import React from 'react';

import { BoxCustomTitle } from './boxTitle';

import { BoxWrapper } from './box.style';

const Box = props => (
  <BoxWrapper className={`${props.className} isoBoxWrapper`} style={props.style}>
    <BoxCustomTitle title={props.title} subtitle={props.subtitle} />
    {props.children}
  </BoxWrapper>
);

export { Box };
