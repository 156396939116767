const actions = {
  LOAD_CATEGORIES: 'LOAD_CATEGORIES',
  LOAD_CATEGORIES_SUCCESS: 'LOAD_CATEGORIES_SUCCESS',
  LOAD_CATEGORIES_ERROR: 'LOAD_CATEGORIES_ERROR',

  LOAD_CATEGORY: 'LOAD_CATEGORY',
  LOAD_CATEGORY_SUCCESS: 'LOAD_CATEGORY_SUCCESS',
  LOAD_CATEGORY_ERROR: 'LOAD_CATEGORY_ERROR',

  SAVE_CATEGORY: 'SAVE_CATEGORY',
  SAVE_CATEGORY_SUCCESS: 'SAVE_CATEGORY_SUCCESS',
  SAVE_CATEGORY_ERROR: 'SAVE_CATEGORY_ERROR',

  REMOVE_CATEGORY: 'REMOVE_CATEGORY',
  REMOVE_CATEGORIES: 'REMOVE_CATEGORIES',

  loadCategories: page => ({
    type: actions.LOAD_CATEGORIES,
    payload: { page },
  }),
  loadCategoriesSuccess: data => ({
    type: actions.LOAD_CATEGORIES_SUCCESS,
    payload: { data },
  }),
  loadCategoriesError: error => ({
    type: actions.LOAD_CATEGORIES_ERROR,
    payload: { error },
  }),

  loadCategory: categoryId => ({
    type: actions.LOAD_CATEGORY,
    payload: categoryId,
  }),
  loadCategorySuccess: data => ({
    type: actions.LOAD_CATEGORY_SUCCESS,
    payload: { data },
  }),
  loadCategoryError: error => ({
    type: actions.LOAD_CATEGORY_ERROR,
    payload: { error },
  }),

  saveCategory: (data, actionName) => ({
    type: actions.SAVE_CATEGORY,
    payload: { data, actionName },
  }),
  saveCategorySuccess: () => ({
    type: actions.SAVE_CATEGORY_SUCCESS,
  }),
  saveCategoryError: error => ({
    type: actions.SAVE_CATEGORY_ERROR,
    payload: { error },
  }),

  removeCategory: () => ({
    type: actions.REMOVE_CATEGORY,
  }),
  removeCategories: () => ({
    type: actions.REMOVE_CATEGORIES,
  }),
};

export default actions;
