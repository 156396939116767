import SuperFetch from '../utils/superFetch';

const UmedRoom = {
  getOptionsRooms: (value, signal, withId = null) =>
    SuperFetch.get(`rooms?search=${value}&with=${withId}`, {}, false, null, signal).then(response => response.data),

  getSingleRoom: id => SuperFetch.get(`rooms?id=${id}`).then(response => response.data),
};

export default UmedRoom;
