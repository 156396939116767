import React from 'react';
import { LayoutContentStyle, LayoutContentWrapper } from './layoutContent.style';

const LayoutContent = props => (
  <LayoutContentStyle>
    <LayoutContentWrapper
      className={props.className != null ? `${props.className} isoLayoutContentWrapper` : 'isoLayoutContentWrapper'}
      {...props}
    >
      {props.children}
    </LayoutContentWrapper>
  </LayoutContentStyle>
);

export { LayoutContent };
