import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import debounce from 'lodash.debounce';

import appActions from '../../redux/app/actions';
import { getCollapsed, getView, getHeight, getOpenDrawer, getOpenKeys, getCurrent } from '../../redux/app/selectors';

import { adminNav, commonNav, moderatorNav } from './options';

import { Sider, Menu, CustomLogo, CustomScrollbar } from '../../components/uielements';

import { ROUTER_URLS, URLS_TO_ROUTER, URLS } from '../../utils/enumsUrl';
import { USER_ROLES } from '../../utils/enums';

import withRoleProvider from '../../hoc/withRoleProvider';

import SidebarWrapper from './sidebar.style';

const { toggleOpenDrawer, changeCurrent, toggleCollapsed } = appActions;

const Sidebar = ({ collapsed, view, height, openDrawer, current, changeCurrent, toggleOpenDrawer, pathname, role }) => {
  const collapsedMenu = collapsed && !openDrawer;
  const mode = collapsedMenu === true ? 'vertical' : 'inline';

  let menus = [...commonNav];
  if (role) {
    if (role.includes(USER_ROLES.ADMIN)) menus = [...adminNav, ...commonNav];
    else if (role.includes(USER_ROLES.MODERATOR)) menus = [...moderatorNav, ...commonNav];
    else if (role.includes(USER_ROLES.AD_USER || USER_ROLES.AD_EMPLOYEE || USER_ROLES.USER)) menus = [...commonNav];
  }

  useEffect(() => {
    if (!pathname) {
      return;
    }

    URLS_TO_ROUTER.find(element => element.key === pathname)
      ? changeCurrent([URLS_TO_ROUTER.find(element => element.key === pathname).value])
      : changeCurrent([ROUTER_URLS.MAP_WITH_FIRST_FLOOR]);
  }, [pathname]);

  const toggleAll = debounce(() => {
    toggleCollapsed();
    toggleOpenDrawer();
  }, 200);

  const handleClick = () => {
    if (view === 'MobileView') {
      toggleAll();
    }
  };

  const getMenuItem = ({ singleOption }) => {
    const { key, label, leftIcon } = singleOption;

    return (
      <Menu.Item key={key}>
        <Link to={`${URLS.CMS}/${key}`}>
          <span className='isoMenuHolder'>
            {leftIcon}
            <span className='nav-text'>{label}</span>
          </span>
        </Link>
      </Menu.Item>
    );
  };

  const onMouseEnter = () => {
    if (openDrawer === false) {
      toggleOpenDrawer();
    }
    return;
  };
  const onMouseLeave = () => {
    if (openDrawer === true) {
      toggleOpenDrawer();
    }
    return;
  };

  return (
    <SidebarWrapper>
      <Sider
        trigger={null}
        collapsible={true}
        collapsed={collapsedMenu}
        width={250}
        className='isomorphicSidebar'
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <CustomLogo collapsed={collapsedMenu} />
        <CustomScrollbar height={height}>
          <Menu onClick={handleClick} className='isoDashboardMenu' mode={mode} selectedKeys={current}>
            {menus.map(singleOption => getMenuItem({ singleOption }))}
          </Menu>
        </CustomScrollbar>
      </Sider>
    </SidebarWrapper>
  );
};

const mapStateToProps = state => {
  return {
    collapsed: getCollapsed(state),
    view: getView(state),
    height: getHeight(state),
    openDrawer: getOpenDrawer(state),
    openKeys: getOpenKeys(state),
    current: getCurrent(state),
  };
};

const mapDispatchToProps = { toggleOpenDrawer, changeCurrent, toggleCollapsed };

export default withRoleProvider(connect(mapStateToProps, mapDispatchToProps)(Sidebar));
