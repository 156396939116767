const commonOptions = {
  features: undefined,
};

const commonEvents = {
  change: undefined,
  'change:active': undefined,
  error: undefined,
  propertychange: undefined,
};

export { commonOptions, commonEvents };
