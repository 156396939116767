const actions = {
  LOAD_CATEGORIES_BASIC: 'LOAD_CATEGORIES_BASIC',
  LOAD_CATEGORIES_BASIC_SUCCESS: 'LOAD_CATEGORIES_BASIC_SUCCESS',
  LOAD_CATEGORIES_BASIC_ERROR: 'LOAD_CATEGORIES_BASIC_ERROR',

  LOAD_CATEGORY_BASIC: 'LOAD_CATEGORY_BASIC',
  LOAD_CATEGORY_BASIC_SUCCESS: 'LOAD_CATEGORY_BASIC_SUCCESS',
  LOAD_CATEGORY_BASIC_ERROR: 'LOAD_CATEGORY_BASIC_ERROR',

  REMOVE_CATEGORIES_BASIC: 'REMOVE_CATEGORIES_BASIC',
  REMOVE_CATEGORY_BASIC: 'REMOVE_CATEGORY_BASIC',

  loadCategoriesBasic: categoryId => ({
    type: actions.LOAD_CATEGORIES_BASIC,
    payload: { categoryId },
  }),
  loadCategoriesBasicSuccess: data => ({
    type: actions.LOAD_CATEGORIES_BASIC_SUCCESS,
    payload: { data },
  }),
  loadCategoriesBasicError: error => ({
    type: actions.LOAD_CATEGORIES_BASIC_ERROR,
    payload: { error },
  }),

  loadCategoryBasic: id => ({
    type: actions.LOAD_CATEGORY_BASIC,
    payload: { id },
  }),
  loadCategoryBasicSuccess: data => ({
    type: actions.LOAD_CATEGORY_BASIC_SUCCESS,
    payload: { data },
  }),
  loadCategoryBasicError: error => ({
    type: actions.LOAD_CATEGORY_BASIC_ERROR,
    payload: { error },
  }),

  removeCategoriesBasic: () => ({
    type: actions.REMOVE_CATEGORIES_BASIC,
  }),
  removeCategoryBasic: () => ({
    type: actions.REMOVE_CATEGORY_BASIC,
  }),
};

export default actions;
