import SuperFetch from '../utils/superFetch';
import TlnString from '../components/Tln/TlnString';

const Advertisements = {
  getAdvertisements: (page = 1) => SuperFetch.get(`commercial?page=${page}`).then(response => response.data),

  getAdvertisement: advertisementId => SuperFetch.get(`commercial/${advertisementId}`).then(response => response.data),

  saveAdvertisement: advertisement =>
    SuperFetch.post('commercial', advertisement, false, TlnString('CRUD_ADD_RECORD_SUCCESS')).then(response => response.status),

  updateAdvertisement: advertisement =>
    SuperFetch.put(`commercial/${advertisement.id}`, advertisement, false, TlnString('CRUD_EDIT_RECORD_SUCCESS')).then(
      response => response.status,
    ),

  deleteAdvertisement: advertisement =>
    SuperFetch.delete(`commercial/${advertisement.id}`, {}, false, TlnString('CRUD_REMOVE_RECORD_SUCCESS')).then(
      response => response.status,
    ),
};

export default Advertisements;
