const actions = {
  LOAD_ARTICLES: 'LOAD_ARTICLES',
  LOAD_ARTICLES_SUCCESS: 'LOAD_ARTICLES_SUCCESS',
  LOAD_ARTICLES_ERROR: 'LOAD_ARTICLES_ERROR',

  LOAD_ARTICLE: 'LOAD_ARTICLE',
  LOAD_ARTICLE_SUCCESS: 'LOAD_ARTICLE_SUCCESS',
  LOAD_ARTICLE_ERROR: 'LOAD_ARTICLE_ERROR',

  SAVE_ARTICLE: 'SAVE_ARTICLE',
  SAVE_ARTICLE_SUCCESS: 'SAVE_ARTICLE_SUCCESS',
  SAVE_ARTICLE_ERROR: 'SAVE_ARTICLE_ERROR',

  REMOVE_ARTICLE: 'REMOVE_ARTICLE',
  REMOVE_ARTICLES: 'REMOVE_ARTICLES',

  loadArticles: page => ({
    type: actions.LOAD_ARTICLES,
    payload: { page },
  }),
  loadArticlesSuccess: data => ({
    type: actions.LOAD_ARTICLES_SUCCESS,
    payload: { data },
  }),
  loadArticlesError: error => ({
    type: actions.LOAD_ARTICLES_ERROR,
    payload: { error },
  }),

  loadArticle: articleId => ({
    type: actions.LOAD_ARTICLE,
    payload: articleId,
  }),
  loadArticleSuccess: data => ({
    type: actions.LOAD_ARTICLE_SUCCESS,
    payload: { data },
  }),
  loadArticleError: error => ({
    type: actions.LOAD_ARTICLE_ERROR,
    payload: { error },
  }),

  saveArticle: (data, actionName) => ({
    type: actions.SAVE_ARTICLE,
    payload: { data, actionName },
  }),
  saveArticleSuccess: () => ({
    type: actions.SAVE_ARTICLE_SUCCESS,
  }),
  saveArticleError: error => ({
    type: actions.SAVE_ARTICLE_ERROR,
    payload: { error },
  }),

  removeArticle: () => ({
    type: actions.REMOVE_ARTICLE,
  }),
  removeArticles: () => ({
    type: actions.REMOVE_ARTICLES,
  }),
};

export default actions;
