const actions = {
  LOAD_SOS_CONTACTS: 'LOAD_SOS_CONTACTS',
  LOAD_SOS_CONTACTS_SUCCESS: 'LOAD_SOS_CONTACTS_SUCCESS',
  LOAD_SOS_CONTACTS_ERROR: 'LOAD_SOS_CONTACTS_ERROR',

  LOAD_SOS_CONTACT: 'LOAD_SOS_CONTACT',
  LOAD_SOS_CONTACT_SUCCESS: 'LOAD_SOS_CONTACT_SUCCESS',
  LOAD_SOS_CONTACT_ERROR: 'LOAD_SOS_CONTACT_ERROR',

  SAVE_SOS_CONTACT: 'SAVE_SOS_CONTACT',
  SAVE_SOS_CONTACT_SUCCESS: 'SAVE_SOS_CONTACT_SUCCESS',
  SAVE_SOS_CONTACT_ERROR: 'SAVE_SOS_CONTACT_ERROR',

  REMOVE_SOS_CONTACT: 'REMOVE_SOS_CONTACT',
  REMOVE_SOS_CONTACTS: 'REMOVE_SOS_CONTACTS',

  loadSosContacts: page => ({
    type: actions.LOAD_SOS_CONTACTS,
    payload: { page },
  }),
  loadSosContactsSuccess: data => ({
    type: actions.LOAD_SOS_CONTACTS_SUCCESS,
    payload: { data },
  }),
  loadSosContactsError: error => ({
    type: actions.LOAD_SOS_CONTACTS_ERROR,
    payload: { error },
  }),

  loadSosContact: sosContactId => ({
    type: actions.LOAD_SOS_CONTACT,
    payload: { sosContactId },
  }),
  loadSosContactSuccess: data => ({
    type: actions.LOAD_SOS_CONTACT_SUCCESS,
    payload: { data },
  }),
  loadSosContactError: error => ({
    type: actions.LOAD_SOS_CONTACT_ERROR,
    payload: { error },
  }),

  saveSosContact: (data, actionName) => ({
    type: actions.SAVE_SOS_CONTACT,
    payload: { data, actionName },
  }),
  saveSosContactSuccess: () => ({
    type: actions.SAVE_SOS_CONTACT_SUCCESS,
  }),
  saveSosContactError: error => ({
    type: actions.SAVE_SOS_CONTACT_ERROR,
    payload: { error },
  }),

  removeSosContact: () => ({
    type: actions.REMOVE_SOS_CONTACT,
  }),
  removeSosContacts: () => ({
    type: actions.REMOVE_SOS_CONTACTS,
  }),
};

export default actions;
