import styled from 'styled-components';

const CustomLoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  min-width: 100%;
  min-height: 100%;
  z-index: 9999;

  background-color: rgba(0, 0, 0, 0.6);

  display: flex;
  justify-content: center;
  align-items: center;
`;

const CustomLoaderWrapperWhite = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  min-width: 100%;
  min-height: 100%;
  z-index: 9999;

  background-color: rgba(255, 255, 255, ${props => props.opacity});

  display: flex;
  justify-content: center;
  align-items: center;
`;

const SimpleCustomLoaderCenterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export { CustomLoaderWrapper, CustomLoaderWrapperWhite, SimpleCustomLoaderCenterWrapper };
