import actions from './actions';

const initState = {
  draw: null,
  modify: false,
  select: true,
  snap: false,
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.CLICK_DRAW:
      return { ...state, draw: payload, modify: false, select: false };

    case actions.CLICK_MODIFY:
      return { ...state, draw: null, modify: true, select: false };

    case actions.CLICK_SELECT:
      return { ...state, draw: null, modify: false, select: true };

    case actions.CLICK_SNAP:
      return { ...state, snap: !state.snap };

    default:
      return state;
  }
}
