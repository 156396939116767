import React from 'react';

import { Table } from 'antd';
import { CustomTableWrapper } from './table.style';

import Tln from '../../Tln';

import { ReactComponent as PlaceholderIcon } from '../../../assets/tablePlaceholder.svg';

const CustomTable = ({ columns, loading, data }) => {
  const dataTable = [];
  Object.keys(data).map((item, index) => {
    return dataTable.push({
      ...data[item],
      keyId: item,
    });
  });

  return (
    <CustomTableWrapper
      rowKey='keyId'
      columns={columns()}
      loading={loading}
      dataSource={dataTable}
      locale={{ emptyText: <TablePlaceholder /> }}
      pagination={false}
    />
  );
};

const TablePlaceholder = () => {
  return (
    <div className='ant-empty ant-empty-normal'>
      <div className='ant-empty-image'>
        <PlaceholderIcon />
      </div>
      <p className='ant-empty-description'>
        <Tln id='TABLE_NO_DATA' />
      </p>
    </div>
  );
};

export { Table, CustomTable };
