import SuperFetch from '../utils/superFetch';
import TlnString from '../components/Tln/TlnString';

const NotificationsRoute = {
  getNotificationsRoute: (page = 1) => SuperFetch.get(`route_notification?page=${page}`).then(response => response.data),

  getNotificationRoute: notificationRouteId =>
    SuperFetch.get(`notification/${notificationRouteId}`).then(response => response.data),

  saveNotificationRoute: notificationRoute =>
    SuperFetch.post('notification', notificationRoute, false, TlnString('CRUD_ADD_RECORD_SUCCESS')).then(
      response => response.status,
    ),

  updateNotificationRoute: notificationRoute =>
    SuperFetch.put(`notification/${notificationRoute.id}`, notificationRoute, false, TlnString('CRUD_EDIT_RECORD_SUCCESS')).then(
      response => response.status,
    ),

  deleteNotificationRoute: notificationRoute =>
    SuperFetch.delete(`notification/${notificationRoute.id}`, {}, false, TlnString('CRUD_REMOVE_RECORD_SUCCESS')).then(
      response => response.status,
    ),
};

export default NotificationsRoute;
