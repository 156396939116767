import { GeoJsonUtils } from '../components/MainMap/utils';

const GeoJsonFeaturesChanger = {
  geoJsonToFeatureObjects: dataFromGeoJson => {
    if (!dataFromGeoJson) {
      return dataFromGeoJson;
    }

    const geoJson = GeoJsonUtils.createGeoJson();
    const features = geoJson.readFeatures(dataFromGeoJson);
    return features;
  },

  featureObjectToGeoJson: feature => {
    const newGeoJson = GeoJsonUtils.createGeoJson();
    const geoJson = newGeoJson.writeFeature(feature);
    return geoJson;
  },

  featureObjectsToGeoJson: features => {
    const newGeoJson = GeoJsonUtils.createGeoJson();
    const geoJson = newGeoJson.writeFeatures(features);
    return geoJson;
  },
};

export default GeoJsonFeaturesChanger;
