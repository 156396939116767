import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { Spin, Layout, Icon, Button } from '../../components/uielements';
import SimpleCustomLoader from '../../components/CustomLoader/SimpleCustomLoader';
// import LanguageSwitcher from '../../components/LanguageSwitcher';

import appActions from '../../redux/app/actions';

import TopbarUser from './TopbarUser';
import TopbarWrapper from './topbar.style';
import Tln from '../../components/Tln';

import withCheckCollectionBeforeLogout from '../../hoc/withCheckCollectionBeforeLogout';

const { Header } = Layout;
const { toggleCollapsed } = appActions;

class Topbar extends Component {
  render() {
    const { toggleCollapsed, isLoading } = this.props;
    const collapsed = this.props.collapsed && !this.props.openDrawer;

    return (
      <TopbarWrapper>
        <Header className={collapsed ? 'isomorphicTopbar collapsed' : 'isomorphicTopbar'}>
          <div className='isoLeft'>
            <Icon type='menu' onClick={toggleCollapsed} />
          </div>

          <ul className='isoRight'>
            {/* <li className='isoLogout'>
              <SimpleCustomLoader loading={isLoading}>
                <Spin />
              </SimpleCustomLoader>
            </li>
            <li>
              <LanguageSwitcher />
            </li> */}
            <li onClick={this.props.logoutWhenPossible} className='isoLogout'>
              <SimpleCustomLoader loading={isLoading}>
                <Spin />
              </SimpleCustomLoader>
              <Button type='link'>
                <Tln id='AUTH_LOGOUT' />
              </Button>
            </li>
            <li className='isoUser'>
              <TopbarUser />
            </li>
          </ul>
        </Header>
      </TopbarWrapper>
    );
  }
}

export default compose(
  withCheckCollectionBeforeLogout,
  connect(state => ({ ...state.App }), { toggleCollapsed }),
)(Topbar);
