import { notification } from 'antd';

import { style } from './notification.style';

const openNotification = (type, description, placement = 'topRight', container = 'body') => {
  notification[type]({
    message: description,
    placement: placement,
    getContainer: () => document.querySelector(container),
    style: style,
  });
};

export { notification, openNotification };
