import styled from 'styled-components';
import { palette } from 'styled-theme';

const CheckboxCustom = ComponentName => styled(ComponentName)`
  &.ant-checkbox-wrapper {
    color: ${palette('text', 5)};
    font-weight: 400;

    &:hover,
    &:focus,
    &:active {
      .ant-checkbox .ant-checkbox-inner {
        border-color: ${palette('primary', 8)};
      }
    }

    .ant-checkbox .ant-checkbox-inner {
      background-color: white;
      border-color: white;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: ${palette('text', 5)};
      border-color: ${palette('text', 5)};

      &::after {
        border-color: ${palette('primary', 0)};
      }
    }
  }
`;

export { CheckboxCustom };
