function getOptions(options = {}, props) {
  for (let key in props) {
    if (
      props[key] && //exclude undefined ones
      key !== 'children' &&
      !key.match(/^on[A-Z]/) //exclude events
    ) {
      options[key] = props[key];
    }
  }
  return options;
}

function getPropsKey(eventName) {
  return (
    'on' +
    eventName
      .replace(/(:[a-z])/g, $1 => $1.toUpperCase())
      .replace(/^[a-z]/, $1 => $1.toUpperCase())
      .replace(':', '')
  );
}

function getEvents(events = {}, props = {}) {
  let prop2EventMap = {};
  for (let key in events) {
    prop2EventMap[getPropsKey(key)] = key;
  }

  let ret = {};
  for (let propName in props) {
    let eventName = prop2EventMap[propName];
    let prop = props[propName];
    if (prop && propName.match(/^on[A-Z]/) && eventName) {
      ret[eventName] = prop;
    }
  }

  return ret;
}

export default class CompUtils {
  static getOptions = getOptions;
  static getEvents = getEvents;
}
