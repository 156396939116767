import { call, all, takeEvery, put, fork } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import actions from './actions';
import ParkingLots from '../../services/parkingLots';
import { API_STATUS } from '../../utils/enums';
import { URLS } from '../../utils/enumsUrl';

export function* loadParkingLots() {
  yield takeEvery(actions.LOAD_PARKING_LOTS, function* ({ payload }) {
    const { page } = payload;
    try {
      const result = yield call(ParkingLots.getParkings, page);
      yield put(actions.loadParkingLotsSuccess(result));
    } catch (error) {
      yield put(actions.loadParkingLotsError(error));
    }
  });
}

export function* loadParkingLot() {
  yield takeEvery(actions.LOAD_PARKING_LOT, function* ({ payload }) {
    const { parkingLotId } = payload;
    try {
      const result = yield call(ParkingLots.getParking, parkingLotId);
      yield put(actions.loadParkingLotSuccess(result));
    } catch (error) {
      yield put(actions.loadParkingLotError(error));
    }
  });
}

export function* editParkingLot() {
  yield takeEvery(actions.EDIT_PARKING_LOT, function* ({ payload }) {
    const { data } = payload;
    try {
      const result = yield call(ParkingLots.updateParking, data);

      if (result === API_STATUS.SUCCESS) {
        yield put(push(URLS.PARKING_LOTS));
        yield put(actions.editParkingLotSuccess(result));
      }
    } catch (error) {
      yield put(actions.editParkingLotError(error));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(loadParkingLot), fork(loadParkingLots), fork(editParkingLot)]);
}
