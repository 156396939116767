import { call, all, takeEvery, put, fork, select } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import actions from './actions';
import Beacons from '../../services/beacons';
import { SAVE_FUNCTION_CRUD } from '../../utils/enums';
import { API_STATUS } from '../../utils/enums';
import { URLS } from '../../utils/enumsUrl';
import { getSearch } from './selectors';

export function* loadBeacons() {
  yield takeEvery(actions.LOAD_BEACONS, function* ({ payload }) {
    const { page, signal } = payload;
    const search = yield select(getSearch);
    try {
      const result = yield call(Beacons.getBeacons, page, search, signal);
      yield put(actions.loadBeaconsSuccess(result));
    } catch (error) {
      yield put(actions.loadBeaconsError(error));
    }
  });
}

export function* loadBeacon() {
  yield takeEvery(actions.LOAD_BEACON, function* ({ payload }) {
    try {
      const result = yield call(Beacons.getBeacon, payload);
      yield put(actions.loadBeaconSuccess(result));
    } catch (error) {
      yield put(actions.loadBeaconError(error));
    }
  });
}

export function* storeBeacon() {
  yield takeEvery(actions.SAVE_BEACON, function* ({ payload }) {
    const { data, actionName } = payload;
    let result;
    try {
      switch (actionName) {
        case SAVE_FUNCTION_CRUD.UPDATE:
          result = yield call(Beacons.updateBeacon, data);
          break;
        default:
          break;
      }
      if (result === API_STATUS.SUCCESS) {
        yield put(push(URLS.BEACONS));
        yield put(actions.saveBeaconSuccess());
      }
    } catch (error) {
      yield put(actions.saveBeaconError(error));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(loadBeacons), fork(loadBeacon), fork(storeBeacon)]);
}
