import React from 'react';

import { CustomLoaderWrapperWhite } from './CustomLoader.style';

const CustomLoaderWhite = ({ children, loading, opacity = 0.6 }) => {
  if (!loading) return null;

  return <CustomLoaderWrapperWhite opacity={opacity}>{children}</CustomLoaderWrapperWhite>;
};

export default CustomLoaderWhite;
