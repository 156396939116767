import actions from './actions';

const initState = {
  isLoading: false,
  isSaving: false,
  events: [],
  event: {},
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_EVENTS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_EVENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        events: payload.data,
      };
    case actions.LOAD_EVENTS_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.LOAD_EVENT:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_EVENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        event: payload.data,
      };
    case actions.LOAD_EVENT_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.SAVE_EVENT:
      return {
        ...state,
        isSaving: true,
      };
    case actions.SAVE_EVENT_SUCCESS:
      return {
        ...state,
        isSaving: false,
      };
    case actions.SAVE_EVENT_ERROR:
      return {
        ...state,
        isSaving: false,
      };

    case actions.REMOVE_EVENT:
      return {
        ...state,
        event: {},
      };
    case actions.REMOVE_EVENTS:
      return {
        ...state,
        events: [],
      };

    default:
      return state;
  }
}
