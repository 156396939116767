import SuperFetch from '../utils/superFetch';
import TlnString from '../components/Tln/TlnString';

const Campuses = {
  getCampuses: (page = 1) => SuperFetch.get(`campus?page=${page}`).then(response => response.data),

  getCampus: campusId => SuperFetch.get(`campus/${campusId}`).then(response => response.data),

  getCampusesBasic: (language = 'pl') => SuperFetch.get(`campus_basic?lang=${language}`).then(response => response.data),

  saveCampus: campus =>
    SuperFetch.post('campus', campus, false, TlnString('CRUD_ADD_RECORD_SUCCESS')).then(response => response.status),

  updateCampus: campus =>
    SuperFetch.put(`campus/${campus.id}`, campus, false, TlnString('CRUD_EDIT_RECORD_SUCCESS')).then(response => response.status),

  deleteCampus: campus =>
    SuperFetch.delete(`campus/${campus.id}`, {}, false, TlnString('CRUD_REMOVE_RECORD_SUCCESS')).then(
      response => response.status,
    ),
};

export default Campuses;
