import actions from './actions';

const initState = {
  isLoading: false,
  isSaving: false,
  parkingLots: [],
  parkingLot: {},
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_PARKING_LOTS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_PARKING_LOTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        parkingLots: payload.data,
      };
    case actions.LOAD_PARKING_LOTS_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.LOAD_PARKING_LOT:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_PARKING_LOT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        parkingLot: payload.data,
      };
    case actions.LOAD_PARKING_LOT_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.EDIT_PARKING_LOT:
      return {
        ...state,
        isSaving: true,
      };
    case actions.EDIT_PARKING_LOT_SUCCESS:
      return {
        ...state,
        isSaving: false,
      };
    case actions.EDIT_PARKING_LOT_ERROR:
      return {
        ...state,
        isSaving: false,
      };

    case actions.REMOVE_PARKING_LOT:
      return {
        ...state,
        parkingLot: {},
      };
    case actions.REMOVE_PARKING_LOTS:
      return {
        ...state,
        parkingLots: [],
      };

    default:
      return state;
  }
}
