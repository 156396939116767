const actions = {
  SET_LANGUAGE: 'SET_LANGUAGE',

  setLanguage: language => ({
    type: actions.SET_LANGUAGE,
    payload: { language },
  }),
};

export default actions;
