import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import categoriesSaga from './categories/saga';
import articlesSaga from './articles/saga';
import eventsSaga from './events/saga';
import sosesSaga from './sos/saga';
import featuresSaga from './features/saga';
import usersSaga from './users/saga';
import typesSaga from './types/saga';
import categoriesCrudsSaga from './categoriesCruds/saga';
import geoBasicSaga from './geoBasic/saga';
import uploadSaga from './upload/saga';
import buttonsSaga from './buttons/saga';
import advertisementsSaga from './advertisements/saga';
import notificationsSaga from './notifications/saga';
import notificationsPushSaga from './notificationsPush/saga';
import campusesSaga from './campuses/saga';
import sosContactsSaga from './sosContacts/saga';
import parkingLotsSaga from './parkingLots/saga';
import categoriesBasicSaga from './categoriesBasic/saga';
import notificationsRouteSaga from './notificationsRoute/saga';
import umedRoomsSaga from './umedRooms/saga';
import connectorValuesSaga from './connectorValues/saga';
import beaconsSaga from './beacons/saga';
import opinionsSaga from './opinions/saga';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    categoriesSaga(),
    articlesSaga(),
    eventsSaga(),
    sosesSaga(),
    featuresSaga(),
    usersSaga(),
    typesSaga(),
    categoriesCrudsSaga(),
    geoBasicSaga(),
    uploadSaga(),
    buttonsSaga(),
    advertisementsSaga(),
    notificationsSaga(),
    notificationsPushSaga(),
    campusesSaga(),
    sosContactsSaga(),
    parkingLotsSaga(),
    categoriesBasicSaga(),
    notificationsRouteSaga(),
    umedRoomsSaga(),
    connectorValuesSaga(),
    beaconsSaga(),
    opinionsSaga(),
  ]);
}
