const actions = {
  LOAD_SELECT_OPTIONS_CONNECTOR: 'LOAD_SELECT_OPTIONS_CONNECTOR',
  LOAD_SELECT_OPTIONS_CONNECTOR_SUCCESS: 'LOAD_SELECT_OPTIONS_CONNECTOR_SUCCESS',
  LOAD_SELECT_OPTIONS_CONNECTOR_ERROR: 'LOAD_SELECT_OPTIONS_CONNECTOR_ERROR',

  loadSelectOptions: dataType => ({
    type: actions.LOAD_SELECT_OPTIONS_CONNECTOR,
    payload: { dataType },
  }),
  loadSelectOptionsSuccess: (data, dataType) => ({
    type: actions.LOAD_SELECT_OPTIONS_CONNECTOR_SUCCESS,
    payload: { data, dataType },
  }),
  loadSelectOptionsError: error => ({
    type: actions.LOAD_SELECT_OPTIONS_CONNECTOR_ERROR,
    payload: { error },
  }),
};

export default actions;
