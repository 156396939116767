const actions = {
  LOAD_CAMPUSES: 'LOAD_CAMPUSES',
  LOAD_CAMPUSES_SUCCESS: 'LOAD_CAMPUSES_SUCCESS',
  LOAD_CAMPUSES_ERROR: 'LOAD_CAMPUSES_ERROR',

  LOAD_CAMPUSES_BASIC: 'LOAD_CAMPUSES_BASIC',
  LOAD_CAMPUSES_BASIC_SUCCESS: 'LOAD_CAMPUSES_BASIC_SUCCESS',
  LOAD_CAMPUSES_BASIC_ERROR: 'LOAD_CAMPUSES_BASIC_ERROR',

  LOAD_CAMPUS: 'LOAD_CAMPUS',
  LOAD_CAMPUS_SUCCESS: 'LOAD_CAMPUS_SUCCESS',
  LOAD_CAMPUS_ERROR: 'LOAD_CAMPUS_ERROR',

  SAVE_CAMPUS: 'SAVE_CAMPUS',
  SAVE_CAMPUS_SUCCESS: 'SAVE_CAMPUS_SUCCESS',
  SAVE_CAMPUS_ERROR: 'SAVE_CAMPUS_ERROR',

  REMOVE_CAMPUS: 'REMOVE_CAMPUS',
  REMOVE_CAMPUSES: 'REMOVE_CAMPUSES',
  REMOVE_CAMPUSES_BASIC: 'REMOVE_CAMPUSES_BASIC',

  CLOSE_COMPONENT_CAMPUSES_BASIC: 'CLOSE_COMPONENT_CAMPUSES_BASIC',

  loadCampuses: page => ({
    type: actions.LOAD_CAMPUSES,
    payload: { page },
  }),
  loadCampusesSuccess: data => ({
    type: actions.LOAD_CAMPUSES_SUCCESS,
    payload: { data },
  }),
  loadCampusesError: error => ({
    type: actions.LOAD_CAMPUSES_ERROR,
    payload: { error },
  }),

  loadCampusesBasic: language => ({
    type: actions.LOAD_CAMPUSES_BASIC,
    payload: { language },
  }),
  loadCampusesBasicSuccess: data => ({
    type: actions.LOAD_CAMPUSES_BASIC_SUCCESS,
    payload: { data },
  }),
  loadCampusesBasicError: error => ({
    type: actions.LOAD_CAMPUSES_BASIC_ERROR,
    payload: { error },
  }),

  loadCampus: campusId => ({
    type: actions.LOAD_CAMPUS,
    payload: campusId,
  }),
  loadCampusSuccess: data => ({
    type: actions.LOAD_CAMPUS_SUCCESS,
    payload: { data },
  }),
  loadCampusError: error => ({
    type: actions.LOAD_CAMPUS_ERROR,
    payload: { error },
  }),

  saveCampus: (data, actionName) => ({
    type: actions.SAVE_CAMPUS,
    payload: { data, actionName },
  }),
  saveCampusSuccess: error => ({
    type: actions.SAVE_CAMPUS_SUCCESS,
  }),
  saveCampusError: error => ({
    type: actions.SAVE_CAMPUS_ERROR,
    payload: { error },
  }),

  removeCampus: () => ({
    type: actions.REMOVE_CAMPUS,
  }),
  removeCampuses: () => ({
    type: actions.REMOVE_CAMPUSES,
  }),
  removeCampusesBasic: () => ({
    type: actions.REMOVE_CAMPUSES_BASIC,
  }),

  closeComponentCampusesBasic: () => ({
    type: actions.CLOSE_COMPONENT_CAMPUSES_BASIC,
  }),
};

export default actions;
