import { call, all, takeEvery, put, fork } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import actions from './actions';
import Users from '../../services/users';
import { SAVE_FUNCTION_CRUD } from '../../utils/enums';
import { API_STATUS } from '../../utils/enums';
import { URLS } from '../../utils/enumsUrl';

import pagesActions from '../pages/actions';

export function* loadUsers() {
  yield takeEvery(actions.LOAD_USERS, function* ({ payload }) {
    const { page } = payload;
    try {
      const result = yield call(Users.getUsers, page);
      yield put(actions.loadUsersSuccess(result));
    } catch (error) {
      yield put(actions.loadUsersError(error));
    }
  });
}

export function* loadUser() {
  yield takeEvery(actions.LOAD_USER, function* ({ payload }) {
    try {
      const result = yield call(Users.getUser, payload);
      yield put(actions.loadUserSuccess(result));
    } catch (error) {
      yield put(actions.loadUserError(error));
    }
  });
}

export function* changePasswordUser() {
  yield takeEvery(actions.CHANGE_PASSWORD_USER, function* ({ payload }) {
    const { userId, password } = payload;
    try {
      const result = yield call(Users.changePasswordUser, userId, password);

      if (result === API_STATUS.SUCCESS) {
        yield put(push(URLS.USERS));
        yield put(actions.changePasswordUserSuccess());
      }
    } catch (error) {
      yield put(actions.changePasswordUserError(error));
    }
  });
}

export function* storeUser() {
  yield takeEvery(actions.SAVE_USER, function* ({ payload }) {
    const { data, actionName } = payload;
    let result;
    try {
      switch (actionName) {
        case SAVE_FUNCTION_CRUD.DELETE:
          result = yield call(Users.deleteUser, data);
          break;
        case SAVE_FUNCTION_CRUD.UPDATE:
          result = yield call(Users.updateUser, data);
          break;
        default:
          result = yield call(Users.saveUser, data);
          break;
      }
      if (result === API_STATUS.SUCCESS) {
        yield put(push(URLS.USERS));
        yield put(actions.saveUserSuccess());

        if (actionName === SAVE_FUNCTION_CRUD.DELETE) {
          yield put(actions.loadUsers());
          yield put(pagesActions.resetPage());
        }
      }
    } catch (error) {
      yield put(actions.saveUserError(error));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(loadUsers), fork(loadUser), fork(changePasswordUser), fork(storeUser)]);
}
