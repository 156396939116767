const actions = {
  LOAD_NOTIFICATIONS_PUSH: 'LOAD_NOTIFICATIONS_PUSH',
  LOAD_NOTIFICATIONS_PUSH_SUCCESS: 'LOAD_NOTIFICATIONS_PUSH_SUCCESS',
  LOAD_NOTIFICATIONS_PUSH_ERROR: 'LOAD_NOTIFICATIONS_PUSH_ERROR',

  LOAD_NOTIFICATION_PUSH: 'LOAD_NOTIFICATION_PUSH',
  LOAD_NOTIFICATION_PUSH_SUCCESS: 'LOAD_NOTIFICATION_PUSH_SUCCESS',
  LOAD_NOTIFICATION_PUSH_ERROR: 'LOAD_NOTIFICATION_PUSH_ERROR',

  SEND_NOTIFICATION_PUSH: 'SEND_NOTIFICATION_PUSH',
  SEND_NOTIFICATION_PUSH_SUCCESS: 'SEND_NOTIFICATION_PUSH_SUCCESS',
  SEND_NOTIFICATION_PUSH_ERROR: 'SEND_NOTIFICATION_PUSH_ERROR',

  TOGGLE_NOTIFICATION_PUSH_PREVIEW_MODAL: 'TOGGLE_NOTIFICATION_PUSH_PREVIEW_MODAL',
  CLOSE_MODAL_NOTIFICATION_PUSH: 'CLOSE_MODAL_NOTIFICATION_PUSH',

  REMOVE_NOTIFICATION_PUSH: 'REMOVE_NOTIFICATION_PUSH',
  REMOVE_NOTIFICATIONS_PUSH: 'REMOVE_NOTIFICATIONS_PUSH',

  loadNotificationsPush: page => ({
    type: actions.LOAD_NOTIFICATIONS_PUSH,
    payload: { page },
  }),
  loadNotificationsPushSuccess: data => ({
    type: actions.LOAD_NOTIFICATIONS_PUSH_SUCCESS,
    payload: { data },
  }),
  loadNotificationsPushError: error => ({
    type: actions.LOAD_NOTIFICATIONS_PUSH_ERROR,
    payload: { error },
  }),

  loadNotificationPush: notificationPushId => ({
    type: actions.LOAD_NOTIFICATION_PUSH,
    payload: { notificationPushId },
  }),
  loadNotificationPushSuccess: data => ({
    type: actions.LOAD_NOTIFICATION_PUSH_SUCCESS,
    payload: { data },
  }),
  loadNotificationPushError: error => ({
    type: actions.LOAD_NOTIFICATION_PUSH_ERROR,
    payload: { error },
  }),

  sendNotificationPush: data => ({
    type: actions.SEND_NOTIFICATION_PUSH,
    payload: { data },
  }),
  sendNotificationPushSuccess: () => ({
    type: actions.SEND_NOTIFICATION_PUSH_SUCCESS,
  }),
  sendNotificationPushError: error => ({
    type: actions.SEND_NOTIFICATION_PUSH_ERROR,
    payload: { error },
  }),

  toggleNotificationPushPreviewModal: () => ({
    type: actions.TOGGLE_NOTIFICATION_PUSH_PREVIEW_MODAL,
  }),
  closeModalNotificationPush: () => ({
    type: actions.CLOSE_MODAL_NOTIFICATION_PUSH,
  }),

  removeNotificationPush: () => ({
    type: actions.REMOVE_NOTIFICATION_PUSH,
  }),
  removeNotificationsPush: () => ({
    type: actions.REMOVE_NOTIFICATIONS_PUSH,
  }),
};

export default actions;
