import { createSelector } from 'reselect';

import { OBJECT_TYPES_ARRAY } from '../../utils/selectEnums';
import GeoJsonFeaturesChanger from '../../utils/GeoJsonFeaturesChanger';

const getSavingStatus = state => state.Features.isLoading;

const getFeaturesGeoJson = state => state.Features.filteredFeatures;

const getFeaturesObjects = createSelector([getFeaturesGeoJson], features =>
  GeoJsonFeaturesChanger.geoJsonToFeatureObjects(features),
);

const getExtent = state => state.Features.extent;

const getNewFeatures = state => state.Features.newFeatures;

const getNewFeaturesGeoJson = state => GeoJsonFeaturesChanger.featureObjectsToGeoJson(state.Features.newFeatures);

const getEditFeatures = state => state.Features.editFeatures;

const getEditFeaturesGeoJson = state => GeoJsonFeaturesChanger.featureObjectsToGeoJson(state.Features.editFeatures);

const getDeleteFeatures = state => state.Features.deleteFeatures;

const getDeleteFeaturesGeoJson = state => GeoJsonFeaturesChanger.featureObjectsToGeoJson(state.Features.deleteFeatures);

const getFiltersToFeatures = state => state.Features.filtersToFeatures;

const getIsCheckedAll = state => OBJECT_TYPES_ARRAY.every(type => state.Features.filtersToFeatures.includes(type));

export {
  getSavingStatus,
  getFeaturesObjects,
  getNewFeatures,
  getNewFeaturesGeoJson,
  getEditFeatures,
  getEditFeaturesGeoJson,
  getDeleteFeatures,
  getDeleteFeaturesGeoJson,
  getExtent,
  getFiltersToFeatures,
  getIsCheckedAll,
};
