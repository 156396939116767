import actions from './actions';

const initState = {
  isLoading: false,
  isLoadingSingle: false,
  isSaving: false,
  soses: [],
  sos: {},
  modalActive: false,
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_SOSES:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_SOSES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        soses: payload.data,
      };
    case actions.LOAD_SOSES_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.LOAD_SOS:
      return {
        ...state,
        isLoadingSingle: true,
      };
    case actions.LOAD_SOS_SUCCESS:
      return {
        ...state,
        isLoadingSingle: false,
        sos: payload.data,
      };
    case actions.LOAD_SOS_ERROR:
      return {
        ...state,
        isLoadingSingle: false,
      };

    case actions.SEND_PUSH_SOS:
      return {
        ...state,
        isSaving: true,
      };
    case actions.SEND_PUSH_SOS_SUCCESS:
      return {
        ...state,
        isSaving: false,
      };
    case actions.SEND_PUSH_SOS_ERROR:
      return {
        ...state,
        isSaving: false,
      };

    case actions.REMOVE_SOS:
      return {
        ...state,
        sos: {},
      };
    case actions.REMOVE_SOSES:
      return {
        ...state,
        soses: [],
      };

    case actions.TOGGLE_SOS_REPLY_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
      };

    case actions.CLOSE_MODAL_SOS:
      return {
        ...state,
        modalActive: false,
      };

    default:
      return state;
  }
}
