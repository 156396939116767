import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import { createFilter } from 'redux-persist-transform-filter';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';

import reducers from '../redux/reducers';
import rootSaga from '../redux/sagas';

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, sagaMiddleware, routeMiddleware];

const saveSubsetLanguagesFilter = createFilter('Languages', ['activeLanguage']);

const saveSubsetFeatureFilter = createFilter('Features', ['filtersToFeatures']);

const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['Languages', 'Features'],
  transforms: [saveSubsetLanguagesFilter, saveSubsetFeatureFilter],
};

const store = createStore(
  persistReducer(persistConfig, combineReducers({ ...reducers, router: routerReducer })),
  compose(applyMiddleware(...middlewares)),
);
sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);

export { store, history, persistor };
