const actions = {
  LOAD_SOSES: 'LOAD_SOSES',
  LOAD_SOSES_SUCCESS: 'LOAD_SOSES_SUCCESS',
  LOAD_SOSES_ERROR: 'LOAD_SOSES_ERROR',

  LOAD_SOS: 'LOAD_SOS',
  LOAD_SOS_SUCCESS: 'LOAD_SOS_SUCCESS',
  LOAD_SOS_ERROR: 'LOAD_SOS_ERROR',

  SEND_PUSH_SOS: 'SEND_PUSH_SOS',
  SEND_PUSH_SOS_SUCCESS: 'SEND_PUSH_SOS_SUCCESS',
  SEND_PUSH_SOS_ERROR: 'SEND_PUSH_SOS_ERROR',

  DELETE_SOS: 'DELETE_SOS',
  DELETE_SOS_ERROR: 'DELETE_SOS_ERROR',

  REMOVE_SOS: 'REMOVE_SOS',
  REMOVE_SOSES: 'REMOVE_SOSES',

  TOGGLE_SOS_REPLY_MODAL: 'TOGGLE_SOS_REPLY_MODAL',
  CLOSE_MODAL_SOS: 'CLOSE_MODAL_SOS',

  loadSoses: page => ({
    type: actions.LOAD_SOSES,
    payload: { page },
  }),
  loadSosesSuccess: data => ({
    type: actions.LOAD_SOSES_SUCCESS,
    payload: { data },
  }),
  loadSosesError: error => ({
    type: actions.LOAD_SOSES_ERROR,
    payload: { error },
  }),

  loadSos: sosId => ({
    type: actions.LOAD_SOS,
    payload: sosId,
  }),
  loadSosSuccess: data => ({
    type: actions.LOAD_SOS_SUCCESS,
    payload: { data },
  }),
  loadSosError: error => ({
    type: actions.LOAD_SOS_ERROR,
    payload: { error },
  }),

  sendPushSos: data => ({
    type: actions.SEND_PUSH_SOS,
    payload: { data },
  }),
  sendPushSosSuccess: () => ({
    type: actions.SEND_PUSH_SOS_SUCCESS,
  }),
  sendPushSosError: error => ({
    type: actions.SEND_PUSH_SOS_ERROR,
    payload: { error },
  }),

  deleteSos: data => ({
    type: actions.DELETE_SOS,
    payload: { data },
  }),
  deleteSosError: error => ({
    type: actions.DELETE_SOS_ERROR,
    payload: { error },
  }),

  removeSos: () => ({
    type: actions.REMOVE_SOS,
  }),
  removeSoses: () => ({
    type: actions.REMOVE_SOSES,
  }),

  toggleSosReplyModal: () => ({
    type: actions.TOGGLE_SOS_REPLY_MODAL,
  }),
  closeModalSos: () => ({
    type: actions.CLOSE_MODAL_SOS,
  }),
};

export default actions;
