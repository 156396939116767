import actions from './actions';

const initState = {
  isLoading: false,
  isSaving: false,
  articles: [],
  article: {},
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_ARTICLES:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_ARTICLES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        articles: payload.data,
      };
    case actions.LOAD_ARTICLES_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.LOAD_ARTICLE:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_ARTICLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        article: payload.data,
      };
    case actions.LOAD_ARTICLE_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.SAVE_ARTICLE:
      return {
        ...state,
        isSaving: true,
      };
    case actions.SAVE_ARTICLE_SUCCESS:
      return {
        ...state,
        isSaving: false,
      };
    case actions.SAVE_ARTICLE_ERROR:
      return {
        ...state,
        isSaving: false,
      };

    case actions.REMOVE_ARTICLE:
      return {
        ...state,
        article: {},
      };
    case actions.REMOVE_ARTICLES:
      return {
        ...state,
        articles: [],
      };

    default:
      return state;
  }
}
