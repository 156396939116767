import React, { Component } from 'react';
import { connect } from 'react-redux';

import NotEmptyCollectionsModal from '../components/MainMap/Controls/NotEmptyCollectionsModal';

import authAction from '../redux/auth/actions';
import featuresActions from '../redux/features/actions';
import { getLoadingStatus } from '../redux/auth/selectors';
import { getNewFeatures, getEditFeatures, getDeleteFeatures } from '../redux/features/selectors';

const { logout } = authAction;
const { clearCollections } = featuresActions;

const withCheckCollectionBeforeLogout = WrappedComponent => {
  class CheckCollectionBeforeLogout extends Component {
    constructor(props) {
      super(props);
      this.state = {
        modalVisible: false,
      };
    }

    setVisibleModal = () => {
      this.setState(prevProps => {
        return {
          ...prevProps,
          modalVisible: !prevProps.modalVisible,
        };
      });
    };

    isFeaturesCollectionsEmpty = () => {
      return (
        this.props.newFeatures.length === 0 && this.props.editedFeatures.length === 0 && this.props.deletedFeatures.length === 0
      );
    };

    logoutWhenPossible = () => {
      if (this.isFeaturesCollectionsEmpty()) {
        this.props.logout();
      } else {
        this.setVisibleModal();
      }
    };

    logoutOnConfirm = () => {
      this.props.clearCollections();
      this.props.logout();
    };

    render() {
      return (
        <React.Fragment>
          <WrappedComponent {...this.props} logoutWhenPossible={this.logoutWhenPossible} />
          <NotEmptyCollectionsModal
            setVisible={this.setVisibleModal}
            clearCollections={this.logoutOnConfirm}
            visible={this.state.modalVisible}
          />
        </React.Fragment>
      );
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(CheckCollectionBeforeLogout);
};

const mapStateToProps = state => {
  return {
    isLoading: getLoadingStatus(state),
    newFeatures: getNewFeatures(state),
    editedFeatures: getEditFeatures(state),
    deletedFeatures: getDeleteFeatures(state),
  };
};

const mapDispatchToProps = { logout, clearCollections };

export default withCheckCollectionBeforeLogout;
