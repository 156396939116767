import actions from './actions';

const initState = {
  isLoading: false,
  isLoadingSingle: false,
  isSaving: false,
  opinions: [],
  opinion: {},
  modalActive: false,
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_OPINIONS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_OPINIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        opinions: payload.data,
      };
    case actions.LOAD_OPINIONS_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.LOAD_OPINION:
      return {
        ...state,
        isLoadingSingle: true,
      };
    case actions.LOAD_OPINION_SUCCESS:
      return {
        ...state,
        isLoadingSingle: false,
        opinion: payload.data,
      };
    case actions.LOAD_OPINION_ERROR:
      return {
        ...state,
        isLoadingSingle: false,
      };

    case actions.SEND_PUSH_OPINION_REPLY:
      return {
        ...state,
        isSaving: true,
      };
    case actions.SEND_PUSH_OPINION_REPLY_SUCCESS:
      return {
        ...state,
        isSaving: false,
      };
    case actions.SEND_PUSH_OPINION_REPLY_ERROR:
      return {
        ...state,
        isSaving: false,
      };

    case actions.REMOVE_OPINION:
      return {
        ...state,
        opinion: {},
      };
    case actions.REMOVE_OPINIONS:
      return {
        ...state,
        opinions: [],
      };

    case actions.TOGGLE_OPINION_DETAILS_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
      };

    case actions.CLOSE_OPINION_DETAILS_MODAL:
      return {
        ...state,
        modalActive: false,
      };

    default:
      return state;
  }
}
