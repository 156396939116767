import SuperFetch from '../utils/superFetch';
import TlnString from '../components/Tln/TlnString';

const ParkingLots = {
  getParkings: (page = 1) => SuperFetch.get(`parking?page=${page}`).then(response => response.data),

  getParking: parkingLotId => SuperFetch.get(`parking/${parkingLotId}`).then(response => response.data),

  updateParking: parkingLot =>
    SuperFetch.put(`parking/${parkingLot.id}`, parkingLot, false, TlnString('CRUD_EDIT_RECORD_SUCCESS')).then(
      response => response.status,
    ),
};

export default ParkingLots;
