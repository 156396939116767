const getLoadingStatus = state => state.Beacons.isLoading;

const getSavingStatus = state => state.Beacons.isSaving;

const getBeacons = state => state.Beacons.beacons;

const getBeacon = state => state.Beacons.beacon;

const getSearch = state => state.Beacons.search;

const getInit = state => state.Beacons.init;

export { getLoadingStatus, getSavingStatus, getBeacons, getBeacon, getSearch, getInit };
