import actions from './actions';

const initState = {
  isLoading: false,
  isSaving: false,
  notifications: [],
  notification: {},
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_NOTIFICATIONS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        notifications: payload.data,
      };
    case actions.LOAD_NOTIFICATIONS_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.LOAD_NOTIFICATION:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        notification: payload.data,
      };
    case actions.LOAD_NOTIFICATION_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.SAVE_NOTIFICATION:
      return {
        ...state,
        isSaving: true,
      };
    case actions.SAVE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isSaving: false,
      };
    case actions.SAVE_NOTIFICATION_ERROR:
      return {
        ...state,
        isSaving: false,
      };

    case actions.REMOVE_NOTIFICATION:
      return {
        ...state,
        notification: {},
      };
    case actions.REMOVE_NOTIFICATIONS:
      return {
        ...state,
        notifications: [],
      };

    default:
      return state;
  }
}
