import actions from './actions';

const initState = {
  isLoading: false,
  categoriesBasic: [],
  categoryBasic: null,
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_CATEGORIES_BASIC:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_CATEGORIES_BASIC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categoriesBasic: payload.data,
      };
    case actions.LOAD_CATEGORIES_BASIC_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.LOAD_CATEGORY_BASIC:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_CATEGORY_BASIC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categoryBasic: payload.data,
      };
    case actions.LOAD_CATEGORY_BASIC_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.REMOVE_CATEGORIES_BASIC:
      return {
        ...state,
        categoriesBasic: [],
      };
    case actions.REMOVE_CATEGORY_BASIC:
      return {
        ...state,
        categoryBasic: null,
      };

    default:
      return state;
  }
}
