import actions from './actions';

const initState = {
  currentPage: 1,
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.SET_PAGE:
      return {
        ...state,
        currentPage: payload.page,
      };

    case actions.RESET_PAGE:
      return {
        ...state,
        currentPage: initState.currentPage,
      };

    default:
      return state;
  }
}
