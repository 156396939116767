import styled from 'styled-components';
import { palette } from 'styled-theme';

import { Table } from './index';

import { transition } from '../../../config/settings/style-util';
import WithDirection from '../../../config/settings/withDirection';

const TableWrapper = styled(Table)`
  overflow: hidden;
  overflow-x: auto;
  background-color: ${palette('text', 5)};

  .ant-table-thead > tr > th {
    color: ${palette('text', 5)};
    font-size: 13px;
    background-color: ${palette('primary', 0)};
    border-bottom: 0;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 16px 15px;
    white-space: nowrap;
    text-align: ${props => (props['data-rtl'] === 'rtl' ? 'right' : 'left')};

    p {
      margin-bottom: 0;
    }
  }

  .ant-table-tbody > tr > td {
    font-size: 12px;
    color: ${palette('text', 1)};
    border-bottom: 1px solid ${palette('border', 0)};

    a {
      color: ${palette('primary', 0)};
      ${transition()};

      &:hover {
        color: ${palette('primary', 1)};
      }
    }
  }

  .ant-table-pagination {
    float: ${props => (props['data-rtl'] === 'rtl' ? 'left' : 'right')};
  }

  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    transform: ${props => (props['data-rtl'] === 'rtl' ? 'rotate(180deg)' : 'rotate(0)')};
  }

  .ant-pagination-prev,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    margin: ${props => (props['data-rtl'] === 'rtl' ? '0 0 0 8px' : '0 8px 0 0')};
  }

  .ant-pagination-item {
    margin: ${props => (props['data-rtl'] === 'rtl' ? '0 0 0 8px' : '0 8px 0 0')};

    &:hover {
      border-color: ${palette('primary', 0)};
      ${transition()};
    }

    &:hover a {
      color: ${palette('primary', 0)};
    }
  }

  .ant-pagination-item-active {
    background-color: ${palette('primary', 0)};
    border-color: ${palette('primary', 0)};

    a {
      color: ${palette('text', 5)};
    }

    &:hover a {
      color: ${palette('text', 5)};
    }
  }

  .ant-spin-nested-loading > div > .ant-spin {
    max-height: none;

    .ant-spin-dot i {
      color: ${palette('primary', 0)};
    }
  }
`;

const CustomTableWrapper = WithDirection(TableWrapper);

export { CustomTableWrapper };
