import { call, all, takeEvery, put, fork, select } from 'redux-saga/effects';
import actions from './actions';
import { push } from 'react-router-redux';
import CategoriesCruds from '../../services/categoriesCruds';
import { SAVE_FUNCTION_CRUD } from '../../utils/enums';
import { API_STATUS } from '../../utils/enums';
import { URLS } from '../../utils/enumsUrl';

import { getModalActive } from './selectors';

import pagesActions from '../pages/actions';

export function* loadCategoriesCruds() {
  yield takeEvery(actions.LOAD_CATEGORIES_CRUDS, function* ({ payload }) {
    const { page } = payload;
    try {
      const result = yield call(CategoriesCruds.getCategoriesCruds, page);
      yield put(actions.loadCategoriesCrudsSuccess(result));
    } catch (error) {
      yield put(actions.loadCategoriesCrudsError(error));
    }
  });
}

export function* loadCategoriesCrudsBasic() {
  yield takeEvery(actions.LOAD_CATEGORIES_CRUDS_BASIC, function* ({ payload }) {
    const { language } = payload;
    try {
      const result = yield call(CategoriesCruds.getCategoriesCrudsBasic, language);
      yield put(actions.loadCategoriesCrudsSuccessBasic(result));
    } catch (error) {
      yield put(actions.loadCategoriesCrudsErrorBasic(error));
    }
  });
}

export function* loadCategoryCruds() {
  yield takeEvery(actions.LOAD_CATEGORY_CRUDS, function* ({ payload }) {
    try {
      const result = yield call(CategoriesCruds.getCategoryCruds, payload);
      yield put(actions.loadCategoryCrudsSuccess(result));
    } catch (error) {
      yield put(actions.loadCategoryCrudsError(error));
    }
  });
}

export function* storeCategoryCruds() {
  yield takeEvery(actions.SAVE_CATEGORIES_CRUDS, function* ({ payload }) {
    const { data, actionName, languageBasic } = payload;
    let result;
    try {
      switch (actionName) {
        case SAVE_FUNCTION_CRUD.DELETE:
          result = yield call(CategoriesCruds.deleteCategoryCruds, data);
          break;
        case SAVE_FUNCTION_CRUD.UPDATE:
          result = yield call(CategoriesCruds.updateCategoryCruds, data);
          break;
        default:
          result = yield call(CategoriesCruds.saveCategoryCruds, data);
          break;
      }
      if (result === API_STATUS.SUCCESS) {
        const isModalOpen = yield select(getModalActive);

        if (isModalOpen) {
          yield put(actions.toggleCategoryCrudsModal());
          yield put(actions.loadCategoriesCrudsBasic(languageBasic));
        } else {
          yield put(push(URLS.CATEGORIES_CRUDS));
        }

        if (actionName === SAVE_FUNCTION_CRUD.DELETE) {
          yield put(actions.loadCategoriesCruds());
          yield put(pagesActions.resetPage());
        }

        yield put(actions.saveCategoriesCrudsSuccess());
      }
    } catch (error) {
      yield put(actions.saveCategoriesCrudsError(error));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(loadCategoriesCruds), fork(loadCategoriesCrudsBasic), fork(loadCategoryCruds), fork(storeCategoryCruds)]);
}
