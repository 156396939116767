import actions from './actions';

const initState = {
  isLoading: false,
  isSaving: false,
  advertisements: [],
  advertisement: {},
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_ADVERTISEMENTS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_ADVERTISEMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        advertisements: payload.data,
      };
    case actions.LOAD_ADVERTISEMENTS_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.LOAD_ADVERTISEMENT:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_ADVERTISEMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        advertisement: payload.data,
      };
    case actions.LOAD_ADVERTISEMENT_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.SAVE_ADVERTISEMENT:
      return {
        ...state,
        isSaving: true,
      };
    case actions.SAVE_ADVERTISEMENT_SUCCESS:
      return {
        ...state,
        isSaving: false,
      };
    case actions.SAVE_ADVERTISEMENT_ERROR:
      return {
        ...state,
        isSaving: false,
      };

    case actions.REMOVE_ADVERTISEMENT:
      return {
        ...state,
        advertisement: {},
      };
    case actions.REMOVE_ADVERTISEMENTS:
      return {
        ...state,
        advertisements: [],
      };

    default:
      return state;
  }
}
