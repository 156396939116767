import actions from './actions';

const initState = {
  isLoading: false,
  isSaving: false,
  campuses: [],
  campus: {},
  campusesBasic: [],
  firstOpen: true,
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_CAMPUSES:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_CAMPUSES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        campuses: payload.data,
      };
    case actions.LOAD_CAMPUSES_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.LOAD_CAMPUSES_BASIC:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_CAMPUSES_BASIC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        firstOpen: false,
        campusesBasic: payload.data,
      };
    case actions.LOAD_CAMPUSES_BASIC_ERROR:
      return {
        ...state,
        isLoading: false,
        firstOpen: false,
      };

    case actions.LOAD_CAMPUS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_CAMPUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        campus: payload.data,
      };
    case actions.LOAD_CAMPUS_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.SAVE_CAMPUS:
      return {
        ...state,
        isSaving: true,
      };
    case actions.SAVE_CAMPUS_SUCCESS:
      return {
        ...state,
        isSaving: false,
      };
    case actions.SAVE_CAMPUS_ERROR:
      return {
        ...state,
        isSaving: false,
      };

    case actions.REMOVE_CAMPUS:
      return {
        ...state,
        campus: {},
      };
    case actions.REMOVE_CAMPUSES:
      return {
        ...state,
        campuses: [],
      };
    case actions.REMOVE_CAMPUSES_BASIC:
      return {
        ...state,
        campusesBasic: [],
      };

    case actions.CLOSE_COMPONENT_CAMPUSES_BASIC:
      return {
        ...state,
        firstOpen: true,
      };

    default:
      return state;
  }
}
