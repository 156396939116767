import { call, all, takeEvery, put, fork } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import actions from './actions';
import Advertisements from '../../services/advertisements';
import { SAVE_FUNCTION_CRUD } from '../../utils/enums';
import { API_STATUS } from '../../utils/enums';
import { URLS } from '../../utils/enumsUrl';

import pagesActions from '../pages/actions';

export function* loadAdvertisements() {
  yield takeEvery(actions.LOAD_ADVERTISEMENTS, function* ({ payload }) {
    const { page } = payload;
    try {
      const result = yield call(Advertisements.getAdvertisements, page);
      yield put(actions.loadAdvertisementsSuccess(result));
    } catch (error) {
      yield put(actions.loadAdvertisementsError(error));
    }
  });
}

export function* loadAdvertisement() {
  yield takeEvery(actions.LOAD_ADVERTISEMENT, function* ({ payload }) {
    const { adId } = payload;
    try {
      const result = yield call(Advertisements.getAdvertisement, adId);
      yield put(actions.loadAdvertisementSuccess(result));
    } catch (error) {
      yield put(actions.loadAdvertisementError(error));
    }
  });
}

export function* storeAdvertisements() {
  yield takeEvery(actions.SAVE_ADVERTISEMENT, function* ({ payload }) {
    const { data, actionName } = payload;
    let result;
    try {
      switch (actionName) {
        case SAVE_FUNCTION_CRUD.DELETE:
          result = yield call(Advertisements.deleteAdvertisement, data);
          break;
        case SAVE_FUNCTION_CRUD.UPDATE:
          result = yield call(Advertisements.updateAdvertisement, data);
          break;
        default:
          result = yield call(Advertisements.saveAdvertisement, data);
          break;
      }

      if (result === API_STATUS.SUCCESS) {
        yield put(push(URLS.ADVERTISEMENTS));
        yield put(actions.saveAdvertisementSuccess());

        if (actionName === SAVE_FUNCTION_CRUD.DELETE) {
          yield put(actions.loadAdvertisements());
          yield put(pagesActions.resetPage());
        }
      }
    } catch (error) {
      yield put(actions.saveAdvertisementError(error));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(loadAdvertisements), fork(loadAdvertisement), fork(storeAdvertisements)]);
}
