import React from 'react';
import { Provider } from 'react-redux';
import { store, history, persistor } from '../redux/store';
import PublicRoutes from './router';
import { ThemeProvider } from 'styled-components';
import themes from '../config/settings/themes';
import { themeConfig } from '../config/settings';
import { PersistGate } from 'redux-persist/integration/react';

const DashApp = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={themes[themeConfig.theme]}>
        <PublicRoutes history={history} />
      </ThemeProvider>
    </PersistGate>
  </Provider>
);

export default DashApp;
