import styled from 'styled-components';
import { palette } from 'styled-theme';

const ActionWrapper = styled.div`
  display: flex;
  align-content: center;
  align-items: center;

  .ant-btn-primary {
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }
  }

  a {
    margin-right: 12px;
    &:last-child {
      margin-right: 0;
    }

    i {
      font-size: 18px;
      color: ${palette('success', 0)};

      &:hover {
        color: ${palette('success', 1)};
      }
    }

    &.deleteBtn {
      i {
        color: ${palette('error', 0)};

        &:hover {
          color: ${palette('error', 1)};
        }
      }
    }
  }
`;

const SingleActionWrapper = styled.div`
  a {
    margin-right: 0;

    i {
      font-size: 18px;
      color: ${palette('success', 0)};

      &:hover {
        color: ${palette('success', 1)};
      }
    }

    &.deleteBtn {
      i {
        color: ${palette('error', 0)};

        &:hover {
          color: ${palette('error', 1)};
        }
      }
    }
  }
`;

export { ActionWrapper, SingleActionWrapper };
