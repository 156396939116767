import SuperFetch from '../utils/superFetch';
import TlnString from '../components/Tln/TlnString';

const Notifications = {
  getNotificationsPush: (page = 1) => SuperFetch.get(`push_notifications?page=${page}`).then(response => response.data),

  getNotificationPush: notificationPushId => SuperFetch.get(`notification/${notificationPushId}`).then(response => response.data),

  sendNotificationPush: notificationPush =>
    SuperFetch.post('notification', notificationPush, false, TlnString('NOTIFICATIONS_PUSH_SEND_SUCCESS')).then(
      response => response.status,
    ),
};

export default Notifications;
