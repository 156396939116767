const actions = {
  LOAD_CATEGORIES_CRUDS: 'LOAD_CATEGORIES_CRUDS',
  LOAD_CATEGORIES_CRUDS_SUCCESS: 'LOAD_CATEGORIES_CRUDS_SUCCESS',
  LOAD_CATEGORIES_CRUDS_ERROR: 'LOAD_CATEGORIES_CRUDS_ERROR',

  LOAD_CATEGORIES_CRUDS_BASIC: 'LOAD_CATEGORIES_CRUDS_BASIC',
  LOAD_CATEGORIES_CRUDS_SUCCESS_BASIC: 'LOAD_CATEGORIES_CRUDS_SUCCESS_BASIC',
  LOAD_CATEGORIES_CRUDS_ERROR_BASIC: 'LOAD_CATEGORIES_CRUDS_ERROR_BASIC',

  LOAD_CATEGORY_CRUDS: 'LOAD_CATEGORY_CRUDS',
  LOAD_CATEGORY_CRUDS_SUCCESS: 'LOAD_CATEGORY_CRUDS_SUCCESS',
  LOAD_CATEGORY_CRUDS_ERROR: 'LOAD_CATEGORY_CRUDS_ERROR',

  SAVE_CATEGORIES_CRUDS: 'SAVE_CATEGORIES_CRUDS',
  SAVE_CATEGORIES_CRUDS_SUCCESS: 'SAVE_CATEGORIES_CRUDS_SUCCESS',
  SAVE_CATEGORIES_CRUDS_ERROR: 'SAVE_CATEGORIES_CRUDS_ERROR',

  REMOVE_CATEGORY_CRUDS: 'REMOVE_CATEGORY_CRUDS',
  REMOVE_CATEGORIES_CRUDS: 'REMOVE_CATEGORIES_CRUDS',
  REMOVE_CATEGORIES_CRUDS_BASIC: 'REMOVE_CATEGORIES_CRUDS_BASIC',

  TOGGLE_CATEGORY_CRUDS_MODAL: 'TOGGLE_CATEGORY_CRUDS_MODAL',

  CLOSE_COMPONENT_CATEGORIES_CRUDS_BASIC: 'CLOSE_COMPONENT_CATEGORIES_CRUDS_BASIC',

  loadCategoriesCruds: page => ({
    type: actions.LOAD_CATEGORIES_CRUDS,
    payload: { page },
  }),
  loadCategoriesCrudsSuccess: data => ({
    type: actions.LOAD_CATEGORIES_CRUDS_SUCCESS,
    payload: { data },
  }),
  loadCategoriesCrudsError: error => ({
    type: actions.LOAD_CATEGORIES_CRUDS_ERROR,
    payload: { error },
  }),

  loadCategoriesCrudsBasic: language => ({
    type: actions.LOAD_CATEGORIES_CRUDS_BASIC,
    payload: { language },
  }),
  loadCategoriesCrudsSuccessBasic: data => ({
    type: actions.LOAD_CATEGORIES_CRUDS_SUCCESS_BASIC,
    payload: { data },
  }),
  loadCategoriesCrudsErrorBasic: error => ({
    type: actions.LOAD_CATEGORIES_CRUDS_ERROR_BASIC,
    payload: { error },
  }),

  loadCategoryCruds: articlesCategoryId => ({
    type: actions.LOAD_CATEGORY_CRUDS,
    payload: articlesCategoryId,
  }),
  loadCategoryCrudsSuccess: data => ({
    type: actions.LOAD_CATEGORY_CRUDS_SUCCESS,
    payload: { data },
  }),
  loadCategoryCrudsError: error => ({
    type: actions.LOAD_CATEGORY_CRUDS_ERROR,
    payload: { error },
  }),

  saveCategoriesCruds: (data, actionName, languageBasic) => ({
    type: actions.SAVE_CATEGORIES_CRUDS,
    payload: { data, actionName, languageBasic },
  }),
  saveCategoriesCrudsSuccess: () => ({
    type: actions.SAVE_CATEGORIES_CRUDS_SUCCESS,
  }),
  saveCategoriesCrudsError: error => ({
    type: actions.SAVE_CATEGORIES_CRUDS_ERROR,
    payload: { error },
  }),

  removeCategoryCruds: () => ({
    type: actions.REMOVE_CATEGORY_CRUDS,
  }),
  removeCategoriesCruds: () => ({
    type: actions.REMOVE_CATEGORIES_CRUDS,
  }),
  removeCategoriesCrudsBasic: () => ({
    type: actions.REMOVE_CATEGORIES_CRUDS_BASIC,
  }),

  toggleCategoryCrudsModal: () => ({
    type: actions.TOGGLE_CATEGORY_CRUDS_MODAL,
  }),

  closeComponentCategoriesCrudsBasic: () => ({
    type: actions.CLOSE_COMPONENT_CATEGORIES_CRUDS_BASIC,
  }),
};

export default actions;
