const actions = {
  LOAD_ADVERTISEMENTS: 'LOAD_ADVERTISEMENTS',
  LOAD_ADVERTISEMENTS_SUCCESS: 'LOAD_ADVERTISEMENTS_SUCCESS',
  LOAD_ADVERTISEMENTS_ERROR: 'LOAD_ADVERTISEMENTS_ERROR',

  LOAD_ADVERTISEMENT: 'LOAD_ADVERTISEMENT',
  LOAD_ADVERTISEMENT_SUCCESS: 'LOAD_ADVERTISEMENT_SUCCESS',
  LOAD_ADVERTISEMENT_ERROR: 'LOAD_ADVERTISEMENT_ERROR',

  SAVE_ADVERTISEMENT: 'SAVE_ADVERTISEMENT',
  SAVE_ADVERTISEMENT_SUCCESS: 'SAVE_ADVERTISEMENT_SUCCESS',
  SAVE_ADVERTISEMENT_ERROR: 'SAVE_ADVERTISEMENT_ERROR',

  REMOVE_ADVERTISEMENT: 'REMOVE_ADVERTISEMENT',
  REMOVE_ADVERTISEMENTS: 'REMOVE_ADVERTISEMENTS',

  loadAdvertisements: page => ({
    type: actions.LOAD_ADVERTISEMENTS,
    payload: { page },
  }),
  loadAdvertisementsSuccess: data => ({
    type: actions.LOAD_ADVERTISEMENTS_SUCCESS,
    payload: { data },
  }),
  loadAdvertisementsError: error => ({
    type: actions.LOAD_ADVERTISEMENTS_ERROR,
    payload: { error },
  }),

  loadAdvertisement: adId => ({
    type: actions.LOAD_ADVERTISEMENT,
    payload: { adId },
  }),
  loadAdvertisementSuccess: data => ({
    type: actions.LOAD_ADVERTISEMENT_SUCCESS,
    payload: { data },
  }),
  loadAdvertisementError: error => ({
    type: actions.LOAD_ADVERTISEMENT_ERROR,
    payload: { error },
  }),

  saveAdvertisement: (data, actionName) => ({
    type: actions.SAVE_ADVERTISEMENT,
    payload: { data, actionName },
  }),
  saveAdvertisementSuccess: () => ({
    type: actions.SAVE_ADVERTISEMENT_SUCCESS,
  }),
  saveAdvertisementError: error => ({
    type: actions.SAVE_ADVERTISEMENT_ERROR,
    payload: { error },
  }),

  removeAdvertisement: () => ({
    type: actions.REMOVE_ADVERTISEMENT,
  }),
  removeAdvertisements: () => ({
    type: actions.REMOVE_ADVERTISEMENTS,
  }),
};

export default actions;
