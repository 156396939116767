const actions = {
  LOAD_NOTIFICATIONS_ROUTE: 'LOAD_NOTIFICATIONS_ROUTE',
  LOAD_NOTIFICATIONS_ROUTE_SUCCESS: 'LOAD_NOTIFICATIONS_ROUTE_SUCCESS',
  LOAD_NOTIFICATIONS_ROUTE_ERROR: 'LOAD_NOTIFICATIONS_ROUTE_ERROR',

  LOAD_NOTIFICATION_ROUTE: 'LOAD_NOTIFICATION_ROUTE',
  LOAD_NOTIFICATION_ROUTE_SUCCESS: 'LOAD_NOTIFICATION_ROUTE_SUCCESS',
  LOAD_NOTIFICATION_ROUTE_ERROR: 'LOAD_NOTIFICATION_ROUTE_ERROR',

  SAVE_NOTIFICATION_ROUTE: 'SAVE_NOTIFICATION_ROUTE',
  SAVE_NOTIFICATION_ROUTE_SUCCESS: 'SAVE_NOTIFICATION_ROUTE_SUCCESS',
  SAVE_NOTIFICATION_ROUTE_ERROR: 'SAVE_NOTIFICATION_ROUTE_ERROR',

  REMOVE_NOTIFICATION_ROUTE: 'REMOVE_NOTIFICATION_ROUTE',
  REMOVE_NOTIFICATIONS_ROUTE: 'REMOVE_NOTIFICATIONS_ROUTE',

  loadNotificationsRoute: page => ({
    type: actions.LOAD_NOTIFICATIONS_ROUTE,
    payload: { page },
  }),
  loadNotificationsRouteSuccess: data => ({
    type: actions.LOAD_NOTIFICATIONS_ROUTE_SUCCESS,
    payload: { data },
  }),
  loadNotificationsRouteError: error => ({
    type: actions.LOAD_NOTIFICATIONS_ROUTE_ERROR,
    payload: { error },
  }),

  loadNotificationRoute: notificationId => ({
    type: actions.LOAD_NOTIFICATION_ROUTE,
    payload: notificationId,
  }),
  loadNotificationRouteSuccess: data => ({
    type: actions.LOAD_NOTIFICATION_ROUTE_SUCCESS,
    payload: { data },
  }),
  loadNotificationRouteError: error => ({
    type: actions.LOAD_NOTIFICATION_ROUTE_ERROR,
    payload: { error },
  }),

  saveNotificationRoute: (data, actionName) => ({
    type: actions.SAVE_NOTIFICATION_ROUTE,
    payload: { data, actionName },
  }),
  saveNotificationRouteSuccess: () => ({
    type: actions.SAVE_NOTIFICATION_ROUTE_SUCCESS,
  }),
  saveNotificationRouteError: error => ({
    type: actions.SAVE_NOTIFICATION_ROUTE_ERROR,
    payload: { error },
  }),

  removeNotificationRoute: () => ({
    type: actions.REMOVE_NOTIFICATION_ROUTE,
  }),
  removeNotificationsRoute: () => ({
    type: actions.REMOVE_NOTIFICATIONS_ROUTE,
  }),
};

export default actions;
