import { call, all, takeEvery, put, fork } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import actions from './actions';
import SosContacts from '../../services/sosContacts';
import { SAVE_FUNCTION_CRUD } from '../../utils/enums';
import { API_STATUS } from '../../utils/enums';
import { URLS } from '../../utils/enumsUrl';

import pagesActions from '../pages/actions';

export function* loadSosContacts() {
  yield takeEvery(actions.LOAD_SOS_CONTACTS, function* ({ payload }) {
    const { page } = payload;
    try {
      const result = yield call(SosContacts.getSosContacts, page);
      yield put(actions.loadSosContactsSuccess(result));
    } catch (error) {
      yield put(actions.loadSosContactsError(error));
    }
  });
}

export function* loadSosContact() {
  yield takeEvery(actions.LOAD_SOS_CONTACT, function* ({ payload }) {
    const { sosContactId } = payload;
    try {
      const result = yield call(SosContacts.getSosContact, sosContactId);
      yield put(actions.loadSosContactSuccess(result));
    } catch (error) {
      yield put(actions.loadSosContactError(error));
    }
  });
}

export function* storeSosContact() {
  yield takeEvery(actions.SAVE_SOS_CONTACT, function* ({ payload }) {
    const { data, actionName } = payload;
    let result;
    try {
      switch (actionName) {
        case SAVE_FUNCTION_CRUD.DELETE:
          result = yield call(SosContacts.deleteSosContact, data);
          break;
        case SAVE_FUNCTION_CRUD.UPDATE:
          result = yield call(SosContacts.updateSosContact, data);
          break;
        default:
          result = yield call(SosContacts.saveSosContact, data);
          break;
      }

      if (result === API_STATUS.SUCCESS) {
        yield put(push(URLS.SOS_CONTACTS));
        yield put(actions.saveSosContactSuccess());

        if (actionName === SAVE_FUNCTION_CRUD.DELETE) {
          yield put(actions.loadSosContacts());
          yield put(pagesActions.resetPage());
        }
      }
    } catch (error) {
      yield put(actions.saveSosContactError(error));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(loadSosContacts), fork(loadSosContact), fork(storeSosContact)]);
}
