const getCollapsed = state => state.App.collapsed;

const getView = state => state.App.view;

const getHeight = state => state.App.height;

const getOpenDrawer = state => state.App.openDrawer;

const getOpenKeys = state => state.App.openKeys;

const getCurrent = state => state.App.current;

export { getCollapsed, getView, getHeight, getOpenDrawer, getOpenKeys, getCurrent };
