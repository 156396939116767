const actions = {
  LOAD_EVENTS: 'LOAD_EVENTS',
  LOAD_EVENTS_SUCCESS: 'LOAD_EVENTS_SUCCESS',
  LOAD_EVENTS_ERROR: 'LOAD_EVENTS_ERROR',

  LOAD_EVENT: 'LOAD_EVENT',
  LOAD_EVENT_SUCCESS: 'LOAD_EVENT_SUCCESS',
  LOAD_EVENT_ERROR: 'LOAD_EVENT_ERROR',

  SAVE_EVENT: 'SAVE_EVENT',
  SAVE_EVENT_SUCCESS: 'SAVE_EVENT_SUCCESS',
  SAVE_EVENT_ERROR: 'SAVE_EVENT_ERROR',

  REMOVE_EVENT: 'REMOVE_EVENT',
  REMOVE_EVENTS: 'REMOVE_EVENTS',

  loadEvents: page => ({
    type: actions.LOAD_EVENTS,
    payload: { page },
  }),
  loadEventsSuccess: data => ({
    type: actions.LOAD_EVENTS_SUCCESS,
    payload: { data },
  }),
  loadEventsError: error => ({
    type: actions.LOAD_EVENTS_ERROR,
    payload: { error },
  }),

  loadEvent: eventId => ({
    type: actions.LOAD_EVENT,
    payload: eventId,
  }),
  loadEventSuccess: data => ({
    type: actions.LOAD_EVENT_SUCCESS,
    payload: { data },
  }),
  loadEventError: error => ({
    type: actions.LOAD_EVENT_ERROR,
    payload: { error },
  }),

  saveEvent: (data, actionName) => ({
    type: actions.SAVE_EVENT,
    payload: { data, actionName },
  }),
  saveEventSuccess: () => ({
    type: actions.SAVE_EVENT_SUCCESS,
  }),
  saveEventError: error => ({
    type: actions.SAVE_EVENT_ERROR,
    payload: { error },
  }),

  removeEvent: () => ({
    type: actions.REMOVE_EVENT,
  }),
  removeEvents: () => ({
    type: actions.REMOVE_EVENTS,
  }),
};

export default actions;
