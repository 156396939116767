import SuperFetch from '../utils/superFetch';
import TlnString from '../components/Tln/TlnString';

const Buttons = {
  getButtons: (page = 1) => SuperFetch.get(`button?page=${page}`).then(response => response.data),

  getButton: buttonId => SuperFetch.get(`button/${buttonId}`).then(response => response.data),

  saveButton: button =>
    SuperFetch.post('button', button, false, TlnString('CRUD_ADD_RECORD_SUCCESS')).then(response => response.status),

  updateButton: button =>
    SuperFetch.put(`button/${button.id}`, button, false, TlnString('CRUD_EDIT_RECORD_SUCCESS')).then(response => response.status),

  deleteButton: button =>
    SuperFetch.delete(`button/${button.id}`, {}, false, TlnString('CRUD_REMOVE_RECORD_SUCCESS')).then(
      response => response.status,
    ),
};

export default Buttons;
