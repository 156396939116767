const actions = {
  LOAD_OPINIONS: 'LOAD_OPINIONS',
  LOAD_OPINIONS_SUCCESS: 'LOAD_OPINIONS_SUCCESS',
  LOAD_OPINIONS_ERROR: 'LOAD_OPINIONS_ERROR',

  LOAD_OPINION: 'LOAD_OPINION',
  LOAD_OPINION_SUCCESS: 'LOAD_OPINION_SUCCESS',
  LOAD_OPINION_ERROR: 'LOAD_OPINION_ERROR',

  DELETE_OPINION: 'DELETE_OPINION',
  DELETE_OPINION_SUCCESS: 'DELETE_OPINION_SUCCESS',
  DELETE_OPINION_ERROR: 'DELETE_OPINION_ERROR',

  SEND_PUSH_OPINION_REPLY: 'SEND_PUSH_OPINION_REPLY',
  SEND_PUSH_OPINION_REPLY_SUCCESS: 'SEND_PUSH_OPINION_REPLY_SUCCESS',
  SEND_PUSH_OPINION_REPLY_ERROR: 'SEND_PUSH_OPINION_REPLY_ERROR',

  REMOVE_OPINION: 'REMOVE_OPINION',
  REMOVE_OPINIONS: 'REMOVE_OPINIONS',

  TOGGLE_OPINION_DETAILS_MODAL: 'TOGGLE_OPINION_DETAILS_MODAL',
  CLOSE_OPINION_DETAILS_MODAL: 'CLOSE_OPINION_DETAILS_MODAL',

  loadOpinions: page => ({
    type: actions.LOAD_OPINIONS,
    payload: { page },
  }),
  loadOpinionsSuccess: data => ({
    type: actions.LOAD_OPINIONS_SUCCESS,
    payload: { data },
  }),
  loadOpinionsError: error => ({
    type: actions.LOAD_OPINIONS_ERROR,
    payload: { error },
  }),

  loadOpinion: opinionId => ({
    type: actions.LOAD_OPINION,
    payload: { opinionId },
  }),
  loadOpinionSuccess: data => ({
    type: actions.LOAD_OPINION_SUCCESS,
    payload: { data },
  }),
  loadOpinionError: error => ({
    type: actions.LOAD_OPINION_ERROR,
    payload: { error },
  }),

  deleteOpinion: data => ({
    type: actions.DELETE_OPINION,
    payload: { data },
  }),
  deleteOpinionError: error => ({
    type: actions.DELETE_OPINION_ERROR,
    payload: { error },
  }),

  sendPushOpinionReply: (opinionId, data) => ({
    type: actions.SEND_PUSH_OPINION_REPLY,
    payload: { opinionId, data },
  }),
  sendPushOpinionReplySuccess: () => ({
    type: actions.SEND_PUSH_OPINION_REPLY_SUCCESS,
  }),
  sendPushOpinionReplyError: error => ({
    type: actions.SEND_PUSH_OPINION_REPLY_ERROR,
    payload: { error },
  }),

  removeOpinion: () => ({
    type: actions.REMOVE_OPINION,
  }),
  removeOpinions: () => ({
    type: actions.REMOVE_OPINIONS,
  }),

  toggleOpinionDetailsModal: () => ({
    type: actions.TOGGLE_OPINION_DETAILS_MODAL,
  }),
  closeOpinionDetailsModal: () => ({
    type: actions.CLOSE_OPINION_DETAILS_MODAL,
  }),
};

export default actions;
