const actions = {
  LOAD_FEATURES: 'LOAD_FEATURES',
  LOAD_FEATURES_SUCCESS: 'LOAD_FEATURES_SUCCESS',
  LOAD_FEATURES_ERROR: 'LOAD_FEATURES_ERROR',

  SEND_FEATURES: 'SEND_FEATURE',
  SEND_FEATURES_SUCCESS: 'SEND_FEATURES_SUCCESS',
  SEND_FEATURES_ERROR: 'SEND_FEATURE_ERROR',

  ADD_FEATURE_TO_NEW_FEATURES: 'ADD_FEATURE_TO_NEW_FEATURES',
  ADD_FEATURE_TO_EDIT_FEATURES: 'ADD_FEATURE_TO_EDIT_FEATURES',
  ADD_FEATURE_TO_DELETE_FEATURES: 'ADD_FEATURE_TO_DELETE_FEATURES',

  SWAP_NEW_FEATURES: 'SWAP_NEW_FEATURES',
  SWAP_EDIT_FEATURES: 'SWAP_EDIT_FEATURES',

  CLEAR_COLLECTIONS: 'CLEAR_COLLECTIONS',

  GENERATE_GRAPH: 'GENERATE_GRAPH',
  GENERATE_GRAPH_SUCCESS: 'GENERATE_GRAPH_SUCCESS',
  GENERATE_GRAPH_ERROR: 'GENERATE_GRAPH_ERROR',

  ADD_TO_FILTERS_FEATURES: 'ADD_TO_FILTERS_FEATURES',
  REMOVE_FROM_FILTERS_FEATURES: 'REMOVE_FROM_FILTERS_FEATURES',
  CHECK_ALL_FEATURES: 'CHECK_ALL_FEATURES',

  CHANGE_FILTER_FEATURES: 'CHANGE_FILTER_FEATURES',

  loadFeatures: (floor, p1, p2, signal) => ({
    type: actions.LOAD_FEATURES,
    payload: { floor, p1, p2, signal },
  }),
  loadFeaturesSuccess: (data, extent) => ({
    type: actions.LOAD_FEATURES_SUCCESS,
    payload: { data, extent },
  }),
  loadFeaturesError: error => ({
    type: actions.LOAD_FEATURES_ERROR,
    payload: { error },
  }),

  sendFeatures: (currentFloor, topLeftExtent, bottomRightExtent) => ({
    type: actions.SEND_FEATURES,
    payload: { currentFloor, topLeftExtent, bottomRightExtent },
  }),
  sendFeaturesSuccess: () => ({
    type: actions.SEND_FEATURES_SUCCESS,
  }),
  sendFeaturesError: error => ({
    type: actions.SEND_FEATURES_ERROR,
    payload: { error },
  }),

  addFeatureToNewFeatures: feature => ({
    type: actions.ADD_FEATURE_TO_NEW_FEATURES,
    payload: { feature },
  }),
  addFeatureToEditFeatures: feature => ({
    type: actions.ADD_FEATURE_TO_EDIT_FEATURES,
    payload: { feature },
  }),
  addFeatureToDeleteFeatures: feature => ({
    type: actions.ADD_FEATURE_TO_DELETE_FEATURES,
    payload: { feature },
  }),

  swapNewFeatures: data => ({
    type: actions.SWAP_NEW_FEATURES,
    payload: { data },
  }),
  swapEditFeatures: data => ({
    type: actions.SWAP_EDIT_FEATURES,
    payload: { data },
  }),

  clearCollections: () => ({
    type: actions.CLEAR_COLLECTIONS,
  }),

  generateGraph: () => ({
    type: actions.GENERATE_GRAPH,
  }),
  generateGraphSuccess: () => ({
    type: actions.GENERATE_GRAPH_SUCCESS,
  }),
  generateGraphError: error => ({
    type: actions.GENERATE_GRAPH_ERROR,
    payload: { error },
  }),

  addToFiltersFeatures: filter => ({
    type: actions.ADD_TO_FILTERS_FEATURES,
    payload: { filter },
  }),
  removeFromFiltersFeatures: filter => ({
    type: actions.REMOVE_FROM_FILTERS_FEATURES,
    payload: { filter },
  }),
  checkAllFeatures: () => ({
    type: actions.CHECK_ALL_FEATURES,
  }),

  changeFilterFeatures: () => ({
    type: actions.CHANGE_FILTER_FEATURES,
  }),
};

export default actions;
