import { store } from '../../redux/store';
import { getTranslations } from '../../redux/languages/selectors';

const TlnString = key => {
  const translations = getTranslations(store.getState());

  return translations[key] ? translations[key] : key;
};

export default TlnString;
