import React from 'react';

import { StyledButton, ModalNotification } from '../../../uielements';

import Tln from '../../../Tln';

import { MAP_ENUMS } from '../../../../utils/enums';

const NotEmptyCollectionsModal = ({ setVisible, clearCollections, visible }) => {
  const StyledButtonAccept = (
    <StyledButton key='accept' onClick={clearCollections} type='primary'>
      <Tln id='NOT_EMPTY_COLLECTIONS_BUTTON_OK' />
    </StyledButton>
  );

  const StyledButtonClose = (
    <StyledButton key='close' onClick={setVisible}>
      <Tln id='NOT_EMPTY_COLLECTIONS_BUTTON_CANCEL' />
    </StyledButton>
  );

  return (
    <React.Fragment>
      <ModalNotification
        title={<Tln id='NOT_EMPTY_COLLECTIONS_TITLE' />}
        destroyOnClose={true}
        visible={visible}
        onOk={clearCollections}
        onCancel={setVisible}
        getContainer={MAP_ENUMS.MAP_CONTAINER}
        centered
        footer={[StyledButtonClose, StyledButtonAccept]}
      >
        <Tln id='NOT_EMPTY_COLLECTIONS_TEXT' />
      </ModalNotification>
    </React.Fragment>
  );
};

export default NotEmptyCollectionsModal;
