import { call, all, takeEvery, put, fork } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import actions from './actions';
import Categories from '../../services/categories';
import { SAVE_FUNCTION_CRUD } from '../../utils/enums';
import { API_STATUS } from '../../utils/enums';
import { URLS } from '../../utils/enumsUrl';

import pagesActions from '../pages/actions';

export function* loadCategories() {
  yield takeEvery(actions.LOAD_CATEGORIES, function* ({ payload }) {
    const { page } = payload;
    try {
      const result = yield call(Categories.getCategories, page);
      yield put(actions.loadCategoriesSuccess(result));
    } catch (error) {
      yield put(actions.loadCategoriesError(error));
    }
  });
}

export function* loadCategory() {
  yield takeEvery(actions.LOAD_CATEGORY, function* ({ payload }) {
    try {
      const result = yield call(Categories.getCategory, payload);
      yield put(actions.loadCategorySuccess(result));
    } catch (error) {
      yield put(actions.loadCategoryError(error));
    }
  });
}

export function* storeCategory() {
  yield takeEvery(actions.SAVE_CATEGORY, function* ({ payload }) {
    const { data, actionName } = payload;
    let result;
    try {
      switch (actionName) {
        case SAVE_FUNCTION_CRUD.DELETE:
          result = yield call(Categories.deleteCategory, data);
          break;
        case SAVE_FUNCTION_CRUD.UPDATE:
          result = yield call(Categories.updateCategory, data);
          break;
        default:
          result = yield call(Categories.saveCategory, data);
          break;
      }

      if (result === API_STATUS.SUCCESS) {
        yield put(push(URLS.CATEGORIES));
        yield put(actions.saveCategorySuccess());

        if (actionName === SAVE_FUNCTION_CRUD.DELETE) {
          yield put(actions.loadCategories());
          yield put(pagesActions.resetPage());
        }
      }
    } catch (error) {
      yield put(actions.saveCategoryError(error));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(loadCategories), fork(loadCategory), fork(storeCategory)]);
}
