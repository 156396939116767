import { createSelector } from 'reselect';
import * as translations from '../../config/translations';
import { defaultLanguage } from '../../config/settings';

const getActiveLanguage = state => state.Languages.activeLanguage;

const getTranslations = createSelector([getActiveLanguage], activeLanguage =>
  translations[activeLanguage] ? translations[activeLanguage] : translations[defaultLanguage],
);
export { getTranslations, getActiveLanguage };
