import React from 'react';
import Tln from '../../components/Tln';
import { Icon } from '../../components/uielements';
import { ROUTER_URLS } from '../../utils/enumsUrl';

export const commonNav = [];

export const adminNav = [
  {
    key: ROUTER_URLS.MAP_WITH_FIRST_FLOOR,
    label: <Tln id='MENU_MAP' />,
    leftIcon: <Icon type='compass' />,
  },
  {
    key: ROUTER_URLS.CATEGORIES,
    label: <Tln id='MENU_CATEGORIES' />,
    leftIcon: <Icon type='folder' theme='filled' />,
  },
  {
    key: ROUTER_URLS.BEACONS,
    label: <Tln id='MENU_BEACONS' />,
    leftIcon: <Icon type='pushpin' />,
  },
  {
    key: ROUTER_URLS.ARTICLES,
    label: <Tln id='MENU_ARTICLES' />,
    leftIcon: <Icon type='file-text' />,
  },
  {
    key: ROUTER_URLS.EVENTS,
    label: <Tln id='MENU_EVENTS' />,
    leftIcon: <Icon type='calendar' />,
  },
  {
    key: ROUTER_URLS.CATEGORIES_CRUDS,
    label: <Tln id='MENU_CATEGORIES_CRUDS' />,
    leftIcon: <Icon type='container' theme='filled' />,
  },
  {
    key: ROUTER_URLS.CAMPUSES,
    label: <Tln id='MENU_CAMPUSES' />,
    leftIcon: <Icon type='tags' />,
  },
  {
    key: ROUTER_URLS.BUTTONS,
    label: <Tln id='MENU_BUTTONS' />,
    leftIcon: <Icon type='play-square' />,
  },
  // {
  //   key: ROUTER_URLS.SOS,
  //   label: <Tln id='MENU_SOS' />,
  //   leftIcon: <Icon type="notification"/>,
  // },
  // {
  //   key: ROUTER_URLS.SOS_CONTACTS,
  //   label: <Tln id='MENU_SOS_CONTACTS' />,
  //   leftIcon: <Icon type="contacts" />,
  // },
  {
    key: ROUTER_URLS.ADVERTISEMENTS,
    label: <Tln id='MENU_ADVERTISEMENTS' />,
    leftIcon: <Icon type='barcode' />,
  },
  {
    key: ROUTER_URLS.NOTIFICATIONS,
    label: <Tln id='MENU_NOTIFICATIONS' />,
    leftIcon: <Icon type='bell' />,
  },
  {
    key: ROUTER_URLS.NOTIFICATIONS_PUSH,
    label: <Tln id='MENU_NOTIFICATIONS_PUSH' />,
    leftIcon: <Icon type='alert' />,
  },
  {
    key: ROUTER_URLS.NOTIFICATIONS_ROUTE,
    label: <Tln id='MENU_NOTIFICATIONS_ROUTE' />,
    leftIcon: <Icon type='environment' />,
  },
  {
    key: ROUTER_URLS.PARKING_LOTS,
    label: <Tln id='MENU_PARKING_LOT' />,
    leftIcon: <Icon type='car' />,
  },
  {
    key: ROUTER_URLS.OPINIONS,
    label: <Tln id='MENU_OPINIONS' />,
    leftIcon: <Icon type='like' />,
  },
  {
    key: ROUTER_URLS.USERS,
    label: <Tln id='MENU_USERS' />,
    leftIcon: <Icon type='user' />,
  },
];

export const moderatorNav = [
  {
    key: ROUTER_URLS.MAP_WITH_FIRST_FLOOR,
    label: <Tln id='MENU_MAP' />,
    leftIcon: <Icon type='compass' />,
  },
  {
    key: ROUTER_URLS.CATEGORIES,
    label: <Tln id='MENU_CATEGORIES' />,
    leftIcon: <Icon type='folder' theme='filled' />,
  },
  {
    key: ROUTER_URLS.BEACONS,
    label: <Tln id='MENU_BEACONS' />,
    leftIcon: <Icon type='pushpin' />,
  },
  {
    key: ROUTER_URLS.ARTICLES,
    label: <Tln id='MENU_ARTICLES' />,
    leftIcon: <Icon type='file-text' />,
  },
  {
    key: ROUTER_URLS.EVENTS,
    label: <Tln id='MENU_EVENTS' />,
    leftIcon: <Icon type='calendar' />,
  },
  {
    key: ROUTER_URLS.CATEGORIES_CRUDS,
    label: <Tln id='MENU_CATEGORIES_CRUDS' />,
    leftIcon: <Icon type='container' theme='filled' />,
  },
  {
    key: ROUTER_URLS.CAMPUSES,
    label: <Tln id='MENU_CAMPUSES' />,
    leftIcon: <Icon type='tags' />,
  },
  {
    key: ROUTER_URLS.BUTTONS,
    label: <Tln id='MENU_BUTTONS' />,
    leftIcon: <Icon type='play-square' />,
  },
  // {
  //   key: ROUTER_URLS.SOS,
  //   label: <Tln id='MENU_SOS' />,
  //   leftIcon: <Icon type="notification"/>,
  // },
  // {
  //   key: ROUTER_URLS.SOS_CONTACTS,
  //   label: <Tln id='MENU_SOS_CONTACTS' />,
  //   leftIcon: <Icon type="contacts" />,
  // },
  {
    key: ROUTER_URLS.ADVERTISEMENTS,
    label: <Tln id='MENU_ADVERTISEMENTS' />,
    leftIcon: <Icon type='barcode' />,
  },
  {
    key: ROUTER_URLS.NOTIFICATIONS,
    label: <Tln id='MENU_NOTIFICATIONS' />,
    leftIcon: <Icon type='bell' />,
  },
  {
    key: ROUTER_URLS.NOTIFICATIONS_ROUTE,
    label: <Tln id='MENU_NOTIFICATIONS_ROUTE' />,
    leftIcon: <Icon type='environment' />,
  },
  {
    key: ROUTER_URLS.NOTIFICATIONS_PUSH,
    label: <Tln id='MENU_NOTIFICATIONS_PUSH' />,
    leftIcon: <Icon type='alert' />,
  },
  {
    key: ROUTER_URLS.PARKING_LOTS,
    label: <Tln id='MENU_PARKING_LOT' />,
    leftIcon: <Icon type='car' />,
  },
  {
    key: ROUTER_URLS.OPINIONS,
    label: <Tln id='MENU_OPINIONS' />,
    leftIcon: <Icon type='like' />,
  },
];
