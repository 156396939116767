import actions from './actions';

const initState = {
  isLoading: false,
  file: null,
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.SEND_FILE:
      return {
        ...state,
        isLoading: true,
      };
    case actions.SEND_FILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        file: payload.data,
      };
    case actions.SEND_FILE_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.REMOVE_FILE:
      return {
        ...state,
        isLoading: false,
        file: null,
      };

    default:
      return state;
  }
}
