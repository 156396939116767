const actions = {
  SET_PAGE: 'SET_PAGE',
  RESET_PAGE: 'RESET_PAGE',

  setPage: page => ({
    type: actions.SET_PAGE,
    payload: { page },
  }),
  resetPage: () => ({
    type: actions.RESET_PAGE,
  }),
};

export default actions;
