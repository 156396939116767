import actions from './actions';

const initState = {
  isLoading: false,
  isLoadingSingle: false,
  isSaving: false,
  modalPreviewActive: false,
  notificationsPush: [],
  notificationPush: {},
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_NOTIFICATIONS_PUSH:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_NOTIFICATIONS_PUSH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        notificationsPush: payload.data,
      };
    case actions.LOAD_NOTIFICATIONS_PUSH_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.LOAD_NOTIFICATION_PUSH:
      return {
        ...state,
        isLoadingSingle: true,
      };
    case actions.LOAD_NOTIFICATION_PUSH_SUCCESS:
      return {
        ...state,
        isLoadingSingle: false,
        notificationPush: payload.data,
      };
    case actions.LOAD_NOTIFICATION_PUSH_ERROR:
      return {
        ...state,
        isLoadingSingle: false,
      };

    case actions.SEND_NOTIFICATION_PUSH:
      return {
        ...state,
        isSaving: true,
      };
    case actions.SEND_NOTIFICATION_PUSH_SUCCESS:
      return {
        ...state,
        isSaving: false,
      };
    case actions.SEND_NOTIFICATION_PUSH_ERROR:
      return {
        ...state,
        isSaving: false,
      };

    case actions.TOGGLE_NOTIFICATION_PUSH_PREVIEW_MODAL:
      return {
        ...state,
        modalPreviewActive: !state.modalPreviewActive,
      };

    case actions.CLOSE_MODAL_NOTIFICATION_PUSH:
      return {
        ...state,
        modalPreviewActive: false,
      };

    case actions.REMOVE_NOTIFICATION_PUSH:
      return {
        ...state,
        notificationPush: {},
      };
    case actions.REMOVE_NOTIFICATIONS_PUSH:
      return {
        ...state,
        notificationsPush: [],
      };

    default:
      return state;
  }
}
