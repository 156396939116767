const actions = {
  LOAD_NOTIFICATIONS: 'LOAD_NOTIFICATIONS',
  LOAD_NOTIFICATIONS_SUCCESS: 'LOAD_NOTIFICATIONS_SUCCESS',
  LOAD_NOTIFICATIONS_ERROR: 'LOAD_NOTIFICATIONS_ERROR',

  LOAD_NOTIFICATION: 'LOAD_NOTIFICATION',
  LOAD_NOTIFICATION_SUCCESS: 'LOAD_NOTIFICATION_SUCCESS',
  LOAD_NOTIFICATION_ERROR: 'LOAD_NOTIFICATION_ERROR',

  SAVE_NOTIFICATION: 'SAVE_NOTIFICATION',
  SAVE_NOTIFICATION_SUCCESS: 'SAVE_NOTIFICATION_SUCCESS',
  SAVE_NOTIFICATION_ERROR: 'SAVE_NOTIFICATION_ERROR',

  LOAD_NOTIFICATIONS_TYPE: 'LOAD_NOTIFICATIONS_TYPE',
  LOAD_NOTIFICATIONS_TYPE_SUCCESS: 'LOAD_NOTIFICATIONS_TYPE_SUCCESS',
  LOAD_NOTIFICATIONS_TYPE_ERROR: 'LOAD_NOTIFICATIONS_TYPE_ERROR',

  REMOVE_NOTIFICATION: 'REMOVE_NOTIFICATION',
  REMOVE_NOTIFICATIONS: 'REMOVE_NOTIFICATIONS',

  loadNotifications: page => ({
    type: actions.LOAD_NOTIFICATIONS,
    payload: { page },
  }),
  loadNotificationsSuccess: data => ({
    type: actions.LOAD_NOTIFICATIONS_SUCCESS,
    payload: { data },
  }),
  loadNotificationsError: error => ({
    type: actions.LOAD_NOTIFICATIONS_ERROR,
    payload: { error },
  }),

  loadNotification: notificationId => ({
    type: actions.LOAD_NOTIFICATION,
    payload: notificationId,
  }),
  loadNotificationSuccess: data => ({
    type: actions.LOAD_NOTIFICATION_SUCCESS,
    payload: { data },
  }),
  loadNotificationError: error => ({
    type: actions.LOAD_NOTIFICATION_ERROR,
    payload: { error },
  }),

  saveNotification: (data, actionName) => ({
    type: actions.SAVE_NOTIFICATION,
    payload: { data, actionName },
  }),
  saveNotificationSuccess: () => ({
    type: actions.SAVE_NOTIFICATION_SUCCESS,
  }),
  saveNotificationError: error => ({
    type: actions.SAVE_NOTIFICATION_ERROR,
    payload: { error },
  }),

  removeNotification: () => ({
    type: actions.REMOVE_NOTIFICATION,
  }),
  removeNotifications: () => ({
    type: actions.REMOVE_NOTIFICATIONS,
  }),
};

export default actions;
