import React, { Component } from 'react';

import withLanguages from '../../hoc/withLanguages';

class Tln extends Component {
  render() {
    const { translations, id } = this.props;

    return <React.Fragment>{translations[id] ? translations[id] : id}</React.Fragment>;
  }
}

export default withLanguages(Tln);
