import { call, all, takeEvery, put, fork, select } from 'redux-saga/effects';
import actions from './actions';
import Features from '../../services/features';
import { openNotification } from '../../components/uielements';
import TlnString from '../../components/Tln/TlnString';
import { MAP_ENUMS, NOTIFICATION_TYPES, API_STATUS } from '../../utils/enums';

import { getNewFeaturesGeoJson, getEditFeaturesGeoJson, getDeleteFeaturesGeoJson } from './selectors';

export function* loadFeatures() {
  yield takeEvery(actions.LOAD_FEATURES, function* ({ payload }) {
    const { floor, p1, p2, signal } = payload;
    try {
      const result = yield call(Features.getFeatures, floor, p1, p2, signal);
      yield put(actions.loadFeaturesSuccess(result, result.data.extent));
    } catch (error) {
      yield put(actions.loadFeaturesError(error));
    }
  });
}

export function* sendFeatures() {
  yield takeEvery(actions.SEND_FEATURES, function* ({ payload }) {
    const { currentFloor, topLeftExtent, bottomRightExtent } = payload;
    const newFeaturesGeoJson = yield select(getNewFeaturesGeoJson);
    const editedFeaturesGeoJson = yield select(getEditFeaturesGeoJson);
    const deletedFeaturesGeoJson = yield select(getDeleteFeaturesGeoJson);

    try {
      const results = yield all([
        call(Features.sendNewFeatures, newFeaturesGeoJson),
        call(Features.sendEditedFeatures, editedFeaturesGeoJson),
        call(Features.sendDeletedFeatures, deletedFeaturesGeoJson),
      ]);

      if (results.every(isRequestSuccess)) {
        yield put(actions.sendFeaturesSuccess());
        yield put(actions.clearCollections());
        yield put(actions.loadFeatures(currentFloor, topLeftExtent, bottomRightExtent));
        openNotification(
          NOTIFICATION_TYPES.SUCCESS,
          TlnString('NOTIFICATION_INFO_SAVE_DATA_SUCCESS'),
          NOTIFICATION_TYPES.PLACEMENT_BOTTOM_RIGHT,
          MAP_ENUMS.MAP_CONTAINER,
        );
      } else {
        yield put(actions.sendFeaturesError(TlnString('NOTIFICATION_INFO_SAVE_DATA_ERROR')));
        openNotification(
          NOTIFICATION_TYPES.ERROR,
          TlnString('NOTIFICATION_INFO_SAVE_DATA_ERROR'),
          NOTIFICATION_TYPES.PLACEMENT_BOTTOM_RIGHT,
          MAP_ENUMS.MAP_CONTAINER,
        );
      }
    } catch (error) {
      yield put(actions.sendFeaturesError(error));
    }
  });
}

export function* generateGraph() {
  yield takeEvery(actions.GENERATE_GRAPH, function* () {
    openNotification(
      NOTIFICATION_TYPES.INFO,
      TlnString('NOTIFICATION_INFO_GENERATE_GRAPH'),
      NOTIFICATION_TYPES.PLACEMENT_BOTTOM_RIGHT,
      MAP_ENUMS.MAP_CONTAINER,
    );
    try {
      yield call(Features.generateGraph);
      yield put(actions.generateGraphSuccess());
    } catch (error) {
      yield put(actions.generateGraphError(error));
    }
  });
}

export function* filterFeatures() {
  yield takeEvery(
    [actions.ADD_TO_FILTERS_FEATURES, actions.REMOVE_FROM_FILTERS_FEATURES, actions.CHECK_ALL_FEATURES],
    function* () {
      yield put(actions.changeFilterFeatures());
    },
  );
}

const isRequestSuccess = (element, index, array) => {
  return element === API_STATUS.SUCCESS;
};

export default function* rootSaga() {
  yield all([fork(loadFeatures), fork(sendFeatures), fork(generateGraph), fork(filterFeatures)]);
}
