import React from 'react';

import user from '../../assets/user.png';

const TopbarUser = () => {
  return (
    <div className='isoImgWrapper'>
      <img alt='user' src={user} />
      <span className='userActivity online' />
    </div>
  );
};

export default TopbarUser;
