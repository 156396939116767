import { store } from '../redux/store';
import { push } from 'react-router-redux';
import authActions from '../redux/auth/actions';

import { apiUrl, defaultLanguage } from '../config/settings';

import { openNotification } from '../components/uielements';
import TlnString from '../components/Tln/TlnString';

import { NOTIFICATION_TYPES, MAP_ENUMS, API_STATUS } from './enums';

const customHeader = (lang, isFormData) => {
  const header = {
    Accept: 'application/json',
    Authorization: `Bearer ${localStorage.getItem('id_token')}`,
    Lang: lang,
  };
  if (!isFormData) {
    header['Content-Type'] = 'application/json';
  }
  return header;
};

const base = (method, url, data = {}, isGeoJson = false, message, signal = null) => {
  const lang = defaultLanguage;
  const isFormData = data instanceof FormData;
  const body =
    method === 'post' || method === 'put' || (method === 'delete' && isGeoJson)
      ? isFormData || isGeoJson
        ? data
        : JSON.stringify(data)
      : null;

  return fetch(`${apiUrl}${url}`, {
    method,
    headers: customHeader(lang, isFormData),
    body: body,
    signal: signal,
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === API_STATUS.SUCCESS) {
        message &&
          openNotification(
            NOTIFICATION_TYPES.SUCCESS,
            message,
            NOTIFICATION_TYPES.PLACEMENT_TOP_RIGHT,
            isGeoJson ? MAP_ENUMS.MAP_CONTAINER : 'body',
          );
        return response;
      } else throw response;
    })
    .catch(response => {
      const { message, errors } = response;

      switch (message) {
        case API_STATUS.UNAUTHENTICATED:
          store.dispatch(authActions.logoutSuccess());
          throw response;

        case API_STATUS.FORBIDDEN:
          store.dispatch(push('/403'));
          throw response;

        default:
          break;
      }

      if (signal && signal.aborted) {
        throw response;
      }

      if (errors) {
        Object.keys(errors).map(key =>
          errors[key].map(error =>
            openNotification(
              NOTIFICATION_TYPES.ERROR,
              TlnString(error) !== error ? TlnString(error) : TlnString('SERVER_ERROR'),
              NOTIFICATION_TYPES.PLACEMENT_TOP_RIGHT,
              isGeoJson ? MAP_ENUMS.MAP_CONTAINER : 'body',
            ),
          ),
        );
      } else {
        openNotification(
          NOTIFICATION_TYPES.ERROR,
          TlnString('SERVER_ERROR'),
          NOTIFICATION_TYPES.PLACEMENT_TOP_RIGHT,
          isGeoJson ? MAP_ENUMS.MAP_CONTAINER : 'body',
        );
      }

      throw response;
    });
};

const SuperFetch = {};
['get', 'post', 'put', 'delete'].forEach(method => {
  SuperFetch[method] = base.bind(null, method);
});
export default SuperFetch;
