import { call, all, takeEvery, put, fork } from 'redux-saga/effects';
import actions from './actions';
import GeoBasic from '../../services/geoBasic';

export function* loadSelectOptions() {
  yield takeEvery(actions.LOAD_SELECT_OPTIONS, function* ({ payload }) {
    const { type, centerPoint, profile, withId, withoutId } = payload;
    try {
      const result = yield call(GeoBasic.getSelectOptions, type, centerPoint, profile, withId, withoutId);
      yield put(actions.loadSelectOptionsSuccess(result, type));
    } catch (error) {
      yield put(actions.loadSelectOptionsError(error));
    }
  });
}

export function* loadSingleObjectName() {
  yield takeEvery(actions.LOAD_SINGLE_OBJECT_NAME, function* ({ payload }) {
    const { type, id } = payload;
    try {
      const result = yield call(GeoBasic.getSingleObjectName, type, id);
      yield put(actions.loadSingleObjectNameSuccess(result, type));
    } catch (error) {
      yield put(actions.loadSingleObjectNameError(error));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(loadSelectOptions), fork(loadSingleObjectName)]);
}
