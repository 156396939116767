import { call, all, takeEvery, put, fork } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import actions from './actions';
import NotificationsRoute from '../../services/notificationsRoute';
import { SAVE_FUNCTION_CRUD } from '../../utils/enums';
import { API_STATUS } from '../../utils/enums';
import { URLS } from '../../utils/enumsUrl';

import pagesActions from '../pages/actions';

export function* loadNotificationsRoute() {
  yield takeEvery(actions.LOAD_NOTIFICATIONS_ROUTE, function* ({ payload }) {
    const { page } = payload;
    try {
      const result = yield call(NotificationsRoute.getNotificationsRoute, page);
      yield put(actions.loadNotificationsRouteSuccess(result));
    } catch (error) {
      yield put(actions.loadNotificationsRouteError(error));
    }
  });
}

export function* loadNotificationRoute() {
  yield takeEvery(actions.LOAD_NOTIFICATION_ROUTE, function* ({ payload }) {
    try {
      const result = yield call(NotificationsRoute.getNotificationRoute, payload);
      yield put(actions.loadNotificationRouteSuccess(result));
    } catch (error) {
      yield put(actions.loadNotificationRouteError(error));
    }
  });
}

export function* storeNotificationsRoute() {
  yield takeEvery(actions.SAVE_NOTIFICATION_ROUTE, function* ({ payload }) {
    const { data, actionName } = payload;
    let result;
    try {
      switch (actionName) {
        case SAVE_FUNCTION_CRUD.DELETE:
          result = yield call(NotificationsRoute.deleteNotificationRoute, data);
          break;
        case SAVE_FUNCTION_CRUD.UPDATE:
          result = yield call(NotificationsRoute.updateNotificationRoute, data);
          break;
        default:
          result = yield call(NotificationsRoute.saveNotificationRoute, data);
          break;
      }

      if (result === API_STATUS.SUCCESS) {
        yield put(push(URLS.NOTIFICATIONS_ROUTE));
        yield put(actions.saveNotificationRouteSuccess());

        if (actionName === SAVE_FUNCTION_CRUD.DELETE) {
          yield put(actions.loadNotificationsRoute());
          yield put(pagesActions.resetPage());
        }
      }
    } catch (error) {
      yield put(actions.saveNotificationRouteError(error));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(loadNotificationsRoute), fork(loadNotificationRoute), fork(storeNotificationsRoute)]);
}
