const actions = {
  LOAD_SELECT_OPTIONS: 'LOAD_SELECT_OPTIONS',
  LOAD_SELECT_OPTIONS_SUCCESS: 'LOAD_SELECT_OPTIONS_SUCCESS',
  LOAD_SELECT_OPTIONS_ERROR: 'LOAD_SELECT_OPTIONS_ERROR',

  LOAD_SINGLE_OBJECT_NAME: 'LOAD_SINGLE_OBJECT_NAME',
  LOAD_SINGLE_OBJECT_NAME_SUCCESS: 'LOAD_SINGLE_OBJECT_NAME_SUCCESS',
  LOAD_SINGLE_OBJECT_NAME_ERROR: 'LOAD_SINGLE_OBJECT_NAME_ERROR',

  REMOVE_SELECT_OPTIONS: 'REMOVE_SELECT_OPTIONS',
  REMOVE_SINGLE_OBJECT_NAME: 'REMOVE_SINGLE_OBJECT_NAME',

  loadSelectOptions: (type, centerPoint, profile, withId, withoutId) => ({
    type: actions.LOAD_SELECT_OPTIONS,
    payload: { type, centerPoint, profile, withId, withoutId },
  }),
  loadSelectOptionsSuccess: (data, featureType) => ({
    type: actions.LOAD_SELECT_OPTIONS_SUCCESS,
    payload: { data, featureType },
  }),
  loadSelectOptionsError: error => ({
    type: actions.LOAD_SELECT_OPTIONS_ERROR,
    payload: { error },
  }),

  loadSingleObjectName: (type, id) => ({
    type: actions.LOAD_SINGLE_OBJECT_NAME,
    payload: { type, id },
  }),
  loadSingleObjectNameSuccess: (data, featureType) => ({
    type: actions.LOAD_SINGLE_OBJECT_NAME_SUCCESS,
    payload: { data, featureType },
  }),
  loadSingleObjectNameError: error => ({
    type: actions.LOAD_SINGLE_OBJECT_NAME_ERROR,
    payload: { error },
  }),

  removeSelectOptions: () => ({
    type: actions.REMOVE_SELECT_OPTIONS,
  }),
  removeSingleObjectName: () => ({
    type: actions.REMOVE_SINGLE_OBJECT_NAME,
  }),
};

export default actions;
