import actions from './actions';

const initState = {
  isLoading: false,
  isSaving: false,
  categories: [],
  category: {},
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_CATEGORIES:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_CATEGORIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categories: payload.data,
      };
    case actions.LOAD_CATEGORIES_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.LOAD_CATEGORY:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_CATEGORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        category: payload.data,
      };
    case actions.LOAD_CATEGORY_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.SAVE_CATEGORY:
      return {
        ...state,
        isSaving: true,
      };
    case actions.SAVE_CATEGORY_SUCCESS:
      return {
        ...state,
        isSaving: false,
      };
    case actions.SAVE_CATEGORY_ERROR:
      return {
        ...state,
        isSaving: false,
      };

    case actions.REMOVE_CATEGORY:
      return {
        ...state,
        category: {},
      };
    case actions.REMOVE_CATEGORIES:
      return {
        ...state,
        categories: [],
      };

    default:
      return state;
  }
}
