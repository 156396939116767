import React, { Component } from 'react';
import { connect } from 'react-redux';

import languagesActions from '../redux/languages/actions';
import { getActiveLanguage, getTranslations } from '../redux/languages/selectors';

const { setLanguage } = languagesActions;

const withLanguages = WrappedComponent => {
  class Languages extends Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(Languages);
};

const mapStateToProps = state => {
  return {
    activeLanguage: getActiveLanguage(state),
    translations: getTranslations(state),
  };
};

const mapDispatchToProps = { setLanguage };

export default withLanguages;
