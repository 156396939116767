import SuperFetch from '../utils/superFetch';
import TlnString from '../components/Tln/TlnString';

const CategoriesCruds = {
  getCategoriesCruds: (page = 1) => SuperFetch.get(`category?page=${page}`).then(response => response.data),

  getCategoryCruds: categoryCrudsId => SuperFetch.get(`category/${categoryCrudsId}`).then(response => response.data),

  getCategoriesCrudsBasic: (language = 'pl') => SuperFetch.get(`category_basic?lang=${language}`).then(response => response.data),

  saveCategoryCruds: categoryCruds =>
    SuperFetch.post('category', categoryCruds, false, TlnString('CRUD_ADD_RECORD_SUCCESS')).then(response => response.status),

  updateCategoryCruds: categoryCruds =>
    SuperFetch.put(`category/${categoryCruds.id}`, categoryCruds, false, TlnString('CRUD_EDIT_RECORD_SUCCESS')).then(
      response => response.status,
    ),

  deleteCategoryCruds: categoryCruds =>
    SuperFetch.delete(`category/${categoryCruds.id}`, {}, false, TlnString('CRUD_REMOVE_RECORD_SUCCESS')).then(
      response => response.status,
    ),
};

export default CategoriesCruds;
