import actions from './actions';

const initState = {
  isLoading: false,
  isSaving: false,
  buttons: [],
  button: {},
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_BUTTONS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_BUTTONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        buttons: payload.data,
      };
    case actions.LOAD_BUTTONS_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.LOAD_BUTTON:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_BUTTON_SUCCESS:
      return {
        ...state,
        isLoading: false,
        button: payload.data,
      };
    case actions.LOAD_BUTTON_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.SAVE_BUTTON:
      return {
        ...state,
        isSaving: true,
      };
    case actions.SAVE_BUTTON_SUCCESS:
      return {
        ...state,
        isSaving: false,
      };
    case actions.SAVE_BUTTON_ERROR:
      return {
        ...state,
        isSaving: false,
      };

    case actions.REMOVE_BUTTON:
      return {
        ...state,
        button: {},
      };
    case actions.REMOVE_BUTTONS:
      return {
        ...state,
        buttons: [],
      };

    default:
      return state;
  }
}
