import actions from './actions';

const initState = {
  isLoading: false,
  isSaving: false,
  sosContacts: [],
  sosContact: {},
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_SOS_CONTACTS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_SOS_CONTACTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        sosContacts: payload.data,
      };
    case actions.LOAD_SOS_CONTACTS_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.LOAD_SOS_CONTACT:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_SOS_CONTACT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        sosContact: payload.data,
      };
    case actions.LOAD_SOS_CONTACT_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.SAVE_SOS_CONTACT:
      return {
        ...state,
        isSaving: true,
      };
    case actions.SAVE_SOS_CONTACT_SUCCESS:
      return {
        ...state,
        isSaving: false,
      };
    case actions.SAVE_SOS_CONTACT_ERROR:
      return {
        ...state,
        isSaving: false,
      };

    case actions.REMOVE_SOS_CONTACT:
      return {
        ...state,
        sosContact: {},
      };
    case actions.REMOVE_SOS_CONTACTS:
      return {
        ...state,
        sosContacts: [],
      };

    default:
      return state;
  }
}
