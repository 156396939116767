import { call, all, takeEvery, put, fork } from 'redux-saga/effects';
import actions from './actions';
import ConnectorValues from '../../services/connectorValues';

export function* loadSelectOptionsConnector() {
  yield takeEvery(actions.LOAD_SELECT_OPTIONS_CONNECTOR, function* ({ payload }) {
    const { dataType } = payload;
    try {
      const result = yield call(ConnectorValues.getSelectOptions, dataType);
      yield put(actions.loadSelectOptionsSuccess(result, dataType));
    } catch (error) {
      yield put(actions.loadSelectOptionsError(error));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(loadSelectOptionsConnector)]);
}
