const actions = {
  SEND_FILE: 'SEND_FILE',
  SEND_FILE_SUCCESS: 'SEND_FILE_SUCCESS',
  SEND_FILE_ERROR: 'SEND_FILE_ERROR',

  REMOVE_FILE: 'REMOVE_FILE',

  sendFile: data => ({
    type: actions.SEND_FILE,
    payload: { data },
  }),
  sendFileSuccess: data => ({
    type: actions.SEND_FILE_SUCCESS,
    payload: { data },
  }),
  sendFileError: error => ({
    type: actions.SEND_FILE_ERROR,
    payload: { error },
  }),

  removeFile: () => ({
    type: actions.REMOVE_FILE,
  }),
};

export default actions;
