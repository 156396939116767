import styled from 'styled-components';
import { palette } from 'styled-theme';

const Label = styled.label`
  font-size: 13px;
  color: ${palette('text', 2)};
  line-height: 1.5;
  font-weight: 400;
  padding: 0;
  margin: 0 0 8px;
`;

export { Label };
