import React from 'react';
import Scrollbar from 'react-smooth-scrollbar';

const CustomScrollbar = ({ id, height, children, className, withTopbar = true }) => (
  <Scrollbar
    id={id}
    className={className}
    style={withTopbar ? { height: height - 70 } : { height: height }}
    continuousScrolling={true}
  >
    {children}
  </Scrollbar>
);

export { CustomScrollbar };
