import React from 'react';
import Tln from '../components/Tln';

import { STATUS_TYPE, NOTIFICATIONS_TYPES, BUTTONS_TYPES, USER_ROLES, UPLOAD_VALUES } from './enums';

const DRAW_SHAPES = {
  POINT: 'Point',
  POLYGON: 'Polygon',
  CIRCLE: 'Circle',
  SQUARE: 'Square',
  BOX: 'Box',
  LINESTRING: 'LineString',
};

const OBJECT_NAMES = {
  CAMPUS: 'campus',
  BUILDING: 'building',
  LEVEL: 'level',
  ROOM: 'room',
  BEACON: 'beaconplace',
  POI: 'poi',
  ROI: 'roi',
  ROUTE: 'route',
  WALL: 'wall',
  STAIRS: 'stairs',
  BACKGROUND: 'background',
  DOOR: 'door',
  FURNITURE: 'furniture',
  PARKING: 'parking',
  WINDOWS: 'windows',
  CONNECTOR: 'connector',
};

const OBJECT_TYPES_ARRAY = [
  OBJECT_NAMES.CAMPUS,
  OBJECT_NAMES.BUILDING,
  OBJECT_NAMES.LEVEL,
  OBJECT_NAMES.ROI,
  OBJECT_NAMES.BEACON,
  OBJECT_NAMES.POI,
  OBJECT_NAMES.CONNECTOR,
  OBJECT_NAMES.WALL,
  OBJECT_NAMES.ROUTE,
  OBJECT_NAMES.DOOR,
  OBJECT_NAMES.STAIRS,
  // OBJECT_NAMES.PARKING,
];

const OBJECT_TYPES_FILTER_FEATURES = {
  CAMPUS: { id: OBJECT_NAMES.CAMPUS, value: <Tln id='SELECT_VALUE_CAMPUS' /> },
  BUILDING: { id: OBJECT_NAMES.BUILDING, value: <Tln id='SELECT_VALUE_BUILDING' /> },
  LEVEL: { id: OBJECT_NAMES.LEVEL, value: <Tln id='SELECT_VALUE_LEVEL' /> },
  WALL: { id: OBJECT_NAMES.WALL, value: <Tln id='SELECT_VALUE_WALL' /> },
  ROI: { id: OBJECT_NAMES.ROI, value: <Tln id='SELECT_VALUE_ROI' /> },
  BEACON: { id: OBJECT_NAMES.BEACON, value: <Tln id='SELECT_VALUE_BEACON' /> },
  ROUTE: { id: OBJECT_NAMES.ROUTE, value: <Tln id='SELECT_VALUE_ROUTE' /> },
  CONNECTOR: { id: OBJECT_NAMES.CONNECTOR, value: <Tln id='SELECT_VALUE_CONNECTOR' /> },
  POI: { id: OBJECT_NAMES.POI, value: <Tln id='SELECT_VALUE_POI' /> },
  STAIRS: { id: OBJECT_NAMES.STAIRS, value: <Tln id='SELECT_VALUE_STAIRS' /> },
  DOOR: { id: OBJECT_NAMES.DOOR, value: <Tln id='SELECT_VALUE_DOOR' /> },
  // PARKING: {id: OBJECT_NAMES.PARKING, value: <Tln id="SELECT_VALUE_PARKING" />},
};

const OBJECT_TYPES = {
  POLYGON: {},
  POINT: {},
  LINE: {},
};

OBJECT_TYPES.POLYGON = [
  { key: OBJECT_NAMES.CAMPUS, value: <Tln id='SELECT_VALUE_CAMPUS' /> },
  { key: OBJECT_NAMES.BUILDING, value: <Tln id='SELECT_VALUE_BUILDING' /> },
  // {key: OBJECT_NAMES.ROOM, value: <Tln id="SELECT_VALUE_ROOM" />},
  { key: OBJECT_NAMES.LEVEL, value: <Tln id='SELECT_VALUE_LEVEL' /> },
  { key: OBJECT_NAMES.ROI, value: <Tln id='SELECT_VALUE_ROI' /> },
  { key: OBJECT_NAMES.WALL, value: <Tln id='SELECT_VALUE_WALL' /> },
  // {key: OBJECT_NAMES.BACKGROUND, value: <Tln id="SELECT_VALUE_BACKGROUND" />}
];

OBJECT_TYPES.POINT = [
  // {key: OBJECT_NAMES.POI, value: <Tln id="SELECT_VALUE_POI" />},
  { key: OBJECT_NAMES.BEACON, value: <Tln id='SELECT_VALUE_BEACON' /> },
  { key: OBJECT_NAMES.CONNECTOR, value: <Tln id='SELECT_VALUE_CONNECTOR' /> },
];

OBJECT_TYPES.LINE = [
  { key: OBJECT_NAMES.ROUTE, value: <Tln id='SELECT_VALUE_ROUTE' /> },
  { key: OBJECT_NAMES.DOOR, value: <Tln id='SELECT_VALUE_DOOR' /> },
  { key: OBJECT_NAMES.STAIRS, value: <Tln id='SELECT_VALUE_STAIRS' /> },
  // {key: OBJECT_NAMES.PARKING, value: <Tln id="SELECT_VALUE_PARKING" />},
  // {key: OBJECT_NAMES.FURNITURE, value: <Tln id="SELECT_VALUE_FURNITURE" />},
];

const OBJECT_TYPES_ALL = [
  { key: OBJECT_NAMES.CAMPUS, value: <Tln id='SELECT_VALUE_CAMPUS' /> },
  { key: OBJECT_NAMES.BUILDING, value: <Tln id='SELECT_VALUE_BUILDING' /> },
  { key: OBJECT_NAMES.ROOM, value: <Tln id='SELECT_VALUE_ROOM' /> },
  { key: OBJECT_NAMES.LEVEL, value: <Tln id='SELECT_VALUE_LEVEL' /> },
  { key: OBJECT_NAMES.WALL, value: <Tln id='SELECT_VALUE_WALL' /> },
  { key: OBJECT_NAMES.BEACON, value: <Tln id='SELECT_VALUE_BEACON' /> },
  { key: OBJECT_NAMES.POI, value: <Tln id='SELECT_VALUE_POI' /> },
  { key: OBJECT_NAMES.ROI, value: <Tln id='SELECT_VALUE_ROI' /> },
  { key: OBJECT_NAMES.ROUTE, value: <Tln id='SELECT_VALUE_ROUTE' /> },
  { key: OBJECT_NAMES.STAIRS, value: <Tln id='SELECT_VALUE_STAIRS' /> },
  { key: OBJECT_NAMES.BACKGROUND, value: <Tln id='SELECT_VALUE_BACKGROUND' /> },
  { key: OBJECT_NAMES.DOOR, value: <Tln id='SELECT_VALUE_DOOR' /> },
  { key: OBJECT_NAMES.FURNITURE, value: <Tln id='SELECT_VALUE_FURNITURE' /> },
  { key: OBJECT_NAMES.PARKING, value: <Tln id='SELECT_VALUE_PARKING' /> },
  { key: OBJECT_NAMES.WINDOWS, value: <Tln id='SELECT_VALUE_WINDOWS' /> },
  { key: OBJECT_NAMES.CONNECTOR, value: <Tln id='SELECT_VALUE_CONNECTOR' /> },
];

const OBJECT_TYPES_LOCATION_FINDER = [
  { key: '', value: <Tln id='SELECT_VALUE_ALL' /> },
  { key: OBJECT_NAMES.CAMPUS, value: <Tln id='SELECT_VALUE_CAMPUS' /> },
  { key: OBJECT_NAMES.BUILDING, value: <Tln id='SELECT_VALUE_BUILDING' /> },
  // {key: OBJECT_NAMES.ROOM, value: <Tln id="SELECT_VALUE_ROOM" />},
  // {key: OBJECT_NAMES.LEVEL, value: <Tln id="SELECT_VALUE_LEVEL" />},
  { key: OBJECT_NAMES.ROI, value: <Tln id='SELECT_VALUE_ROI' /> },
  // {key: OBJECT_NAMES.PARKING, value: <Tln id="SELECT_VALUE_PARKING" />},
];

const SHAPES_TYPES = [
  { key: DRAW_SHAPES.POINT, value: <Tln id='SELECT_VALUE_POINT' /> },
  { key: DRAW_SHAPES.POLYGON, value: <Tln id='SELECT_VALUE_POLYGON' /> },
  { key: DRAW_SHAPES.CIRCLE, value: <Tln id='SELECT_VALUE_CIRCLE' /> },
  { key: DRAW_SHAPES.SQUARE, value: <Tln id='SELECT_VALUE_SQUARE' /> },
  { key: DRAW_SHAPES.BOX, value: <Tln id='SELECT_VALUE_BOX' /> },
  { key: DRAW_SHAPES.LINESTRING, value: <Tln id='SELECT_VALUE_LINESTRING' /> },
];

const OBJECT_GROUP = {
  POINT: 'POINT',
  POLYGON: 'POLYGON',
  LINE: 'LINE',
};

const LOCATION_FINDER_CATEGORIES = [
  {
    value: 'LOCATION_FINDER_CATEGORIES_FROM_MAP',
    name: <Tln id='LOCATION_FINDER_CATEGORIES_FROM_MAP' />,
  },
  {
    value: 'LOCATION_FINDER_CATEGORIES_LAT_LON',
    name: <Tln id='LOCATION_FINDER_CATEGORIES_LAT_LON' />,
  },
];

const COLORS_OPTIONS = ['#C3EAFF', '#002F52'];

const STATUS_OPTIONS = [
  {
    id: STATUS_TYPE.ACTIVE,
    name: <Tln id='STATUS_OPTION_ACTIVE' />,
  },
  {
    id: STATUS_TYPE.INACTIVE,
    name: <Tln id='STATUS_OPTION_INACTIVE' />,
  },
];

const NOTIFICATIONS_TYPES_OPTIONS = [
  {
    id: NOTIFICATIONS_TYPES.GENERAL,
    name: <Tln id='NOTIFICATION_TYPE_GENERAL' />,
  },
  {
    id: NOTIFICATIONS_TYPES.MARKETING,
    name: <Tln id='NOTIFICATION_TYPE_MARKETING' />,
  },
];

const NOTIFICATIONS_TYPES_OPTIONS_ALL = [
  {
    id: NOTIFICATIONS_TYPES.GENERAL,
    name: <Tln id='NOTIFICATION_TYPE_GENERAL' />,
  },
  {
    id: NOTIFICATIONS_TYPES.MARKETING,
    name: <Tln id='NOTIFICATION_TYPE_MARKETING' />,
  },
  {
    id: NOTIFICATIONS_TYPES.PUSH,
    name: <Tln id='NOTIFICATION_TYPE_PUSH' />,
  },
  {
    id: NOTIFICATIONS_TYPES.ROUTE,
    name: <Tln id='NOTIFICATION_TYPE_ROUTE' />,
  },
];

const BUTTONS_TYPES_OPTIONS = [
  {
    id: BUTTONS_TYPES.BEFORE_ARTICLES,
    name: <Tln id='BUTTON_TYPE_BEFORE_ARTICLES' />,
  },
  {
    id: BUTTONS_TYPES.BEFORE_EVENTS,
    name: <Tln id='BUTTON_TYPE_BEFORE_EVENTS' />,
  },
];

const USER_ROLES_OPTIONS = [
  {
    id: USER_ROLES.ADMIN,
    name: <Tln id='USER_ROLE_ADMIN' />,
  },
  {
    id: USER_ROLES.MODERATOR,
    name: <Tln id='USER_ROLE_MODERATOR' />,
  },
  {
    id: USER_ROLES.AD_USER,
    name: <Tln id='USER_ROLE_AD_USER' />,
  },
  {
    id: USER_ROLES.AD_EMPLOYEE,
    name: <Tln id='USER_ROLE_AD_EMPLOYEE' />,
  },
  {
    id: USER_ROLES.USER,
    name: <Tln id='USER_ROLE_USER' />,
  },
];

const USER_ROLES_OPTIONS_WITHOUT_AD_USER = [
  {
    id: USER_ROLES.ADMIN,
    name: <Tln id='USER_ROLE_ADMIN' />,
  },
  {
    id: USER_ROLES.MODERATOR,
    name: <Tln id='USER_ROLE_MODERATOR' />,
  },
];

const UPLOAD_VALUES_OPTIONS = [
  {
    id: UPLOAD_VALUES.VIDEO,
    name: <Tln id='UPLOAD_VALUE_VIDEO' />,
  },
  {
    id: UPLOAD_VALUES.AUDIO,
    name: <Tln id='UPLOAD_VALUE_AUDIO' />,
  },
];

export {
  OBJECT_NAMES,
  DRAW_SHAPES,
  SHAPES_TYPES,
  OBJECT_GROUP,
  LOCATION_FINDER_CATEGORIES,
  OBJECT_TYPES_ALL,
  OBJECT_TYPES_LOCATION_FINDER,
  COLORS_OPTIONS,
  STATUS_OPTIONS,
  NOTIFICATIONS_TYPES_OPTIONS,
  NOTIFICATIONS_TYPES_OPTIONS_ALL,
  BUTTONS_TYPES_OPTIONS,
  USER_ROLES_OPTIONS,
  USER_ROLES_OPTIONS_WITHOUT_AD_USER,
  UPLOAD_VALUES_OPTIONS,
  OBJECT_TYPES_ARRAY,
  OBJECT_TYPES_FILTER_FEATURES,
};

export default OBJECT_TYPES;
