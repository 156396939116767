import styled from 'styled-components';

const LogoCollapsed = styled.div`
  padding: 0 8px;
  img {
    max-width: 100%;
    height: auto;
  }
`;

const Logo = styled.div`
  img {
    max-width: 100%;
    height: 54px;
  }
`;

export { LogoCollapsed, Logo };
