import SuperFetch from '../utils/superFetch';
import TlnString from '../components/Tln/TlnString';

const Beacons = {
  getBeacons: (page = 1, search = '', signal) =>
    SuperFetch.get(`beacons?page=${page}&search=${search}`, {}, false, null, signal).then(response => response.data),

  getBeacon: beaconId => SuperFetch.get(`beacons/${beaconId}`).then(response => response.data),

  updateBeacon: beacon =>
    SuperFetch.put(`beacons/${beacon.id}`, beacon, false, TlnString('CRUD_EDIT_RECORD_SUCCESS')).then(
      response => response.status,
    ),
};

export default Beacons;
