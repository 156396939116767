import SuperFetch from '../utils/superFetch';
import TlnString from '../components/Tln/TlnString';

const Opinions = {
  getOpinions: (page = 1) => SuperFetch.get(`rating?page=${page}`).then(response => response.data),

  getOpinion: opinionId => SuperFetch.get(`rating/${opinionId}`).then(response => response.data),

  deleteOpinion: opinion =>
    SuperFetch.delete(`rating/${opinion.id}`, {}, false, TlnString('CRUD_REMOVE_RECORD_SUCCESS')).then(
      response => response.status,
    ),

  sendPushOpinionReply: (opinionId, opinionReply) =>
    SuperFetch.post(`rating/respond/${opinionId}`, opinionReply, false, TlnString('OPINION_REPLY_PUSH_SUCCESS')).then(
      response => response.status,
    ),
};

export default Opinions;
