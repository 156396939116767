import { call, all, takeEvery, put, fork } from 'redux-saga/effects';
import actions from './actions';
import { push } from 'react-router-redux';
import Campuses from '../../services/campuses';
import { SAVE_FUNCTION_CRUD } from '../../utils/enums';
import { API_STATUS } from '../../utils/enums';
import { URLS } from '../../utils/enumsUrl';

import pagesActions from '../pages/actions';

export function* loadCampuses() {
  yield takeEvery(actions.LOAD_CAMPUSES, function* ({ payload }) {
    const { page } = payload;
    try {
      const result = yield call(Campuses.getCampuses, page);
      yield put(actions.loadCampusesSuccess(result));
    } catch (error) {
      yield put(actions.loadCampusesError(error));
    }
  });
}

export function* loadCampusesBasic() {
  yield takeEvery(actions.LOAD_CAMPUSES_BASIC, function* ({ payload }) {
    const { language } = payload;
    try {
      const result = yield call(Campuses.getCampusesBasic, language);
      yield put(actions.loadCampusesBasicSuccess(result));
    } catch (error) {
      yield put(actions.loadCampusesBasicError(error));
    }
  });
}

export function* loadCampus() {
  yield takeEvery(actions.LOAD_CAMPUS, function* ({ payload }) {
    try {
      const result = yield call(Campuses.getCampus, payload);
      yield put(actions.loadCampusSuccess(result));
    } catch (error) {
      yield put(actions.loadCampusError(error));
    }
  });
}

export function* storeCampus() {
  yield takeEvery(actions.SAVE_CAMPUS, function* ({ payload }) {
    const { data, actionName } = payload;
    let result;
    try {
      switch (actionName) {
        case SAVE_FUNCTION_CRUD.DELETE:
          result = yield call(Campuses.deleteCampus, data);
          break;
        case SAVE_FUNCTION_CRUD.UPDATE:
          result = yield call(Campuses.updateCampus, data);
          break;
        default:
          result = yield call(Campuses.saveCampus, data);
          break;
      }
      if (result === API_STATUS.SUCCESS) {
        yield put(push(URLS.CAMPUSES));
        yield put(actions.saveCampusSuccess());

        if (actionName === SAVE_FUNCTION_CRUD.DELETE) {
          yield put(actions.loadCampuses());
          yield put(pagesActions.resetPage());
        }
      }
    } catch (error) {
      yield put(actions.saveCampusError(error));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(loadCampuses), fork(loadCampusesBasic), fork(loadCampus), fork(storeCampus)]);
}
