import { call, all, takeEvery, put, fork } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import actions from './actions';
import Events from '../../services/events';
import { SAVE_FUNCTION_CRUD } from '../../utils/enums';
import { API_STATUS } from '../../utils/enums';
import { URLS } from '../../utils/enumsUrl';

import pagesActions from '../pages/actions';

export function* loadEvents() {
  yield takeEvery(actions.LOAD_EVENTS, function* ({ payload }) {
    const { page } = payload;
    try {
      const result = yield call(Events.getEvents, page);
      yield put(actions.loadEventsSuccess(result));
    } catch (error) {
      yield put(actions.loadEventsError(error));
    }
  });
}

export function* loadEvent() {
  yield takeEvery(actions.LOAD_EVENT, function* ({ payload }) {
    try {
      const result = yield call(Events.getEvent, payload);
      yield put(actions.loadEventSuccess(result));
    } catch (error) {
      yield put(actions.loadEventError(error));
    }
  });
}

export function* storeEvent() {
  yield takeEvery(actions.SAVE_EVENT, function* ({ payload }) {
    const { data, actionName } = payload;
    let result;
    try {
      switch (actionName) {
        case SAVE_FUNCTION_CRUD.DELETE:
          result = yield call(Events.deleteEvent, data);
          break;
        case SAVE_FUNCTION_CRUD.UPDATE:
          result = yield call(Events.updateEvent, data);
          break;
        default:
          result = yield call(Events.saveEvent, data);
          break;
      }
      if (result === API_STATUS.SUCCESS) {
        yield put(push(URLS.EVENTS));
        yield put(actions.saveEventSuccess());

        if (actionName === SAVE_FUNCTION_CRUD.DELETE) {
          yield put(actions.loadEvents());
          yield put(pagesActions.resetPage());
        }
      }
    } catch (error) {
      yield put(actions.saveEventError(error));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(loadEvents), fork(loadEvent), fork(storeEvent)]);
}
