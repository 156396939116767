import styled from 'styled-components';
import { palette } from 'styled-theme';

const TextAreaCustom = ComponentName => styled(ComponentName)`
  &.ant-input {
    overflow: hidden;
  }
`;

const SearchCustom = ComponentName => styled(ComponentName)`
  &.ant-input-affix-wrapper .ant-input:not(:last-child) {
    text-align: right;
    padding-right: 36px;
    border: 1px solid transparent;
    border-radius: 8px;
    background-color: white;
    &:hover:not(:disabled),
    &:focus,
    &:active {
      cursor: pointer;
      border-color: ${palette('primary', 0)};
      border-radius: 8px;
    }
  }

  .ant-input-search-icon,
  .ant-input-search-icon:hover {
    color: ${palette('primary', 0)};
    font-size: 20px;
  }
`;

export { TextAreaCustom, SearchCustom };
