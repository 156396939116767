const actions = {
  LOAD_TYPES: 'LOAD_TYPES',
  LOAD_TYPES_SUCCESS: 'LOAD_TYPES_SUCCESS',
  LOAD_TYPES_ERROR: 'LOAD_TYPES_ERROR',

  loadTypes: () => ({
    type: actions.LOAD_TYPES,
  }),
  loadTypesSuccess: data => ({
    type: actions.LOAD_TYPES_SUCCESS,
    payload: { data },
  }),
  loadTypesError: error => ({
    type: actions.LOAD_TYPES_ERROR,
    payload: { error },
  }),
};

export default actions;
