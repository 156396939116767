const theme = {};

theme.palette = {
  primary: [
    '#007BC0', // 0: Primary Blue
    '#C3EAFF', // 1: Secondary Blue
    'rgba(181, 251, 255, 0.3)', // 2: LightBlue
    'rgba(0, 123, 192, 0.3)', // 3: Primary Blue 30% opacity
    '#F0F0F0', // 4: Background Grey
    '#FFF', // 5: icons color
    '#d9d9d9', //6: border color cruds
    '#1890ff', //7: border select color cruds
    '#1D4C93', //8 Third Blue (Dark)
  ],
  text: [
    '#979797', // 0: Text
    '#797979', // 1: TextDark
    '#000000', // 2: Text Color - BLACK
    '#4F4F4F', // 3: Text Color - DARK GRAY
    '#E0E0E0', // 4: Text Color - GRAY
    '#FFFFFF', // 5: Text Color - WHITE
  ],
  success: [
    '#38BC28', // 0: Success
    '#2CE316', // 0: Success Hover
  ],
  error: [
    '#f64744', // 0: Error
    '#FF5B58', // 1: Error Hover
  ],
  grayscale: [
    '#bababa', // 0: GreyShade
    'rgba(0, 0, 0, 0.1)', // 1: border sidebar, topbar
  ],
  border: [
    '#e9e9e9', // 0: Border
  ],
};

theme.fonts = {
  primary: 'Noto Sans, sans-serif',
  pre: 'Consolas, Liberation Mono, Menlo, Courier, monospace',
};

export default theme;
