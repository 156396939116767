import SuperFetch from '../utils/superFetch';
import TlnString from '../components/Tln/TlnString';

const SosContacts = {
  getSosContacts: (page = 1) => SuperFetch.get(`email_sms?page=${page}`).then(response => response.data),

  getSosContact: sosContactId => SuperFetch.get(`email_sms/${sosContactId}`).then(response => response.data),

  saveSosContact: sosContact =>
    SuperFetch.post('email_sms', sosContact, false, TlnString('CRUD_ADD_RECORD_SUCCESS')).then(response => response.status),

  updateSosContact: sosContact =>
    SuperFetch.put(`email_sms/${sosContact.id}`, sosContact, false, TlnString('CRUD_EDIT_RECORD_SUCCESS')).then(
      response => response.status,
    ),

  deleteSosContact: sosContact =>
    SuperFetch.delete(`email_sms/${sosContact.id}`, {}, false, TlnString('CRUD_REMOVE_RECORD_SUCCESS')).then(
      response => response.status,
    ),
};

export default SosContacts;
