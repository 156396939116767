import actions from './actions';

const initState = {
  isLoading: false,
  isSaving: false,
  beacons: [],
  beacon: {},
  search: '',
  init: false,
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_BEACONS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_BEACONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        beacons: payload.data,
        init: true,
      };
    case actions.LOAD_BEACONS_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.LOAD_BEACON:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_BEACON_SUCCESS:
      return {
        ...state,
        isLoading: false,
        beacon: payload.data,
      };
    case actions.LOAD_BEACON_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.SAVE_BEACON:
      return {
        ...state,
        isSaving: true,
      };
    case actions.SAVE_BEACON_SUCCESS:
      return {
        ...state,
        isSaving: false,
      };
    case actions.SAVE_BEACON_ERROR:
      return {
        ...state,
        isSaving: false,
      };

    case actions.REMOVE_BEACON:
      return {
        ...state,
        beacon: {},
      };
    case actions.REMOVE_BEACONS:
      return {
        ...state,
        beacons: [],
      };

    case actions.SET_SEARCH:
      return {
        ...state,
        search: payload.value,
      };

    default:
      return state;
  }
}
