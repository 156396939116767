import actions from './actions';

const initState = {
  isLoading: false,
  isSaving: false,
  user: {},
  users: [],
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_USERS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: payload.data,
      };
    case actions.LOAD_USERS_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.LOAD_USER:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: payload.data,
      };
    case actions.LOAD_USER_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.CHANGE_PASSWORD_USER:
      return {
        ...state,
        isSaving: true,
      };
    case actions.CHANGE_PASSWORD_USER_SUCCESS:
      return {
        ...state,
        isSaving: false,
      };
    case actions.CHANGE_PASSWORD_USER_ERROR:
      return {
        ...state,
        isSaving: false,
      };

    case actions.SAVE_USER:
      return {
        ...state,
        isSaving: true,
      };
    case actions.SAVE_USER_SUCCESS:
      return {
        ...state,
        isSaving: false,
      };
    case actions.SAVE_USER_ERROR:
      return {
        ...state,
        isSaving: false,
      };

    case actions.REMOVE_USER:
      return {
        ...state,
        user: {},
      };
    case actions.REMOVE_USERS:
      return {
        ...state,
        users: [],
      };

    default:
      return state;
  }
}
