const actions = {
  LOAD_USERS: 'LOAD_USERS',
  LOAD_USERS_SUCCESS: 'LOAD_USERS_SUCCESS',
  LOAD_USERS_ERROR: 'LOAD_USERS_ERROR',

  LOAD_USER: 'LOAD_USER',
  LOAD_USER_SUCCESS: 'LOAD_USER_SUCCESS',
  LOAD_USER_ERROR: 'LOAD_USER_ERROR',

  SAVE_USER: 'SAVE_USER',
  SAVE_USER_SUCCESS: 'SAVE_USER_SUCCESS',
  SAVE_USER_ERROR: 'SAVE_USER_ERROR',

  CHANGE_PASSWORD_USER: 'CHANGE_PASSWORD_USER',
  CHANGE_PASSWORD_USER_SUCCESS: 'CHANGE_PASSWORD_USER_SUCCESS',
  CHANGE_PASSWORD_USER_ERROR: 'CHANGE_PASSWORD_USER_ERROR',

  REMOVE_USER: 'REMOVE_USER',
  REMOVE_USERS: 'REMOVE_USERS',

  loadUsers: page => ({
    type: actions.LOAD_USERS,
    payload: { page },
  }),
  loadUsersSuccess: data => ({
    type: actions.LOAD_USERS_SUCCESS,
    payload: { data },
  }),
  loadUsersError: error => ({
    type: actions.LOAD_USERS_ERROR,
    payload: { error },
  }),

  loadUser: userId => ({
    type: actions.LOAD_USER,
    payload: userId,
  }),
  loadUserSuccess: data => ({
    type: actions.LOAD_USER_SUCCESS,
    payload: { data },
  }),
  loadUserError: error => ({
    type: actions.LOAD_USER_ERROR,
    payload: { error },
  }),

  saveUser: (data, actionName) => ({
    type: actions.SAVE_USER,
    payload: { data, actionName },
  }),
  saveUserSuccess: () => ({
    type: actions.SAVE_USER_SUCCESS,
  }),
  saveUserError: error => ({
    type: actions.SAVE_USER_ERROR,
    payload: { error },
  }),

  changePasswordUser: (userId, password) => ({
    type: actions.CHANGE_PASSWORD_USER,
    payload: { userId, password },
  }),
  changePasswordUserSuccess: () => ({
    type: actions.CHANGE_PASSWORD_USER_SUCCESS,
  }),
  changePasswordUserError: error => ({
    type: actions.CHANGE_PASSWORD_USER_ERROR,
    payload: { error },
  }),

  removeUser: () => ({
    type: actions.REMOVE_USER,
  }),
  removeUsers: () => ({
    type: actions.REMOVE_USERS,
  }),
};

export default actions;
