import { call, all, takeEvery, put, fork } from 'redux-saga/effects';
import actions from './actions';
import Upload from '../../services/upload';

export function* sendFile() {
  yield takeEvery(actions.SEND_FILE, function* ({ payload }) {
    try {
      const result = yield call(Upload.sendFile, payload.data);
      yield put(actions.sendFileSuccess(result));
    } catch (error) {
      yield put(actions.sendFileError(error));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(sendFile)]);
}
