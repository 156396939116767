import SuperFetch from '../utils/superFetch';

const GeoBasic = {
  getSelectOptions: (type, centerPoint = [null, null], profile, withId = null, withoutId = null) =>
    SuperFetch.get(
      `geo_basic?geomType=${type}&p=${centerPoint[0]},${centerPoint[1]}&profile=${profile}&with=${withId}&without=${withoutId}`,
    ).then(response => response.data),

  getSingleObjectName: (type, id) => SuperFetch.get(`geo_basic?geomType=${type}&id=${id}`).then(response => response.data),
};

export default GeoBasic;
