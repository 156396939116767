import actions from './actions';

const initState = {
  isLoading: false,
  isSaving: false,
  notificationsRoute: [],
  notificationRoute: {},
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_NOTIFICATIONS_ROUTE:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_NOTIFICATIONS_ROUTE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        notificationsRoute: payload.data,
      };
    case actions.LOAD_NOTIFICATIONS_ROUTE_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.LOAD_NOTIFICATION_ROUTE:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_NOTIFICATION_ROUTE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        notificationRoute: payload.data,
      };
    case actions.LOAD_NOTIFICATION_ROUTE_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.SAVE_NOTIFICATION_ROUTE:
      return {
        ...state,
        isSaving: true,
      };
    case actions.SAVE_NOTIFICATION_ROUTE_SUCCESS:
      return {
        ...state,
        isSaving: false,
      };
    case actions.SAVE_NOTIFICATION_ROUTE_ERROR:
      return {
        ...state,
        isSaving: false,
      };

    case actions.REMOVE_NOTIFICATION_ROUTE:
      return {
        ...state,
        notificationRoute: {},
      };
    case actions.REMOVE_NOTIFICATIONS_ROUTE:
      return {
        ...state,
        notificationsRoute: [],
      };

    default:
      return state;
  }
}
