import React, { Component } from 'react';
import { connect } from 'react-redux';

import authActions from '../redux/auth/actions';
import { getRoleLoadedStatus, getRole } from '../redux/auth/selectors';

import CustomLoaderWhite from '../components/CustomLoader/CustomLoaderWhite';

const { checkWhoAmI } = authActions;

function withRoleProvider(Cmp) {
  class Controller extends Component {
    componentDidMount() {
      this.props.checkWhoAmI();
    }

    render() {
      if (!this.props.roleLoaded && !this.props.role) {
        return (
          <CustomLoaderWhite loading={true} opacity={1}>
            <img src={require('../assets/logo_umed_pl.svg')} alt='Uniwersytet Medyczny' />
          </CustomLoaderWhite>
        );
      }

      return <Cmp {...this.props} />;
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(Controller);
}

const mapStateToProps = state => {
  return {
    roleLoaded: getRoleLoadedStatus(state),
    role: getRole(state),
  };
};

const mapDispatchToProps = { checkWhoAmI };

export default withRoleProvider;
