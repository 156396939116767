const actions = {
  LOAD_BEACONS: 'LOAD_BEACONS',
  LOAD_BEACONS_SUCCESS: 'LOAD_BEACONS_SUCCESS',
  LOAD_BEACONS_ERROR: 'LOAD_BEACONS_ERROR',

  LOAD_BEACON: 'LOAD_BEACON',
  LOAD_BEACON_SUCCESS: 'LOAD_BEACON_SUCCESS',
  LOAD_BEACON_ERROR: 'LOAD_BEACON_ERROR',

  SAVE_BEACON: 'SAVE_BEACON',
  SAVE_BEACON_SUCCESS: 'SAVE_BEACON_SUCCESS',
  SAVE_BEACON_ERROR: 'SAVE_BEACON_ERROR',

  REMOVE_BEACON: 'REMOVE_BEACON',
  REMOVE_BEACONS: 'REMOVE_BEACONS',

  SET_SEARCH: 'SET_SEARCH',

  loadBeacons: (page, signal = null) => ({
    type: actions.LOAD_BEACONS,
    payload: { page, signal },
  }),
  loadBeaconsSuccess: data => ({
    type: actions.LOAD_BEACONS_SUCCESS,
    payload: { data },
  }),
  loadBeaconsError: error => ({
    type: actions.LOAD_BEACONS_ERROR,
    payload: { error },
  }),

  loadBeacon: beaconId => ({
    type: actions.LOAD_BEACON,
    payload: beaconId,
  }),
  loadBeaconSuccess: data => ({
    type: actions.LOAD_BEACON_SUCCESS,
    payload: { data },
  }),
  loadBeaconError: error => ({
    type: actions.LOAD_BEACON_ERROR,
    payload: { error },
  }),

  saveBeacon: (data, actionName) => ({
    type: actions.SAVE_BEACON,
    payload: { data, actionName },
  }),
  saveBeaconSuccess: () => ({
    type: actions.SAVE_BEACON_SUCCESS,
  }),
  saveBeaconError: error => ({
    type: actions.SAVE_BEACON_ERROR,
    payload: { error },
  }),

  removeBeacon: () => ({
    type: actions.REMOVE_BEACON,
  }),
  removeBeacons: () => ({
    type: actions.REMOVE_BEACONS,
  }),

  setSearch: value => ({
    type: actions.SET_SEARCH,
    payload: { value },
  }),
};

export default actions;
