import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import { connect } from 'react-redux';
import App from '../app/App/App';
import asyncComponent from '../utils/AsyncFunc';

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/signin',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);
const PublicRoutes = ({ history, isLoggedIn }) => {
  return (
    <ConnectedRouter history={history}>
      <div>
        <Switch>
          <Route exact path={'/'} component={asyncComponent(() => import('../pages/Auth/signin'))} />
          <Route exact path={'/signin'} component={asyncComponent(() => import('../pages/Auth/signin'))} />
          <Route exact path={'/403'} component={asyncComponent(() => import('../app/App/403'))} />
          <RestrictedRoute path='/cms/' component={App} isLoggedIn={isLoggedIn} />
          <Route component={asyncComponent(() => import('../app/App/404'))} />
        </Switch>
      </div>
    </ConnectedRouter>
  );
};

export default connect(state => ({
  isLoggedIn: state.Auth.idToken !== null || localStorage.getItem('id_token') ? true : false,
}))(PublicRoutes);
