import React, { Component } from 'react';
import { Route, withRouter, Switch, Redirect } from 'react-router-dom';
import { compose } from 'redux';

import withRoleProvider from '../../hoc/withRoleProvider';

import { adminRoutes, commonRoutes, moderatorRoutes } from './routesList';
import { USER_ROLES } from '../../utils/enums';

class AppRouter extends Component {
  routes = (url, routes) => (
    <Switch>
      {routes.map(singleRoute => {
        const { path, exact, ...otherProps } = singleRoute;
        return <Route exact={exact === false ? false : true} key={path} path={`${url}/${path}`} {...otherProps} />;
      })}
      <Redirect to='/404' />
    </Switch>
  );

  render() {
    const { url, role } = this.props;

    if (role) {
      if (role.includes(USER_ROLES.ADMIN)) return this.routes(url, [...adminRoutes, ...commonRoutes]);
      else if (role.includes(USER_ROLES.MODERATOR)) return this.routes(url, [...moderatorRoutes, ...commonRoutes]);
      else if (role.includes(USER_ROLES.AD_USER || USER_ROLES.AD_EMPLOYEE || USER_ROLES.USER))
        return this.routes(url, [...commonRoutes]);
    }
    return this.routes(url, [...commonRoutes]);
  }
}

export default compose(withRouter, withRoleProvider)(AppRouter);
