const INTERACTION_MENU_ACTION_TYPE = {
  CLICK_DRAW: 'clickDraw',
  CLICK_MODIFY: 'clickModify',
  CLICK_SELECT: 'clickSelect',
  CLICK_SNAP: 'clickSnap',
};

const MODAL_ACTION_TYPE = {
  SET_TITLE: 'setTitle',
  SET_DATA: 'setData',
  SET_VISIBLE: 'setVisible',
  TOOGLE_VISIBLE: 'toggleVisible',
};

export { INTERACTION_MENU_ACTION_TYPE, MODAL_ACTION_TYPE };
