import SuperFetch from '../utils/superFetch';
import TlnString from '../components/Tln/TlnString';

const Users = {
  getUsers: (page = 1) => SuperFetch.get(`user?page=${page}`).then(response => response.data),

  getUser: userId => SuperFetch.get(`user/${userId}`).then(response => response.data),

  saveUser: user => SuperFetch.post('user', user, false, TlnString('CRUD_ADD_RECORD_SUCCESS')).then(response => response.status),

  updateUser: user =>
    SuperFetch.put(`user/${user.id}`, user, false, TlnString('CRUD_EDIT_RECORD_SUCCESS')).then(response => response.status),

  deleteUser: user =>
    SuperFetch.delete(`user/${user.id}`, {}, false, TlnString('CRUD_REMOVE_RECORD_SUCCESS')).then(response => response.status),

  changePasswordUser: (userId, password) =>
    SuperFetch.post(`change_user_password/${userId}`, { password }, false, TlnString('AUTH_RESET_PASSWORD_SUCCESS')).then(
      response => response.status,
    ),
};

export default Users;
