import styled from 'styled-components';

const DescriptionsWrapper = styled.div`
  .ant-descriptions-item-content > p {
    margin: 0;
  }

  .ant-descriptions-item-content > span > p {
    margin: 0;
  }

  .ant-descriptions-bordered .ant-descriptions-item-label {
    min-width: 12rem;
  }

  .ant-descriptions-item-no-label {
    display: none;
  }

  img {
    max-height: 100px;
    width: auto;
  }
`;

export { DescriptionsWrapper };
