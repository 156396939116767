const getLoadingStatus = state => state.CategoriesCruds.isLoading;

const getSavingStatus = state => state.CategoriesCruds.isSaving;

const getCategoryCruds = state => state.CategoriesCruds.categoryCruds;

const getCategoriesCruds = state => state.CategoriesCruds.categoriesCruds;

const getCategoriesCrudsBasic = state => state.CategoriesCruds.categoriesCrudsBasic;

const getModalActive = state => state.CategoriesCruds.modalActive;

const getFirstOpenStatus = state => state.CategoriesCruds.firstOpen;

export {
  getLoadingStatus,
  getSavingStatus,
  getCategoryCruds,
  getCategoriesCruds,
  getCategoriesCrudsBasic,
  getModalActive,
  getFirstOpenStatus,
};
