import SuperFetch from '../utils/superFetch';
import TlnString from '../components/Tln/TlnString';

const Notifications = {
  getNotifications: (page = 1) => SuperFetch.get(`notification?page=${page}`).then(response => response.data),

  getNotification: notificationId => SuperFetch.get(`notification/${notificationId}`).then(response => response.data),

  saveNotification: notification =>
    SuperFetch.post('notification', notification, false, TlnString('CRUD_ADD_RECORD_SUCCESS')).then(response => response.status),

  updateNotification: notification =>
    SuperFetch.put(`notification/${notification.id}`, notification, false, TlnString('CRUD_EDIT_RECORD_SUCCESS')).then(
      response => response.status,
    ),

  deleteNotification: notification =>
    SuperFetch.delete(`notification/${notification.id}`, {}, false, TlnString('CRUD_REMOVE_RECORD_SUCCESS')).then(
      response => response.status,
    ),
};

export default Notifications;
