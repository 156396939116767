import styled from 'styled-components';
import { palette } from 'styled-theme';

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
  align-items: center;
`;

const TitleWrapperModify = styled.div`
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
  align-items: center;

  .ant-btn {
    margin-right: 10px;
  }
`;

const ComponentTitle = styled.h3`
  font-size: 18px;
  font-weight: 500;
  color: ${palette('text', 2)};
  margin: 5px 0;
`;

export { TitleWrapper, ComponentTitle, TitleWrapperModify };
