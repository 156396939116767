import styled from 'styled-components';

const Fieldset = styled.div`
  width: 100%;
  flex-basis: 48%;
`;

const FieldsetWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const CategoryWrapper = styled.div`
  display: flex;
  margin-top: 3px;
`;

export { Fieldset, FieldsetWrapper, CategoryWrapper };
